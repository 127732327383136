@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';

.rssi {
    font: $font-medium $regular;
    align-items: center;
    display: flex;
}

.offline {
    composes: rssi;
    gap: 2px;
    color: $oslo-gray;
}

.online {
    composes: rssi;
    gap: 5px;
}
