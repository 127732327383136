@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 15px;

    @media screen and (min-width: $mobile-max) {
        padding: 0 64px;
    }
}

.statusRow {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    @media screen and (max-width: 1270px) {
        justify-content: flex-end;
        display: block;
    }
}

.content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    gap: 20px;
}

.buttonWrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.statusWrapper {
    background-color: $white;
    border-radius: 10px;
    padding: 10px 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    width: fit-content;
    @media screen and (max-width: 890px) {
        width: 240px;
    }
}

.summaryTileValue {
    font-size: 26px;
    font-family: $regular;
}

.summaryTileValueBold {
    font-size: 34px;
    font-family: $demi-bold;
}

.summaryCards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.downloadLink {
    text-decoration: none;
    color: $blue-azul;
    align-items: flex-start;
    display: flex;
    gap: 5px;
}
