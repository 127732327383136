@import '../../style/variables/colors';
@import '../../style/variables/media.scss';
@import '../../style/variables/fonts.scss';

.wrap {
    font: $font-medium $regular;
}

.grayText {
    color: $oslo-gray;
    @media screen and (max-width: $tablet-max){
            display: none;
        }
}

.offline {
    @media screen and (max-width: $tablet-max) {
        display: none;
    }
}

.ethernetAndSim {
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content;
    svg {
        width: 24px;
        height: 24px;
        fill: $black-shark;
    }
}

.aligned {
    display: flex;
    align-items: center;
    gap: 5px;
}

.notSynced {
    @media screen and (max-width: $tablet-max) {
        display: none;
    }
}

.status{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
}

.clodAndSim{
  display: flex;
  align-items: center;
  gap: 10px;
}

.info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 600px;
    @media screen and (max-width: $tablet-max) {
        width: 100%;
        gap: 10px;
        padding-right: 20px;
        justify-content: space-between;
    }
}

.noDisplay {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    @media screen and (max-width: $tablet-max) {
        display: none;
    }
}

.connected {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    @media screen and (max-width: $tablet-max) {
        justify-content: flex-end;
        padding-right: 20px;
        width: 0;
    }
}

.status {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}

.devices {
    display: flex;
    gap: 30px;
}

.textBold {
    font-family: $demi-bold;
}

.senorLine {
    background: $red-persian;
    width: 10px;
    height: 10px;
    border-radius: 100%;
}

.lostDevices {
    display: flex;
    gap: 10px;
    align-items: center;
}
