@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.header {
    font: $font-large $medium;
    margin: unset;
}

.card {
    background-color: $white;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    margin: 20px 0;
}

.graph {
    position: relative;
    width: 50%;
    min-width: 430px;
    margin-bottom: 15px;
    @media screen and (max-width: 1150px) {
        width: 100%;
        min-width: unset;
        margin-bottom: 30px;
    }
}

.cardContent {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.expandButton {
    background: $yellow-sunlight;
    position: absolute;
    border: unset;
    width: 70px;
    height: 25px;
    overflow: hidden;
    left: calc(50% - 35px);
    bottom: -20px;
    padding: 0 10px;
    border-radius: 0 0 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sortButton {
    display: flex;
    position: relative;
    justify-content: flex-end;
    margin-bottom: -40px;
    z-index: 1;
}
