@import './variables/colors.scss';
@import './variables/fonts.scss';
@import './variables/media.scss';

.modal {
    &__overlay {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        background: rgba(0, 0, 0, .50);
    }

    &__content {
        max-height: 80vh;
        overflow: auto;
        min-width: 300px;
        padding: 13px 0;
        margin-top: 15vh;
        background: $white;
        box-shadow: 0 2px 12px 0 rgba(41, 34, 34, 0.52);
        border-radius: 10px;
        border: 1px solid $grey-ash;

        &--size {
            &-small {
                width: 350px;
            }
            &-medium {
                width: 500px;
            }
            &-large {
                width: 800px;
            }
        }

        &--mobile-full {
            @media screen and (max-width: 790px) {
                max-height: unset;
                margin-top: unset;
                height: 100%;
                padding-bottom: 90px;

                .modal__content__buttons--sticky-bottom {
                    position: absolute;
                    background-color: $white;
                    bottom: 0;
                    width: 100%;
                    padding: 20px 0;
                }
            }
        }

        &__header {
            &--close {
                width: 100%;
                padding: 0 20px 0 55px;
                display: flex;
                .modal__content__title {
                    padding: 0;
                }
            }
            &--top-padding {
                margin-top: 20px;
            }
            &--sticky {
                position: absolute;
                margin: unset;
                background-color: $white;
                border-radius: 10px;
                top: 0;
                width: 100%;
                border-bottom: 0;
                &__with-search {
                    padding: 0;
                }
            }
        }

        &__search {
            padding: 15px 33px 0 33px;

        }
        &__video {
            display: flex;
            justify-content: center;
            margin: 0 auto 30px auto;
            max-width: 640px;
            max-height: 360px;
            width: 100%;
            height: 100%;
            &__player {
                max-width: 640px;
                height: 360px;
                width: 100%;
            }
        }

        &__title {
            padding: 0;
            text-align: center;
            font: 18px/25px $demi-bold;
            font-weight: normal;
            color: $black-shark;
            width: 100%;

            &--top-padding {
                margin-top: 20px;
            }
        }

        &__spinner {
            display: flex;
            justify-content: center;
            align-items: center;
            &--margin-top {
                margin-top: 150px;
            }
        }

        &__iframe {
            width: 100%;
            height: calc(100% - 100px)
        }

        &__body {
            margin: 30px 45px;
            white-space: pre-wrap;
            font: $font-medium $regular;
            &--full-width {
                margin: 30px 0;
            }
            &--form {
                margin: 15px 45px 7px 45px;
            }
            &--flex {
                display: flex;
                flex-wrap: wrap;
                width: calc(100% - 45px*2);
            }
            &--center {
                text-align: center;
            }

            &__inner {
                padding: 0 20px;
            }
            &--large {
                min-height: 365px;
                display: flex;
                flex-direction: column;
            }
            .form-flex {
                display: flex;
                flex: 1;
                flex-direction: column;
            }
            &__fab-button-wrapper {
                padding: 15px;
                &__text {
                   margin-top: 8px;
                }
            }
        }

        &__sticky {
            max-height: 80vh;
            overflow: auto;
            padding-top: 80px;
        }

        &__description {
            font: 15px/20px $regular;
            text-align: center;
            color: $black-shark;
            margin: 0;
            &--small {
                font: 16px/25px $regular;
            }
            &--bold {
                font-family: $demi-bold;
            }
            &--padded-large {
                padding-top: 65px;
            }
            &--slim {
                padding: 15px 0;
            }
            &--left {
                text-align: left;
            }
            &__download {
                margin-top: 15px;

                &__icon {
                    color: $oslo-gray;
                    padding-right: 10px;
                }
            }
        }
        &__radio {
            min-width: 140px;
            font-family: $regular;
            &--slim {
                min-width: 125px;
            }
        }

        &__buttons {
            display: flex;
            justify-content: center;
            padding-bottom: 10px;
            gap: 15px;
            &--flex-end {
                flex: 1;
                display: flex;
                align-items: flex-end;
            }

            &--padded {
                padding-bottom: 30px;
            }

            .btn {
                min-width: 136px;
                margin: 0 7px;
                transition: all .2s ease;
            }

            &--sticky {
                position: absolute;
                bottom: 0;
                width: 100%;
                border-radius: 10px;
                padding: 25px 0;
                background-color: $white;
            }
        }

        &--swap-organization {
            width: 350px;
            position: relative;
            padding: unset;
            overflow: unset;
            .btn {
                position: unset;
            }

            @media screen and (max-width: $mobile-max) {
                margin-top: 2vh;
                max-height: 98vh;
                overflow-y: auto;
            }

            &__lined {
                width: 100%;
                border-bottom: 1px solid $grey-ash;
                margin: 10px auto 20px -10px;
            }

            &__list {
                flex-direction: column;
                margin-top: 10px;

                &__sub-title {
                    margin: 0;
                    font: 18px/25px $demi-bold;
                }

                &__row {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 30px 25px 0 0;

                    &__text {
                        padding-right: 15px;
                        font: 15px/20px $regular;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        &__overflow {
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }

                    &:first-of-type {
                        margin-top: 0;
                    }

                    &:last-of-type {
                        margin-bottom: 15px;
                    }
                }
            }

            .modal__content {
                &__title {
                    font-size: 18px;
                    text-align: left;
                    font-family: $demi-bold;
                    display: flex;
                    align-items: center;
                    padding: 0 30px;
                }

                &__body {
                    margin: 5px 10px 100px 30px;
                    min-height: 40vh;
                }
            }
        }

        &--info {
            width: 500px;
            .modal__content__buttons {
                padding-top: 50px;
                padding-bottom: 0;
                gap: 15px;
            }
        }

        &--welcome {
            width: 500px;
            background: $blue-cerulean;
            border-color: $blue-cerulean;
            font-family: $medium;
            box-shadow: 0 2px 12px 0 rgba(41, 34, 34, 0.52);
            .modal__content {
                &__title {
                    color: $white;
                }
                &__body {
                    font-family: $regular;
                    text-align: center;
                    color: $white;
                    a, a:hover, a:active, a:visited {
                        color: white;
                    }
                }
                &__buttons {
                    gap: 15px;
                    float: right;
                    padding-top:10px;
                }
            }
        }
        &--hbs-on-boarding {
            width: 500px;
            min-height: 500px;
        }
        &--virus-risk {
            width: 800px;
        }
        &__risk-factors {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            &__element {
                width: 290px;
                padding-bottom: 15px;
                &__solution {
                    display: flex;
                    background-color: $grey-porcelain;
                    height: 100px;
                    width: 280px;
                    font: 24px/30px $black-shark;
                    align-items: center;
                    padding: 20px;
                    svg {
                        min-width: 48px;
                        min-height: 48px;
                        fill: $black-shark;
                        margin-right: 10px;
                    }
                }
            }
        }
        &--reset-connections {
            position: relative;
            padding: unset;

            @media screen and (max-width: $mobile-max) {
                margin-top: 2vh;
                max-height: 98vh;
                overflow-y: auto;
            }

            &__column-1 {
                width: 180px;
            }

            &__column-2 {
                width: calc(100% - 180px);
            }

            &__row {
                margin-top: 20px;
            }

            &__clipboard {
                margin-left: 12px;
                height: 60px;
            }

            &__center-text {
                align-items: center;
                justify-content: center;
                display: flex;
                height: 15vh;
            }

            .modal__content__header--sticky__with-search {
                margin-left: 10px;
            }

            .modal__content__body {
                min-height: 20vh;
                margin-bottom: 120px;
            }

            .material-symbols-outlined {
                color: $black-shark;
                &:hover {
                    color: $blue-celestial;
                    outline: none;
                    cursor: pointer;
                }
            }
        }
    }
    &__thresholds {
        display: flex;
        &__sensor-wrapper {
            padding: 0 30px 30px 0;
            width: 50%;
            min-width: 280px;
            display: inline-block;
            &__header {
                display: flex;
                svg {
                    margin-right: 10px;
                }
            }
            &__limit-row {
                margin-left: 32px;
                line-height: 40px;
            }
        }
    }
}
