@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.infoWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}

.subscriptionInfo {
    display: flex;
    flex-direction: column;
    gap: 20px
}

.proratedAmount {
    display: flex;
    align-items: center;
    background: $grey-concrete;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
    gap: 10px;
}
