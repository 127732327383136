@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.tile {
    padding-bottom: 20px;
    color: $black-shark;
}

.newDomainHeader {
    display: flex;
    align-items: center;
    text-align: center;
    height: 48px;
}


.headerPadding {
    padding: 0 10px;
}

.headerText {
    font: $font-large $demi-bold;
    margin: 0;
    padding: 0 10px;
    text-align: left;
}
