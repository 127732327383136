@import './variables/colors.scss';
@import './variables/fonts.scss';

.alert-box {
    position: fixed;
    left: 50%;
    top: 115px;
    z-index: 1000;
    display: flex;
    align-items: center;
    font-family: $demi-bold;
    margin-left: -140px;
    background-color: $green-ottoman;
    color: $black-shark;
    border: 1px solid $green-biosphere;
    border-radius: 10px;
    height: 70px;
    width: 380px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
    transition: 0.3s all;
    text-align: center;
    &--error {
        border: 1px solid $red-persian;
        background-color: $red-chablis;
        animation: fadein 0.5s;
        @keyframes fadein {
           from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    }
    &--fade {
        animation: fade 6s;
        @keyframes fade {
            0% {
                opacity: 0;
            }
            10% {
                opacity: 1;
            }
            30% {
                opacity: 1;
            }
            60% {
                opacity: 1;
            }
            100% {
                opacity: 0;
            }
        }
    }

    &__icon-container {
        background-color: $green-biosphere;
        width: 62px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &--error {
            background-color: $red-persian;
        }
        .icon-circle {
            color: $white;
            font-size: 32px;
        }
    }
    &__text {
        text-align: center;
        white-space: pre-wrap;
        width: calc(100% - 62px);
        font-size: $font-medium;
        gap: 5px;
        &--button {
            display: flex;
            justify-content: center;
            text-align: center;
            font-family: $regular;
            gap: 5px;
        }
    }
    &__background {
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 9999;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        -webkit-transition: 0.5s;
        overflow: auto;
        transition: all 0.3s linear;
    }
}
