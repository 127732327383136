@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';
@import '~commons/src/style/mixins.scss';

.form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font: $font-medium $regular;
}

.description {
    margin-top: 10px;
    margin-bottom: 10px;
    font: $font-medium $demi-bold;
}

.row {
    display: flex;
    gap: 20px;
    font: $font-medium $regular;
}

.option {
    width: 225px;
    border-radius: 10px;
    border: 1px solid $grey-mist;
    background: #fff;
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 10px;

    &:hover {
        cursor: pointer;
        background: $grey-porcelain;
    }
}

.optionSelected {
    background: $blue-marble;
    border: 1px solid $black-shark;
}

.bold {
    font: $font-medium $demi-bold;
}

.buttons {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 20px;
    gap: 20px;
    flex-wrap: wrap;
}

.warningText {
    font: $font-small $regular;
    color: $red-persian
}

