@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.card {
    position: relative;
    background: $grey-concrete;
    width: calc(100% - 430px);
    border-radius: 10px;
    padding: 20px;
    @media screen and (max-width: 1150px) {
        margin-left: unset;
        width: 100%;
        margin-top: 20px;
    }
}

.expandedCard {
    display: flex;
    flex-wrap: wrap;
    composes: card;
    width: calc(100% - 20px);
    margin-left: 20px;
    @media screen and (max-width: 1150px) {
        margin-left: unset;
        width: 100%;
        margin-top: 20px;
    }
}

.selectedDevice {
    width: 100%;
}
.expandedSelectedDevice {
    width: 50%;
    @media screen and (max-width: 1150px) {
        width: 100%;
    }
}

.graphSection {
    width: 100%;
}

.infoSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font: $font-medium $regular;
    gap: 5px;
}

.infoHeader {
    font: $font-medium $demi-bold;
    width: 170px;
}
.deviceInfo {
    display: flex;
    width: calc(100% - 175px);
    justify-content: flex-end;
    gap: 5px;
    @media screen and (max-width: 720px) {
        width: 100%;
        justify-content: space-between;
    }
    @media screen and (max-width: 450px) {
        flex-wrap: wrap;
    }
}

.deviceName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: $demi-bold;
    min-width: 85px;
}
.timeInUse {
    white-space: nowrap;
}

.graph {
    rect {
        fill: $blue-celestial;
        width: 15px;
        stroke: none;
        &:hover {
            opacity: 1;
            cursor: pointer;
        }
    }
    .highcharts-point-select {
        fill: $blue-celestial !important;
        opacity: 1;
        stroke: none !important;
    }
}

.graphNotInUse {
    composes: graph;
    rect {
        fill: $green-spring;
        width: 15px;
        &:hover {
            opacity: 1;
            cursor: pointer;
        }
    }
}

.expandButton {
    background: $yellow-sunlight;
    position: absolute;
    transform: rotate(-90deg);
    border: unset;
    width: 70px;
    height: 25px;
    overflow: hidden;
    left: -48px;
    top: 97px;
    padding: 0 10px;
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1150px) {
        transform: unset;
        top: -25px;
        left: calc(50% - 35px);
    }
}

.greyDatesSeries {
    path {
        fill: $grey-porcelain;
    }
    text {
        fill: $oslo-gray !important;
    }
}
