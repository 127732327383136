@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';
@import '../../style/variables/media.scss';

.wrapper {
    max-width: 250px;
    width: 100%;
    z-index: 1;
}

.paper {
    max-height: 400px;
    overflow: scroll;
    width: 100%;
}
