@import '../../style/variables/colors.scss';
@import '../../style/variables/fonts.scss';

.component {
    width: 250px;
    transition: width 0.5s;
    input {
        font: $font-medium $regular;
        color: $oslo-gray;
    }
    i {
        color: $oslo-gray;
    }
    &:hover {
        i, input {
            color: $black-shark;
        }
    }
}
.disabled {
    &:hover {
        i, input {
            color: $oslo-gray; // no change when disabled
        }
    }
}

.notExpanded {
    width: 124px;
    transition: width 0.5s;
}

