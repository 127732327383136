@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.paddingBottom {
    padding-bottom: 20px;
}

.scopeWrapper {
    padding-bottom: 20px;
    width: 100%;
}
