@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';

.body {
    height: 40px;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
}

.text {
    font: $font-large $medium;
}

.subText {
    font: $font-medium $regular;
}

.input {
    width: 100%;
    max-width: 240px;
}

.editSection {
    display: flex;
    justify-content: space-between;
}

.editNameSection {
    display: flex;
    align-items: center;
    gap: 10px;
}

.inputSection {
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
}
