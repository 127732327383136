@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';

.container {
    padding: 25px 30px;
    max-width: 800px;
    background-color: $white;
    border-radius: 10px;
    width: 100%;
}
