@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';

.deviceInfo {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 30px;
}

.serialNumber {
    font: $font-small $regular;
}

.metaData {
    display: flex;
    align-items: center;
    gap: 5px;
}

.sensors {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
}

.sensor {
    display: flex;
    gap: 5px;
}

.sensorIcon {
    display: flex;
    > svg {
        fill: $grey-shuttle;
        height: 20px;
        width: 20px;
    }
}

.hub {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.deviceTitle {
    font-size:$font-medium; ;
    font-weight: 500;
    color: $black-shark;
    margin-left: 6px;
}

.toSpaceButton {
    margin-left: 6px;
}



