@import './variables/colors.scss';
@import './variables/media.scss';

.options-row {
    &__action-container {
        display: flex;
        height: 48px;
        align-items: center;
        gap: 5px;
        &--full-width {
            width: 100%;
            justify-content: space-between;
        }
        .ant-picker {
            height: 33px;
            border: none;
            margin-bottom: unset;
        }
    }

    &__filter-buttons {
        display: flex;
        align-items: center;
        background: $white;
        max-height: 35px;
        border-radius: 5px;
        gap: 5px;
        &--full-width {
            width: 100%;
            justify-content: space-evenly;
        }
    }
}
