@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';

.tableContainer {
    border: 1px solid $grey-nepal;
    border-radius: 10px;
    border-spacing: 0;
    table-layout: fixed;
    width: 100%;

    .headerRow th {
        text-align: left;
        padding: 2px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100px;
        max-width: 100px;
        border-bottom: 1px solid $grey-nepal;
        font-family: $demi-bold;
    }

    .dataRow td {
        text-align: left;
        padding: 2px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100px;
        max-width: 100px;
    }

    .separatorRow td {
        border-bottom: 1px solid $grey-nepal;
    }

    .separatorRow:last-child td {
        border-bottom: none;
    }

    td:first-child, th:first-child {
        max-width: 130px;
        padding-left: 20px;
    }
}
