@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.slimTable {
    border-radius: 10px;
    margin: 10px 0 25px 0;
    background-color: $white;
    @media screen and (min-width: $mobile-max) {
        display: none;
    }
}

.LimitingWarning {
    display: flex;
    align-items: center;
    margin: 20px 0;
    gap: 10px;
}
