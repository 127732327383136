@import '~commons/src/style/variables/colors.scss';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';
@import '~commons/src/style/mixins.scss';

.container{
    width: 100%;
    height: 100%;
    border-spacing: 0;
}

.header{
    margin-bottom: 10px;
}

.body{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.placeholderHeader{
    margin: 0 !important;
    height: 46px !important;
    width: 100%;
    border-radius: 10px 10px 0 0;
}

.placeholderRow{
    display: flex;
    align-items: center;
    height: 40px;
    width: 100%;
    &:not(:last-child){
        border-bottom: 1px solid $grey-ash;
    }
    td{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.placeholderCell {
    height: 35% !important;
    width: 75% !important;
    border-radius: 4px;
    margin: 0 0 2.5px 0 !important;
}
