@import "~commons/src/style/variables/colors";
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';
@import './Common.module';

.list {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    background-color: $white;
    border-radius: 10px;
}

.item {
    display: grid;
    grid-template-columns: 10% 90%;
    grid-template-rows: auto;
    grid-template-areas:
        "expand-button header"
        "expand-button offline-info";
    padding: 15px 30px;

    & + & {
        border-top: 1px solid $grey-nepal;
    }
}
.itemWrapper {
    & + & {
        border-top: 1px solid $grey-nepal;
    }
}

.expandButton {
    grid-area: expand-button;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.itemHeader {
    overflow: hidden;
    text-overflow: ellipsis;
    grid-area: header;
    font-family: $demi-bold;
    padding: 8px 0 16px 0;
    font-size: 18px;
}

.itemOfflineInfo {
    grid-area: offline-info;
    font-family: $regular;
    display: flex;
    flex-direction: row;
    gap: 30px;

    & > div {
        display: flex;
        flex-direction: column;
    }
}
