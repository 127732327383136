@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.wrapper {
    background: $white;
    border-radius: 10px;
    padding: 20px;
}

.buttonWrapper {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 10px;
    margin-top: 20px;
    justify-content: center;
}

.confirmationInfo {
    font: $font-medium $regular;
}

.changesInfo {
    font: $font-medium $regular;
    display: flex;
    gap: 5px;
    padding-top: 20px;
}

.thanksInfo {
    font: $font-medium $demi-bold;
}

.imageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
}

