@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';

.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
}

.body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px 60px;
    width: 100%;
    height: 25vh;
}

.text {
    font: 18px $regular;
    color: $black-shark;
    max-width: 400px;
}
