@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';
@import '~commons/src/style/mixins';

.spaceDevices {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 10px;
    margin-top: 15px;
}

.overviewList {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
