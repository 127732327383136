@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';

.pageContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.excludedDevicesHeader {
    font: $font-large $regular;
    margin-bottom: 10px;
    margin-top: 30px;
}

.excludedDevice {
    font: $font-medium $regular;
    margin-bottom: 10px;
}

.dropdownWrapper {
    display: flex;
}

.spinnerWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: -32px;
}

.errorWrapper {
    padding-top: 30px;
}
