@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.labelsHeader {
    color: $black-shark;
    font: $font-medium $demi-bold;
    display: flex;
    align-items: center;
    border: unset;
    background-color: unset;
    margin: 20px 0;
}

.planBadge {
    background-color: $blue-marble;
    border-radius: 10px;
    padding: 5px 10px;
}

.infoParagraph {
    display: flex;
    align-items: center;
    gap: 5px;
    font: $font-medium $regular;
    padding: 10px 0;
}

.labelsSectionOpen {
    padding-bottom: 30px
}
.labelsSectionClosed {
    padding-bottom: 10px
}

.errorWrapper {
    margin-top: 15px;
}
