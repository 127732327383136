@import '~commons/src/style/variables/media.scss';
@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';


.wrapper {
    padding: 20px
}

.settingsContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font: $font-medium $regular;
    color: $black-shark;
    margin-bottom: 20px;
}

.buttonContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
}


.deleteContent {
    display: flex;
    font: $font-medium $regular;
    justify-content: center;
    color: $black-shark;
    margin-bottom: 20px;
}

.moveContent {
    min-height: 200px;
}

.selectorWrapper {
    display: inline-grid;
    width: 100%;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    gap: 10px 20px
}
