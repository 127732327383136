@import './variables/colors.scss';
@import './variables/fonts.scss';
@import './variables/media.scss';

.single-device {
    &__options-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: -10px;
        background-color: $white;
        padding: 0 30px;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: 1;
        height: 55px;

        &--fit-height {
            height: fit-content;
            padding: 20px;
        }

        @media screen and (max-width: $sidebar-disappear) {
            top: 60px;
        }
    }
    &__header-row {
        display: flex;
        justify-content: space-between;
        color: $black-shark;
        font-size: 14px;
        font-family: $demi-bold;
        &__graph {
            display: flex;
        }
        &__real-time {
            display: flex;
            align-items: center;
            width: 160px;
            margin-right: 14px;
            padding-left: 3px;
        }
        &__historical {
            display: flex;
            align-items: center;
            gap: 10px;
        }
        &__reset-zoom {
            margin-left: 10px;
            display: flex;
            align-items: center;
            gap: 10px;
        }
        &__pollutant {
            padding-left: 3px;
        }
    }

    .virtual-sensors-header {
        font: 18px/25px $demi-bold;
        color: $black-shark;
        margin-bottom: -15px;
    }
}

.sensor-row {
    height: 120px;
    margin: 30px 0;
    display: flex;
    &--show-legend {
        height: 140px;
    }
    &--expanded {
        height: 215px;
    }
    &--mobile {
        display: block;
        height: 270px;
        border: 1px solid $grey-porcelain;
        border-radius: 10px;
        background-color: $white;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14);
    }
    .sensor {
        &__block {
            height: fit-content;
            width: 120px;
            margin-top: 23px;
            margin-bottom: 0;
            .sensor__icon {
                padding-top:7px;
                &--unit-top {
                    padding-top: 20px;
                }
            }
            &__only-name {
                margin-top: unset;
                align-items: center;
                height: 100%;
                display: flex;
                gap: 5px;
                word-break: break-word;
                svg { min-width: 24px }
            }
        }
        &__value {
            font-size: 32px;
            display: flex;
            margin-bottom: -10px;
        }
        &__unit {
            margin-top: 5px;
        }
    }

    &__current-value {
        margin-right: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 133px;
        border: 1px solid $grey-porcelain;
        border-radius: 10px;
        background-color: $white;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.04);
        &__lined {
            width: 80%;
            border-bottom: 1px solid $grey-ash;
            margin: 15px 0 10px 0;
        }
        &__edge-values {
            width: 100%;
            padding: 5px 15px;
            color: $black-shark;
            display: flex;

            .sensor {
                &__value {
                    font-size: 14px;
                    margin-bottom: 0;
                    flex-wrap: wrap;
                }

                &__unit {
                    margin-top: 0;
                    &--risk {
                        margin: 0 0 5px 2px;
                        font-size: 10px;
                    }
                }
            }
        }

        &__header {
            font: 14px/20px $regular;
            min-width: 30px;
        }
        &__link {
            font: 11px/15px $regular;
            color: $black-shark;
            margin-top: 2px;
            text-align: center;
        }
    }
    &__current-value-mobile {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;

        .sensor {
            &__block {
                width: fit-content;
                margin-bottom: 10px;
            }
            &__title {
                line-height: 1.5;
            }
            &__value {
                font: 24px $demi-bold;
                line-height: 1;
                &--block {
                    display: block;
                }
            }
            &__unit {
                margin: 0;
            }
        }
    }
    &__historical {
        align-items: flex-start;
        position: relative;
        flex-wrap: wrap;
        padding: 0 10px;
        display: flex;
        height: 100%;
        max-width: 1017px;
        border: 1px solid $grey-porcelain;
        border-radius: 10px;
        background-color: $white;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
        width: -webkit-calc(100% - 147px);
        width:    -moz-calc(100% - 147px);
        width:         calc(100% - 147px);

        div:first-child:not(.centered__content) {
            width: 100%;
            height: 97%;
            margin-top: 3px;
        }
        &--full-width {
        max-width: 100%;
            width: 100%;
        }
        &--text {
            display: inline;
            padding: 15px;
            overflow-y: auto;
            overflow-x: hidden;
        }
        &__bold {
            font-family: $demi-bold;
        }
        .centered {
            width: 100%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__historical-mobile {
        padding: 0 20px;
    }
}

.highcharts-tooltip-box {
    fill: rgba(57, 69, 77, 0.25); //$black-shark;
}
