@import '../../style/variables/colors.scss';
@import '../../style/variables/fonts.scss';

.badge {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    min-width: 24px;
    padding: 5px;
    border-radius: 53px;
    background-color: $yellow-sunlight;
    font-family: $ultra-thin;
    font-size: 13px;
}