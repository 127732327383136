@import "~commons/src/style/variables/colors";
@import '~commons/src/style/variables/fonts.scss';

.iframe {
    width: 100%;
    max-width: 1024px;
    height: 100vh;
    max-height: 1200px;
    border: none;
    border-radius: 10px;
}

.iframeContainer {
    width: 100%;
    padding: 5px;
}

.description {
    font: 15px/20px $regular;
    max-width: 1194px;
    margin: 25px 85px 0 85px;
}

.pageWrapper {
    display: flex;
    max-width: 1034px;
    margin: 25px 85px 0 85px;
    justify-content: flex-start;

    background: $white;
    border-radius: 10px;
    padding: 0;
}
