@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';

.statusBarWrapper {
    display: flex;
    background-color: $white;
    border-radius: 10px;
    padding: 10px 20px;
    gap: 10px;
    font-size: $font-medium;
    flex-wrap: wrap;
    justify-content: space-between;
}

.statusBarTotal{
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
}

.statusBarBold{
    font-family: $demi-bold;
}

.statusBarContent {
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
        width: 28px;
        height: 28px;
    }
}


