@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';
@import '~commons/src/style/mixins';

.formWrapper {
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;
    padding: 20px;
    background-color: $white;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    color: $black-shark;
    font-size: $font-medium;
    font: $demi-bold;
    margin: 0;
}

.form {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
}

.addDeviceSection {
    display: flex;
    flex-wrap: wrap;
    max-width: 830px;
    @media screen and (max-width: $tablet-max) {
        gap: 10px;
    }
}

.closeButton {
    width: min-content;
    font-size: $font-medium;
    color: $blue-azul;
    button {
        font-size: $font-medium;
    }
}

.footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.externalLink {
    display: flex;
    align-items: center;
    gap: 5px;
    color: $blue-azul;
    font-size: $font-small;
    span:hover {
        text-decoration: underline;
    }
}
