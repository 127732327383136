@import "./variables/fonts.scss";
@import './variables/colors.scss';

.compare-card {
    display: table-cell;
    text-align: center;

    &--outdoor {
        background: $grey-ash;
    }

    &__title {
        font-size: 13px;
        margin-top: 33px;
    }

    &__icon {
        font-size: 20px;
        margin-right: 3px;
        vertical-align: bottom;
        &--weather {
            position: absolute;
            z-index: 1;
            svg {
                vertical-align: top;
                width: 43px;
                height: 43px;
                color: $black-shark;
                fill: $black-shark;
            }
        }
    }

    &__sensor-data {
        &--temp {
            margin-top: 6px;
            &--outdoor {
                position: relative;
                z-index: 2;
                margin-left: 30px;
                border-radius: 25px;
            }
        }
    }

    &__sensor-value {
        font-family: $medium;
        display: flex;
        margin-top: 20px;
        margin-bottom: -10px;
        font-size: 54px;
        &--pressure {
            font-size: 40px;
            margin-top: 32px;
        }
    }

    &__sensor-unit {
        font-family: $regular;
        margin-top: 3px;
        font-size: 26px;
        vertical-align: top;
        &--pressure {
            font-size: 15px;
            vertical-align: baseline;
        }
    }
}
