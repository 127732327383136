@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';

.focusSelector {
    width: 100%;
    background-color: $white;
    margin: 10px;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    min-height: 66px;
}

.errorWrapper {
    width: 100%;
    margin: 0 14px;
}

.focusOptions {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: calc(100% - 280px);
    min-width: 300px;
    gap: 15px 30px;
    @media screen and (max-width: 740px) {
        width: 100%;
    }
}
.leftSection {
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap;
    align-items: center;
}

.focusDescription {
    width: 280px;
    font-size: 15px;
    padding-right: 10px;
}

.elements {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;

    .ant-picker {
        height: 33px;
        border: none;
        margin-bottom: unset;
    }
}
.btnSecondary {
    padding: 7px;
}
