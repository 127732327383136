@import './variables/colors.scss';
@import './variables/fonts.scss';
@import './variables/media.scss';

.list-tile {
    height: 90px;
    width: 100%;
    min-width: 370px;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
    background-color: $white;
    margin: 14px 0;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;

    &--link {
        width: 100%;
        @media screen and (max-width: $mobile-max) {
            width: 370px;
            margin: 0 14px;
            display: block;
        }
    }

    &:hover:not(&--no-hover) {
        cursor: pointer;
        box-shadow: 0 14px 28px rgba(162, 164, 165, 0.1), 0 10px 10px rgba(162, 164, 165, 0.1);
        -webkit-box-shadow: 0 14px 28px rgba(162, 164, 165, 0.1), 0 10px 10px rgba(162, 164, 165, 0.1);
    }

    &__main-info {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        padding-right: 10px;
        @media screen and (min-width: $tablet-max) {
            max-width: 438px;
            margin-right: 0;
        }
    }

    &__header {
        display: flex;
        align-content: center;
        align-items: center;
        max-width: calc(100% - 110px);

        &--full-width {
            max-width: 100%;
        }

        &__section {
            display: inline-block;
            margin-left: 20px;
            max-width: calc(100% - 80px);
        }

        &__title {
            &--name {
                margin: 0;
                font-size: 20px;
                line-height: 27px;
                font-family: $bold;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-right: 5px;
            }
        }

        &__last-synced {
            font-size: 12px;
            font-family: $regular;
            color: $grey-shuttle;
        }
    }

    .tile__status {
        margin-top: 8px;
    }

    &__body {
        height: 90px;
        padding: 0 40px;
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        @media screen and (max-width: $tablet-max) {
            display: none;
        }

        &--no-data {
            width: 50%;
            justify-content: center;
        }
    }
}
