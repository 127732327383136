@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

$breakpoint: 600px;

.searchInput {
    width: 100%;
    gap: 0 !important;
    input {
        margin: 0;
    }
}

.modalWrapper {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
}

.modal {
    width: 100%;
    height: fit-content;
    max-height: 600px;
    overflow: hidden;
    max-width: 500px;
    @media screen and (max-width: $breakpoint) {
        margin-left: 10px;
        margin-right: 10px;
    }
}

.searchContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3px;
    div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
    }
    @media screen and (max-width: $breakpoint) {
        gap: 10px;
        div {
            flex-direction: column;
            align-items: flex-start;
            flex-grow: 1;
        }
    }
}

.errorMessage {
    color: $red-persian;
    font-size: $font-small;
    font-weight: 400;
}

.resultList {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
    height: 100%;
    max-height: 430px;
    padding: 10px 0;
}

.listItem {
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.locationName {
    display: block;
    white-space: nowrap;
    width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.deviceLink {
    display: block;
    width: 250px;
}

.notFound {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    svg {
        width: 64px;
    }
}
