@import './variables/colors.scss';
@import './variables/fonts.scss';

@keyframes slide-in {
    from {
        top: -100%;
    }
    to {
        top: 54px;
    }
}

.sidebar-navigation {
    background-color: $white;
    width: 300px;
    height: 100vh;
    position: fixed;
    z-index: 501;
    top: 0;
    left: 0;
    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, .20);
        z-index: 501;
    }
    &--slim {
        width: 90px;
    }
    &__header {
        width: 100%;
        margin-top: 30px;
        height: 130px;
        display: flex;
        justify-content: center;
        &:focus, a:focus {
            outline: none;
        }
    }
    &__logo-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 206px;
        height: 110px;
    }
    &__logo {
        height: 110px;
        &:focus {
            outline: none;
        }
    }
    &__items-container {
        overflow-y: auto;
        padding-left: 30px;
        height: 40vh;
        &--hamburger {
            z-index: 501;
            background: transparent;
            width: 33px;
            height: 33px;
            padding: 5px;
            border: unset;
            &:focus {
                outline: unset;
                .menu-icon__line {
                    background: $blue-aura;
                }
            }
        }

        &__item {
            display: flex;
            align-items: center;
            height: 45px;
            font-family: $demi-bold;
            font-size: 18px;
            text-decoration: none;
            list-style: none;
            color: $oslo-gray;
            fill: $oslo-gray;
            &--component-tab {
                width: 100%;
                border: unset;
            }
            &:focus {
                outline: none;
                .sidebar-navigation__items-container__element {
                    color: $blue-aura;
                    fill: $blue-aura;
                }
            }

            &:hover,
            a:hover,
            :hover,
            &--active {
                cursor: pointer;
                color: $black-shark;
                fill: $black-shark;
            }

            &--slim {
                display: none;
                visibility: hidden;
            }
            svg {
                height: 24px;
                padding-right: 10px;
            }
            &__icon {
                padding-right: 10px;
            }

            &--ellipsis {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            &--center {
                align-items: center;
                justify-content: center;
            }

            &--function-item {
                height: auto;
                padding: 0 25px 0 30px;
                margin-top: 25px;

                :hover {
                    cursor: pointer;
                }

                .sidebar-navigation__items-container__item {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &--border-top {
                    border-top: 1px solid $oslo-gray;
                    padding-top: 5px;
                }
            }
            &--powered-by {
                margin-top: 20px;
                font-size: 13px;
                justify-content: center;
                color: $black-shark;
                fill: $grey-river-bed;
                &:hover {
                    cursor: default;
                }
                &__logo {
                    height: 34px;
                    padding-left: 9px;
                }
            }
        }
        &__element {
            display: flex;
            align-items: center;
            min-width: 0;
        }
    }

    &__profile {
        width: 100%;
        padding-top: 15px;
        padding-bottom: 25px;
        margin-bottom: 20px;
        border-bottom: 1px solid $oslo-gray;

        &__selector {
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &__info {
            width: 100%;
            margin-top: 10px;
            &__text {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                word-break: break-word;
                color: $black-shark;
                font: 15px/24px $regular;
                padding: 0 15px;
                &--bold {
                    font-family: $demi-bold;
                }

                &--section {
                    margin-top: 15px;
                }
            }
        }
    }
}

.hamburger-navigation-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 600;
    width: 100%;
    border-bottom: 1px solid $grey-porcelain;
}

.hamburger-navigation {
    height: 54px;
    background-color: $white;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .sidebar-navigation__items-container--hamburger {
        .menu-icon__line {
            background: $black-shark;
        }
        &:focus {
            outline: unset;
            .menu-icon__line {
                background: $blue-aura;
            }
        }
    }

    &__items-container {
        text-decoration: none;
        color: $black-shark;
        display: flex;
        list-style: none;
        height: 100%;
        align-items: center;
        &__item {
            text-decoration: none;
            color: $black-shark;
        }
        &--hamburger {
            display: flex;
            &__links {
                background-color: $white;
                opacity: 0;
                position: absolute;

                .sidebar-navigation__items-container {
                    &__item {
                        line-height: 45px;
                        padding: 0 25px;
                        font-family: $medium;
                        list-style: none;
                        text-align: center;
                        color: $oslo-gray;
                        fill: $oslo-gray;
                        :hover,
                        &--active {
                            cursor: pointer;
                            color: $black-shark;
                            text-decoration: none;
                            fill: $black-shark;
                        }
                        &--function-item {
                            border-top: 0.5px solid $oslo-gray;
                            margin-top: 20px;
                            height: 78px;
                            &--border-bottom {
                                border-top: unset;
                                border-bottom: 0.5px solid $oslo-gray;
                                margin-top: unset;
                                margin-bottom: 20px;
                            }

                            .sidebar-navigation__items-container__element {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                outline: unset;
                                &:focus {
                                    outline: none;
                                    color: $blue-aura;
                                    fill: $blue-aura;
                                }
                            }
                        }
                    }
                    &__element {
                        width: 100%;
                    }
                }
                &.open {
                    animation: slide-in 0.3s ease 0s forwards;
                    opacity: 1;
                    position: absolute;
                    width: 100%;
                    z-index: 600;
                    -webkit-box-shadow: 0 1px 3px rgba(162, 164, 165, 0.12),
                    0 1px 2px rgba(162, 164, 165, 0.24);
                    box-shadow: 0 1px 3px rgba(162, 164, 165, 0.12),
                    0 1px 2px rgba(162, 164, 165, 0.24);
                }
            }
        }
    }
}

//haburger icon
.menu-icon {
    background: transparent;
    width: 18px;
    height: 18px;
    position: relative;
    top: -2px;
    cursor: pointer;
    border: none;
    span {
        height: 3px;
        width: 100%;
        background-color: $black-shark;
        display: block;
        border-radius: 9px;
        opacity: 1;
        -webkit-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
    }
    .menu-icon__line {
        color: $black-shark;
    }
    .first {
        -webkit-transform: translateY(9px);
        transform: translateY(9px);
    }
    .second {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    .third {
        -webkit-transform: translateY(9px);
        transform: translateY(9px);
    }
    &.open {
        span {
            &:nth-child(1) {
                top: 18px;
                width: 0;
                left: 50%;
            }
            &.second {
                -webkit-transform: rotate(45deg) translateY(4px) translateX(4.5px);
                transform: rotate(45deg) translateY(4px) translateX(4.5px);
            }
            &.third {
                -webkit-transform: rotate(-45deg) translateY(2px) translateX(-2px);
                transform: rotate(-45deg) translateY(2px) translateX(-2px);
            }
        }
    }
}
