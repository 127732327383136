@import '../../style/variables/colors.scss';
@import '../../style/variables/fonts.scss';
@import '../../style/variables/media.scss';

.listWrapper {
    margin-bottom: 35px;
    position: relative;
}
.centerList {
    display: flex;
    justify-content: center;
}

.list {
    font: 18px/28px $regular;
    height: 308px;
    overflow-y: auto;
    width: 300px;
    max-width: 300px;
    padding-right: 20px;
    @media screen and (min-width: 550px) and (max-width: $mobile-max) {
        width: 100%;
    }
    @media screen and (max-width: 550px) {
        max-height: 308px;
        height: auto;
    }
}

.fullHeightList {
    height: unset;
    padding-right: unset;
    max-height: unset;
}


.label {
    display: flex;
    height: 44px;
    align-items: center;
    gap: 5px;
    font: $font-medium $regular;
}

.disabled {
    composes: label;
    color: $oslo-gray;
    >svg, >i {
        fill: $oslo-gray;
        color: $oslo-gray;
    }
}

.labelText {
    display: flex;
    flex-direction: column;
}

.subText {
    font: $font-small $regular;
}
