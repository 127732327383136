@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';



.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
}

.cancelButton {
    display: flex;
    justify-content: center;
}

.body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px 60px;
    width: 100%;
    height: 30vh;
}

.suffix {
    font: $font-large $regular;
}

.text {
    display: flex;
    flex-direction: column;
    white-space: pre-wrap;
    color: $black-shark;
    gap: 5px;
    max-width: 500px;
}

.title {
    font: 26px $medium;
}

.subTitle {
    font: $font-large $regular;
}

.buttons {
    display: flex;
    align-items: center;
    gap: 20px;
}

.roleText {
    font: $font-large $regular;
}

// Delete all below when feature toggle for floor plans is removed
.noSpaces {
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    flex-wrap: nowrap;
}
.buttonsV1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}
