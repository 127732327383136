@import "~commons/src/style/variables/colors";
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

$breakpoint: 1200px;

.rowHeader{
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    background-color: $white;
    padding: 10px 30px;
    gap: 20px;
}

.headerContent{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 4px;
    align-items: center;
    font-size: $font-medium;

    @media screen and (min-width: $breakpoint) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    &:not(:last-child){
        border-bottom: 1px solid $grey-nepal;
    }
}

.buildingsInfo{
    display: flex;
    gap: 20px;
    align-items: center;
    width: fit-content;
    min-width: 250px;

    @media screen and (max-width: $breakpoint) {
        min-width: unset;
        width: fit-content;
    }
}

@mixin focused() {
    &:hover{
        color: $blue-aura !important;
    }
    &:focus, &:focus-within{
        outline: 2px solid $blue-aura !important;
        outline-offset: 1px;
    }
}

.summaryWrapper {
    width: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.toggleButton{
    @include focused();
    visibility: visible;
    margin-right: 20px;
}

.visuallyHidden{
    visibility: hidden;
}

.buildingName{
    width: calc(100% - 1px);
    max-width: 250px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $blue-azul;

    @media screen and (max-width: $breakpoint) {
        width: max-content;
        max-width: unset;
        text-overflow: unset;
    }


    @include focused();
    &:hover{
        text-decoration: underline;
    }
}

.bodyWrapper{
    width: 100%;
    overflow: hidden;
    padding: 20px;
    box-sizing: border-box;

    @media screen and (max-width: $breakpoint) {
        padding: 20px 0;
    }
}

.deviceName{
    overflow: hidden;
    display: block;
    width: calc(100%);
    text-overflow: ellipsis;
}
