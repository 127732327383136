@import './variables/colors.scss';
@import './variables/fonts.scss';

input:required {
    box-shadow:none;
}

.form {
    padding-bottom: 60px;
    width: 100%;
    &--medium-padding {
        padding-bottom: 30px;
    }
    &__header {
        border-radius: 10px;
        padding: 22px 0 20px 35px;
        &--padding-bottom {
            padding-bottom: 50px;
        }
    }
    &__container {
        width: 95%;
        max-width: 282px;
        margin: 0 auto;
        &--center {
            text-align: center;
        }
    }
    &__qr-container {
        display: flex;
        flex-direction: column;
        max-width: 378px;
    }
    &__wide-container {
        padding: 35px;
        &--pdf {
            height: 800px;
            iframe {
                width: 100%;
                height: 100%;
            }
        }
    }
    &__row {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        &> * {
            margin-left: 10px;
            margin-right: 10px;
        }
        &--slim-margin {
            &> * {
                margin-left: 7px;
                margin-right: 7px;
            }
        }
        &--no-left-margin {
            &> * {
                margin-left: 0;
            }
        }
        &--spaced {
            justify-content: space-between;
        }
        .no-margin {
            margin: unset;
            &--side {
                margin-left: unset;
                margin-right: unset;
            }
        }
        &--padded-small {
            margin-bottom: 20px;
        }
        &--padded-medium {
            margin-bottom: 40px;
        }
        &--padded-large {
            margin-bottom: 70px;
        }
        &--undertext {
            margin: -15px auto 25px 0;
            padding-left: 0;
        }
        &--centered {
            justify-content: center;
        }
        &--centered-padded {
            margin: 0 0 45px 0;
            justify-content: center;
            label {
                justify-content: center;
                display: flex;
                padding-bottom: 5px;
            }
        }
        &--center-vertical {
            align-items: center;
        }
        &__time-input {
            white-space: nowrap;
            width: 117px;
        }
        &__selected-device {
            width: 100%;
            max-width: 710px;
            &__content {
                &--wide {
                    display: flex;
                    @media screen and (max-width: 740px) {
                        display: none;
                    }
                }
                &--slim {
                    display: none;
                    @media screen and (max-width: 740px) {
                        display: flex;
                    }
                }
            }
        }
    }
    &__button-container {
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
        margin: auto 0 0 0;
        &--margin-bottom {
            > button {
                margin-bottom: 10px;
            }
        }
        &--no-padding {
            padding-bottom: 0;
        }
        &--margin-top {
            margin-top: 20px;
        }
        &--margin-top-50 {
            margin-top: 50px;
        }
        &--left {
            justify-content: left;
            margin-left: -10px;
        }
        &--right {
            justify-content: right;
            margin-right: -10px;
        }
        &--float-top {
            margin-top: 0;
            margin-bottom: auto;
        }
    }
    &__field {
        display: flex;
        flex-grow: 1;
        .labeled-text,
        .input-container {
            flex-grow: 1;
        }
        &--no-flex-grow {
            flex-grow: unset;
        }
        &--half {
            flex-basis: calc(50% - 20px);
            min-width: 205px;
        }
        &--padded-small {
            margin-bottom: 10px;
        }
        &--padded {
            margin-bottom: 70px;
        }
        &--single-width {
            max-width: 339px;
        }
        &--medium-width {
            max-width: 425px;
        }
        &--standard-width {
            width: 261px;
            min-width: 261px;
        }
        &--medium-slim {
            width: 180px;
        }
        &--slim {
            width: 110px

        }
        &--baseline {
            align-items: baseline;
            margin-bottom: 10px;
            button {
                margin-left: 20px;
            }
        }
        &--no-margin-left {
            margin-left: 0;
        }
    }

    &__attr {
        display: block;
        font-size: 14px;
        font-family: $regular;
        color: $black-shark;

        &--mandatory:before {
            content: "*";
            margin-left: 3px;
            color: $red-auburn;
        }
        &--element {
            margin-bottom: 20px;
            &--full-width {
                width: 100%;
            }
        }

        &--edit-image {
            color: $oslo-gray;
            margin-bottom: 20px;
            font: 13px/18px $regular;
            text-align: center;
            &--logo {
                color: $black-shark;
                margin-bottom: 20px;
                font: 13px/18px $regular;
                text-align: center;
            }
        }
        &--image {
            height: 120px;
            width: 120px;
            border-radius: 10px;
            background: $grey-ash;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto 10px auto;
            > img {
                max-width: 120px;
                max-height: 120px;
            }
            &.no-margin {
                margin: 0;
            }
            &--large {
                height: 240px;
                width: 100%;
                > img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            &--medium {
                height: 240px;
                width: 100%;
                max-width: 500px;
                > img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            &__placeholder {
                display: flex;
                flex-direction: column;
                .text-wrap {
                   white-space: pre-wrap;
                }
            }
        }

        &__info {
            cursor: pointer;
            display: inline-block;
            position: relative;
            height: 20px;

            &__icon {
                cursor: pointer;
                margin-left: 5px;
                outline: none !important;
                color: $black-shark;
                font-size: 22px;
                margin-top: -3px;

                &:focus {
                    color: $blue-celestial;
                }
            }
        }

        &--input {
            font-size: 14px;
            font-family: $regular;
            background-color: $white;
            display: flex; // to make mobile view look better
            height: 46px;
            width: 100%;
            margin: 0 0 20px 0;
            border-radius: 10px;
            border: 1px solid $grey-mist;

            &--bottom-margin {
                margin-bottom: 10px;
            }
        }

        &--textarea {
            font-size: 14px;
            background-color: $white;
            width: calc(100% - 6px);
            margin: 0 0 20px 0;
            border-radius: 10px;
            border: 1px solid $grey-mist;
            resize: none;
            color: $black-shark;
            overflow: hidden;
            font-family: $regular;
            padding: 10px;
        }

        &--checkbox {
            text-align: left;
            padding-left: 5px;
            cursor: pointer;
            color: $black-shark;
            font-family: $regular;
            &--as-label {
                margin-bottom: -14px;
                font-size: 18px;
            }
        }

        &--invalid {
            background-color: $red-chablis;
        }
    }

    &__response-message {
            margin: 0;
            font-size: 13px;
            color: $black-shark;
            text-align: center;
            white-space: pre-wrap;
        &--failed {
            color: $red-auburn;
        }
        &--success {
            font-family: $demi-bold;
        }
        &--padded {
            margin: 10px auto;
        }
        &--right {
            text-align: right;
        }
        &--left {
            text-align: left;
            white-space: nowrap;
        }
    }
}

.input-container {
    position: relative;
    &__flex {
        display: flex;
    }
    &--clickable {
        cursor: pointer;
    }
    &--margin-bottom {
        margin-bottom: 20px;
    }
    &--large-margin-bottom {
        margin-bottom: 30px;
    }
    .ant-picker {
        width: 100%;
    }

    &__error {
        color: $red-persian;
        font: 13px/16px $regular;
        margin-bottom: 18px;
    }

    &__hint {
        position: absolute;
        left: calc(100% + 18px);
        top: 19px;
        min-height: 40px;
        width: 190px;
        box-sizing: border-box;
        padding: 8px 10px;
        background: $black-shark;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2);
        border-radius: 4px;
        transition: all .2s ease;
        font-size: 12px;
        line-height: 16px;
        color: $white;
        text-align: left;
        z-index: 2;

        &:before {
            content: '';
            position: absolute;
            left: -18px;
            top: 15px;
            border: 5px solid transparent;
            border-right: 14px solid $black-shark;
        }
        &--checkbox {
            top: -9px;
        }
        &--info {
            color: $black-shark;
            white-space: pre-wrap;
            background: $white;
            border: 1px solid $black-shark;
            top: -10px;

            &:before {
                border: 5px solid transparent;
                border-right: 12px solid $black-shark;
            }
        }
        &--down {
            left: calc((-190px/2));
            top: 30px;
            &:before {
                content: '';
                position: absolute;
                left: calc((190px/2 + 18px/2));
                top: -18px;
                border: 6px solid transparent;
                border-bottom: 11px solid $black-shark;
            }
        }

    }
    &__icon {
        position: absolute;
        right: 10px;
        bottom: 35px;
        width: 15px;
        transition: all .2s ease;
        opacity: 1;

        #checkmark {
            fill: $green-biosphere;
        }

        #cross {
            fill: $red-persian;
        }
    }
    &--invalid {
        .ant-picker {
            background-color: $red-chablis;
        }
    }
    .logo--medium {
        opacity: 1;
    }
    &__string {
        height: 46px;
        line-height: 30px;
        display: flex;
        align-items: center;

    }
    .form__attr--input {
        &:focus {
            outline: -webkit-focus-ring-color auto 5px;
        }
        padding: 0 10px;
        line-height: 20px;
        height: 46px;
        outline: none;
        &--small {
            height: 32px;
        }

        &.input {
            &--validated {
                &.input--invalid {
                    background-color: $red-chablis;
                }
            }
            &--no-bottom-margin {
                margin-bottom: 0;
            }
            &--start-text {
                padding-left: 45px;
            }

        }
    }
}

.location-pick-map {
    height: 350px;
    border: 1px solid $grey-shuttle;
    border-radius: 10px;

    &__placeholder {
        height: 350px;
        border: 1px solid $grey-shuttle;
        border-radius: 10px;
    }

    > div:first-child {
        border-radius: 10px;
    }

    &__container {
        margin-top: 20px;
    }

    .form__attr.form__attr--input {
        left: 0;
        margin: 20px 5%;
        width: 90%;
        outline: none;
        font: 13px/16px $regular;
        padding: 0 10px;
        &:focus {
            outline: -webkit-focus-ring-color auto 5px;
        }
    }
}
