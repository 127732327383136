@import "./variables/colors.scss";
@import "./variables/fonts.scss";
@import "./variables/media.scss";


.hub-info-header {
    &__image {
        display: flex;
        justify-content: center;
        max-height: 95px;
    }
    &__content {
        width: 100%;
        display: flex;
        @media screen and (max-width: 700px) {
            display: block;
        }
        &__spread-section {
            display: flex;
            align-items: center;
            @media screen and (max-width: 700px) {
                justify-content: space-between;
            }
        }
        &__details {
            margin: auto 15px;
            color: $black-shark;
            font: 13px/18px $regular;
        }

        &__specs {
            width: fit-content;
            justify-content: space-evenly;
            height: 80px;
            display: flex;
            flex-direction: column;
            margin-right: 15px;
            margin-left: 15px;
            &--tile {
                margin-right: 0;
                max-width: 70px;
                @media screen and (max-width: $tablet-max){
                    margin-right: 15px;
                    .hub-info-header__content__specs__text {
                        display: none;
                    }

                }
            }

            &__container {
                display: flex;
                width: fit-content;
                align-items: center;

                svg {
                    width: 24px;
                    height: 24px;
                    fill: $black-shark;
                }
            }

            &__text {
                font: 13px/18px $regular;
                color: $oslo-gray;
                margin-left: 10px;
                white-space: nowrap;
            }
        }
    }
}
.hub-device-tile {
    padding: 15px;
    &__device-image {
        align-items: center;
        display: flex;
        padding-left: 5px;
        padding-right: 5px;
    }
    &__content {
        padding-left: 20px;
        color: $black-shark;
        &__header {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font: 18px/25px $demi-bold;
            font-weight: 600;
        }
        &__text {
            font: 13px/18px $regular;
            color: $oslo-gray;
            margin-bottom: 7px;
            &--alert {
                color: $red-auburn;
            }
        }
        .info-header__content__spesifications {
            width: fit-content;
        }
    }
}
