@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

@mixin shared-cell-attributes {
    .cell {
        display: flex;
        align-items: center;
        padding: 0 10px;
    }
    .firstCell {
        flex-basis: 100px;
        flex-grow: 1;
        flex-shrink: 0;
    }
    .seatsCell {
        justify-content: flex-start;
        flex-basis: 50px;
        flex-shrink: 0;
        flex-grow: 2;
    }
}

.cell {
    display: flex;
    align-items: center;
}

.table {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    background-color: $grey-concrete;
    border-radius: 10px;
    margin: 10px 0;
}

.row {
    display: flex;
    padding: 4px 0;
    font-size: 15px;
    font-family: $regular;
    border-top: 1px solid $grey-nepal;
    @include shared-cell-attributes();
}

.header {
    display: flex;
    background: $grey-mist;
    border-radius: 10px 10px 0 0;
    font: $font-medium $regular;
    padding: 5px 0;
    @include shared-cell-attributes();

    .cell {
        white-space: nowrap;
    }
}

.smallText {
    font: $font-small $regular;
}
.seatsCounter {
    max-width: 120px;
}

.subText {
    font: $font-small $regular;
}
