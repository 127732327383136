@import './variables/colors.scss';
@import './variables/fonts.scss';
@import './variables/media.scss';

.table {
    width: 100%;
    vertical-align: top;
    overflow: hidden;
    &__row {
        display: flex;
        justify-content: space-between;
        padding: 13px 10px 10px 10px;
        border-bottom: 1px solid $grey-ash;
        .tile-header__info--chip {
            margin: 0;
        }
        &:focus, &:hover {
            background-color: $grey-porcelain;
            outline: none;
            cursor: pointer;
        }
        &--last {
            border-bottom: none;
            margin-bottom: 25px;
        }
    }
    &__overlay {
        background: linear-gradient(0deg, white 0%, rgba(255,255,255,0.8) 50.86%, rgba(255,255,255,0.3) 100%);
        z-index: 1;
        width: 100%;
        height: 33px;
        margin-top: -33px;
        top:0;
        right:0;
        bottom:0;
        left:0;
        position: relative;
        cursor: pointer;
    }
    &__column {
        width: 28%;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        &--last {
            display: flex;
            justify-content: flex-end;
            width: 15%;
        }
        &--location {
            width: 50%;
        }
        &--first {
            text-align: left;
        }

        &__content {
            white-space: nowrap;
        }
    }
    &__row--rescale-when-small {
        .table__column {
            @media screen and (max-width: $mobile-max) {
                width: 33%;
                &--hidden-when-small {
                    display: none;
                }
            }
        }
    }
}
