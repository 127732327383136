$fonts-dir: "../fonts" !default;

@font-face {
    font-family: 'OpenSans-TrueBold';
    src: url('#{$fonts-dir}/open-sans-v28-latin-700.woff2') format('woff2'),
    url('#{$fonts-dir}/open-sans-v28-latin-700.woff') format('woff'),
    url('#{$fonts-dir}/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');
    font-weight: 700;
}
@font-face {
    font-family: 'OpenSans-DemiBold';
    src: url('#{$fonts-dir}/open-sans-v28-latin-600.woff2') format('woff2'),
    url('#{$fonts-dir}/open-sans-v28-latin-600.woff') format('woff'),
    url('#{$fonts-dir}/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');
    font-weight: 600;
}
@font-face {
    font-family: 'OpenSans-Medium';
    src: url('#{$fonts-dir}/open-sans-v28-latin-500.woff2') format('woff2'),
    url('#{$fonts-dir}/open-sans-v28-latin-500.woff') format('woff'),
    url('#{$fonts-dir}/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');
    font-weight: 500;
}
@font-face {
    font-family: 'OpenSans-Regular';
    src: url('#{$fonts-dir}/open-sans-v28-latin-regular.woff2') format('woff2'),
    url('#{$fonts-dir}/open-sans-v28-latin-regular.woff') format('woff'),
    url('#{$fonts-dir}/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');
    font-weight: 400;
}
@font-face {
    font-family: 'OpenSans-Light';
    src: url('#{$fonts-dir}/open-sans-v28-latin-300.woff2') format('woff2'),
    url('#{$fonts-dir}/open-sans-v28-latin-300.woff') format('woff'),
    url('#{$fonts-dir}/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');
    font-weight: 300;
}
