@import '~commons/src/style/variables/media';
@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';

.add-cert {
    width: 80%;
    margin: 0 auto;

    &__input-upload {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        color: $black-shark;
        font: 14px/19px $regular;
    }
    &__input-element {
        padding-left: 10px;
        display: flex;
        align-items: center;
        color: $black-shark;
        font: 14px/19px $regular;
    }
    &__file-name {
        padding-left: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
    }
    &__actions {
        display: flex;
        margin-bottom: 15px;
        justify-content: space-between;
        width: 100%;
    }
}

.certificate {
    &__info {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        &--icon {
            color: $green-biosphere;
        }

        &--status {
            display: flex;
            flex-direction: column;
            margin-left: 20px;
        }

        &--status-info {
            line-height: 25px;
            margin: 0;
        }
    }

    &__button-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
