@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

@mixin shared-cell-attributes {
    .cell {
        display: flex;
        padding: 8px;
        align-items: center;
    }

    .textCell {
        flex-basis: 350px;
        flex-grow: 2;
    }

    .fractionCell {
        justify-content: flex-end;
        flex-basis: 200px;
        flex-shrink: 0;
        flex-grow: 2;
    }

    .linkCell {
        flex-basis: 50px;
        flex-grow: 1;
        flex-shrink: 0;
    }
}

.row {
    display: flex;
    padding: 8px 24px;
    font-size: 15px;
    font-family: $regular;
    border-top: 1px solid $grey-nepal;
    @include shared-cell-attributes();

    .textCell {
        font-size: 18px;
        font-family: $medium;
    }

    .fractionCell {
        justify-content: flex-end;
    }

    .linkCell {
        justify-content: flex-end;
        cursor: pointer;
    }

    &:hover {
        background-color: $grey-ash;
    }
}
.expandedRow {
    max-height: 500px;
    background-color: $grey-concrete;
    padding: 20px;
    overflow: scroll;
    border-top: 1px solid $grey-nepal;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.expandedRowWrapper {
    position: relative;
}
.expandedRowWrapper::before {
    position: absolute;
    width: 100%;
    height: 20px;
    left: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(175, 175, 175, 0.3) 100%);
    content: '';
}

.table {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    background-color: $white;
    border-radius: 10px;
}

.header {
    display: flex;
    font-size: 18px;
    padding: 8px 24px;
    @include shared-cell-attributes();

    .cell {
        white-space: nowrap;
        font-family: $medium;
    }

    .textCell {
        flex-direction: row-reverse;
        justify-content: flex-end;
    }
}
