@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.infoWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.subscriptionInfo {
    display: flex;
    flex-direction: column;
    gap: 20px
}

.subscriptionDetailsWrapper {
    min-width: 305px;
    max-width: 450px;
    width: calc(100% - 220px);
}
