@import './variables/colors.scss';
@import './variables/fonts.scss';
@import './variables/media.scss';
@import '~react-placeholder/lib/reactPlaceholder.css';

.placeholder {
    &__tile-body {
        height: 10px !important;
        margin: 38.75px auto 0 auto !important;
        background-color: $grey-ash;
        &--slim {
            margin: 20px auto 0 auto !important;
        }
    }
    &__slim-body {
        margin-right: 10px;
    }
    &__fullwidth-wrapper {
        width: 100%;
        margin: 5px 15px 0 15px;
    }
    &__threshold-breach {
        width: 100%;
        border-radius: 10px;
        padding: 20px;
        background-color: $white;
        margin-bottom: 20px;
        &__header-element {
            background-color: $grey-ash;
            margin-bottom: 1rem;
        }
        &__section {
            width: 50%;
            min-width: 550px;
            height: 216px;
            padding: 20px;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            @media screen and (max-width: 700px) {
                min-width: 100%;
            }
            &--element {
                background-color: $grey-ash;
                margin-bottom: 1rem;
            }
            &--wide {
                width: 70%;
            }
            &--right {
                background-color: $grey-concrete;
                &--slim {
                    width: 30%;
                    min-width: 250px;
                    height: 260px;
                }
            }
        }
    }
    &__tile-fullwidth-wrapper {
        width: 100%;
        height: 120px;
        margin: 30px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1230px;

        &--element {
            -webkit-box-shadow: 0 1px 3px rgba(162, 164, 165, 0.12),
            0 1px 2px rgba(162, 164, 165, 0.24);
            box-shadow: 0 1px 3px rgba(162, 164, 165, 0.12),
            0 1px 2px rgba(162, 164, 165, 0.24);
            background-color: $white;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:first-child {
                min-width: 133px;
                width: 133px;
                margin-right: 14px;
            }
            &:last-child {
                width: 100%;
            }
        }

        .column {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &-right {
                width: 90%;
            }
            &-left {
                width: 10%;
            }
        }
        hr {
            height: 90%;
            border: 0.5px dotted;
            opacity: 0.2;
        }
    }
    &__tile-fullwidth-element {
        width: 80% !important;
        height: 10px !important;
        background-color: $grey-ash;
        margin: 10px auto !important;
    }
    &__device-logo {
        width: 67px !important;
        height: 79px !important;
        background-color: $grey-ash;
        margin: 3px auto !important;
    }
    &__device-header {
        width: 300px !important;
        height: 35px !important;
        background-color: $grey-ash;
        margin: auto 0 0 0 !important;
        &--info {
            height: 25px !important;
            width: 200px !important;
            margin: 18px auto 0 0 !important;
        }
    }
    &__event-list {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 20px;
    }
    &__event-list-element {
        height: 100px;
        width: calc((100% - (20px * 2)) / 3);
        min-width: 276px;
        padding: 10px 20px;
        background-color: $white;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media screen and (max-width: 692px) {
            width: 100% !important;
            height: 94px;
        }
    }
}

@media screen and (max-width: $tablet-max) and (min-width: $tablet-min) {
    .placeholder {
        &__tile-fullwidth-wrapper {
            width: 100%;
        }
    }
}
