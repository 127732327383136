@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';
@import '~commons/src/style/mixins.scss';

.statusBarWrapper {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: 10px;
    padding: 10px 20px;
    @include content-divider-y(30px) {
        border: 1px solid $grey-nepal;
    }
}

.statusBarTroubleshootWrapper {
    display: flex;
    align-items: center;
    column-gap: 20px;
    row-gap: 10px;
    font-size: $font-medium;
    font-weight: 400;
    flex-wrap: wrap;
}

.statusBarTroubleShootLinks {
    display: flex;
    column-gap: 30px;
    row-gap: 10px;
    flex-wrap: wrap;
}

.statusBarDeviceWrapper {
    display: flex;
    column-gap: 30px;
    row-gap: 10px;
    flex-wrap: wrap;
    font-size: $font-large;
}

.statusBarDeviceInfo {
    display: flex;
    align-items: center;
    column-gap: 10px;
    row-gap: 5px;
    flex-wrap: wrap;
}

.statusBarTotalInfo {
    display: flex;
    column-gap: 5px;
    align-items: center;
    font-size: inherit;
    font-weight: 800;
    margin: 0;
    svg {
        width: 28px;
        height: 28px;
    }
}

.pill {
    font-size: inherit;
    border-radius: 10px;
    padding: 5px;
    background-color: $grey-concrete;
    color: $black-shark;
}
