@import './variables/colors.scss';
@import './variables/fonts.scss';
@import './variables/media.scss';

.list-selector {
    &--center {
        display: flex;
        justify-content: center;
    }
}
