@import '~commons/src/style/variables/colors.scss';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';
@import '~commons/src/style/mixins.scss';

$table-break: 630px;

.paginationFooter{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap-reverse;
    @media screen and (max-width: $table-break) {
        justify-content: center;
    }
}

.paginationSummary {
    color: $grey-shuttle;
    font-weight: 400;
    font-size: $font-medium;
}

.paginationWrapper {
    button {
        background: none;
        border: none;
        color: $black-shark;
        font-weight: 400;
        font-size: $font-medium;
        border-radius: 100%;
        &:hover{
            border: 2px solid $white;
            border-radius: 100%;
            background: none;
        }
        &:focus, &:focus-within{
            outline: 2px solid $blue-azul;
            background: none;
        }
        &[aria-current="true"]{
            background-color: $white;
            &:hover{
                background: $white;
                outline: none;
            }
            &:focus, &:focus-within{
                background: $white;
            }
        }
    }
}
