@import '~commons/src/style/variables/colors.scss';
@import '~commons/src/style/variables/fonts.scss';

.icon {
    cursor: pointer;
    display: inline-block;
    position: relative;
    height: 20px;
    >i {
        cursor: pointer;
        margin-left: 2px;
        outline: none !important;
        color: $black-shark;
        font-size: 22px;
        margin-top: -3px;
        :focus {
            color: $blue-celestial;
        }
    }
}

.hint {
    position: absolute;
    left: calc(100% + 12px);
    min-height: 40px;
    width: 190px;
    box-sizing: border-box;
    padding: 8px 10px;
    background: $black-shark;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2);
    border-radius: 4px;
    transition: all .2s ease;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    z-index: 2;
    color: $black-shark;
    white-space: pre-wrap;
    background: $white;
    border: 1px solid $black-shark;
    top: -10px;

    &:before {
        content: '';
        position: absolute;
        left: -18px;
        top: 10px;
        border: 5px solid transparent;
        border-right: 12px solid $black-shark;
    }
}

.down {
    left: calc((-190px/2));
    top: 30px;
    &:before {
        content: '';
        position: absolute;
        left: calc((190px/2 + 18px/2));
        top: -18px;
        border: 6px solid transparent;
        border-bottom: 11px solid $black-shark;
    }
}
