@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';

.button {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    background: none;
    border: none;
    font: $font-small $medium;
    color: $blue-azul;
    text-decoration: none;
    transition: all 0.2s ease;
    padding: 2px 0;
    &:focus {
        outline: -webkit-focus-ring-color auto 5px;
    }
    &:hover {
        cursor: pointer;
    }
}

.noColor {
    color: $black-shark;
}

.medium {
    font: $font-medium $regular;
}

.alert {
    color: $red-persian;
}

.disabled {
    composes: button;
    color: $oslo-gray;
    &:hover {
        cursor: auto;
    }
}
