@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';

.body {
    margin: 0 20px 10px 20px;
}

.buttonWrapper {
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 20px;
    margin-top: 40px;
    flex-wrap: wrap;
    margin-bottom: 10px;
}
