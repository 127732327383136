@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';


@mixin shared-cell-attributes {
    .cell {
        display: flex;
        align-items: center;
        font: $font-medium $regular;
        padding: 0 10px;
    }

    .textCell {
        flex-basis: 150px;
        flex-grow: 2;
    }

    .rightAlignedCell {
        justify-content: flex-end;
        flex-basis: 140px;
        flex-shrink: 0;
        flex-grow: 2;
    }
    .poNumberCell {
        justify-content: flex-end;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        flex-basis: 140px;
        flex-shrink: 0;
        flex-grow: 2;
    }

    .firstCell {
        flex-basis: 140px;
        flex-grow: 1;
        flex-shrink: 0;
    }
    .seatsCell {
        justify-content: flex-end;
        flex-basis: 80px;
        flex-shrink: 0;
        flex-grow: 2;
    }
}

.cell {
    display: flex;
    align-items: center;
}

.table {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    background-color: $white;
    border-radius: 10px;
}

.alignedCellContent {
    display: flex;
    align-items: center;
}

.row {
    display: flex;
    padding: 4px 16px;
    font-size: 15px;
    font-family: $regular;
    border-top: 1px solid $grey-nepal;
    @include shared-cell-attributes();

    &:hover {
        background-color: $grey-ash;
    }
}

.header {
    display: flex;
    font: $font-medium $demi-bold;
    padding: 10px 16px;
    @include shared-cell-attributes();

    .cell {
        white-space: nowrap;
        font-family: $medium;
    }
    .firstCell {
        padding-left: 30px;
    }

    .textCell {
        flex-direction: row-reverse;
        justify-content: flex-end;
    }
}

.headerCell {
    display: flex;
    align-items: center;
    height: 24px;
}
