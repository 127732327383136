@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.selectedDevice {
    background: $grey-concrete;
    width: 50%;
    border-radius: 10px;
    height:220px;
    padding: 20px;
    @media screen and (max-width: 1150px) {
        width: 100%;
    }
}

.dayViewSelectedDevice {
    composes: selectedDevice;
    padding: 0 0 0 30px;
    @media screen and (max-width: 1150px) {
        padding: 0;
    }
}

.deviceName {
    display: block;
    font: $medium $demi-bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.timeInUse {
    font: $medium $regular;
}

.sensorGraph {
    background: $white;
    border-radius: 10px;
    padding: 5px;
    height: 130px;
    margin-top: 10px;
}

.dayViewSensorGraph {
    composes: sensorGraph;
    width: 100%;
    height: 210px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
