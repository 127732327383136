@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.ratePlanSection {
    flex: 1;
    background: $white;
    border-radius: 10px;
    padding: 20px;
    min-height: 450px;
    min-width: 330px;
    @media screen and (max-width: $mobile-break) {
        min-width: unset;
        width: 100%;
    }
}

.header {
    font: $font-medium $demi-bold;
}

.text {
    font: $font-small $regular;
    margin: auto 0 0 0;
    text-align: left;
}

.textButton {
    background: none;
    color: $blue-azul;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}
