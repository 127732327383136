@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';

.element {
    display: flex;
    min-height: 25px;
    padding: 15px 20px 15px 20px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-radius: 10px;
    background: $white;
    gap: 10px;
}

.column {
    font: $font-large $demi-bold;
    color: $black-shark;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.floor {
    composes: column;
    max-width: 50px;
    min-width: 50px;

}
.name {
    composes: column;
    max-width: 250px;
    min-width: 100px;
}

.body {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font: $font-small $regular;
    color: $oslo-gray;
    width: calc(100% - 300px);
    @media screen and (max-width: 800px) {
        width: 100%;
    }
}

.sensorsWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.sensors {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    overflow: hidden;
    gap: 10px;
    height: 26px;
}

.status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.sensor {
    display: flex;
    width: 150px;
    min-width: 150px;
    align-items: center;
    align-content: center;
    align-self: stretch;
    color: $black-shark;
    font: $font-large $medium;
}
