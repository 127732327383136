.user-management {
    font-size: 14px;

    &--padding-bottom {
        margin-bottom: 45px;
    }

    &--modal-view {
        .wrapper {
            margin-left: 0 !important;
        }

        .dropdown__search .mdc-menu-surface {
            max-height: 125px !important;
        }
    }

    .labeled-text {
        margin-top: 5px;
        &__value {
            color: $black-shark;
            margin-bottom: 10px;
        }
    }

    .wrapper {
        margin-left: 150px;
    }

    @media screen and (max-width: $mobile-max) {
        .form__field--single-width {
            flex-grow: unset;
            width: 100%;
        }

        .wrapper {
            margin-left: 0 !important;
        }
    }
}

