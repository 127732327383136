@import './variables/colors.scss';
@import './variables/fonts.scss';

.response-box {
    min-height: 50px;
    border-radius: 10px;
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    padding: 5px;

    &--error {
        background-color: $red-chablis;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.04);
        border: 1px solid $red-persian;
    }
    &--message {
        background-color: $green-ottoman;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.04);
        border: 1px solid $green-biosphere;
    }
    &--content {
        color: $black-shark;
        font: 14px $demi-bold;
        white-space: pre-wrap;
        padding: 0 20px;
    }
    &__subtext {
        font: 13px $regular;
        white-space: pre-wrap;
    }
}
