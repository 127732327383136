@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';

.body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 46px;
    width: 100%;
    gap: 20px;

    &:hover {
        background-color: $blue-powder;
        border-radius: 10px;
    }
}

.name {
    font: $font-medium $regular;
    white-space: pre-wrap;
}

.buttons {
    display: flex;
    align-items: center;
    gap: 20px;
}

.input {
    width: 100%;
    max-width: 240px;
}
