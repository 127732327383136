@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';

.wrapper {
    box-sizing: border-box;
    width: 100%;
    background-color: $white;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.carouselWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.sparkLines {
    display: flex;
    gap: 10px;
    overflow: hidden;
}
.buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: -40px;
}

.carouselContainer {
    width: calc(100% - 80px);
    align-items: center;
}

.sparkLinePlaceholder {
    height: 50px;
}

.compare {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
}

.advancedFeatures {
    padding-top: 15px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
    align-items: center;
}

.digestWrapper {
    cursor: pointer;
    display: flex;
    gap: 10px;
}

.bold {
    font: $font-medium $demi-bold;
}


