@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.subscriptionDetails {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 10px;
}

.container {
    padding: 20px 20px 0 20px;
    width: 100%;
}

.summaryOfAllSubscriptions {
    padding: 20px;
    font-family: $regular;
}

.smallText {
    font-size: $font-small;
}
.sectionHeader {
    font: $font-medium $demi-bold;
}

.seatsWrapper {
    background: $grey-concrete;
    padding: 5px 10px;
    border-radius: 10px;
    margin-top: 10px;
}

.subscriptionParams {
    display: flex;
    flex-direction: column;
    width: 300px;
    max-width: 100%;
    gap: 20px;
}

.poReferenceWrapper {
    width: 280px;
    margin-bottom: 20px;
}

.pOText {
    font: $font-small $regular;
    margin-top: -20px;
}

.iconWithText {
    display: flex;
    align-items: center;
    gap: 5px;
}

.ratePlanSections {
    display: flex;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    max-width: 800px;
}

.ratePlanSection {
    flex: 1;
    background: $white;
    border-radius: 10px;
    padding: 20px;
    min-height: 450px;
    width: 100%;
}

.buttonsContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;
    gap: 10px;
    justify-content: center;
    width: 100%;
}

.prorateInfo {
    display: flex;
    align-items: center;
    gap: 5px;
}
