@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.header {
    font: $font-large $medium;
    margin: unset;
}
.descriptionParagraph {
    font: $font-small $regular;
}
.graphCard {
    background-color: $white;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    margin: 20px 0;
}

.contentWrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.graphWrapper {
    width: calc(100% - 145px);
    @media screen and (max-width: 1104px) {
        width: 100%;
    }
}
.scrollWrapper {
    overflow: scroll;
    width: 100%;
}

.graph {
    width: 100%;
    min-width: 700px;
}

.infoWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-wrap: wrap;
    width: 145px;
}

.infoSection {
    color: $black-shark;
    font: $font-medium $regular;
    width: 145px;
}

.label {
    color: $oslo-gray;
    font: $font-small $regular;
}
