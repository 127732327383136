@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';

.sensorBlock {
    gap: 3px;
    display: flex;
    flex-direction: column;
}
.valueBlock {
    display: flex;
    gap: 4px;
    font: $font-large $demi-bold;
    color: $black-shark;
    align-items: center;
}
.unit {
    font: $font-small $regular;
}

.value {
    gap: 2px;
    display: flex;
    align-items: baseline;
}

.sensorType {
    font: $font-medium $regular;
    color: $grey-shuttle;
    display: flex;
    align-items: center;
    gap: 3px;
    > svg {
        fill: $grey-shuttle;
        height: 20px;
        width: 20px;
    }
}
