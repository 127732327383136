@import '~commons/src/style/variables/colors.scss';
@import '~commons/src/style/variables/fonts.scss';

.deviceCounts {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.deviceCountElement {
    font: $font-small $regular;
    width: 170px;
}

.deviceCountElementHeader {
    font: $font-medium $demi-bold;
}

.subscriptionInfo {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px 25px;
}

.nextInvoiceDate {
    display: flex;
    align-items: center;
    gap: 10px;
    font: $font-small $regular;
}
.invoiceDate {
    font: $font-large $medium;
}
.subscriptionInfoWrapper {
    gap: 20px;
    display: flex;
    flex-direction: column;
}

.subscriptionWrapper {
    display: flex;
}
.subscriptionWrapperRight {
    display: flex;
    align-items: flex-end;
}

.subscriptionInfoHeader {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
    align-items: flex-start;
}

.managedByPartner {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font: $font-small $regular;
}

.partnerName {
    font: $font-large $demi-bold;
}
.subscriptionButtonsWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}
.subscriptionButton {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 2px 0;
    font-size: $font-medium;
}

.whenToUseSection {
    display: flex;
    gap: 20px;
    font: $font-small $regular;
    flex-wrap: wrap;
}
.whenToUseSubSection {
    width: calc(50% - 20px);
    min-width: 200px;
    @media screen and (max-width: 600px){
        width: 100%;
    }
}

.whenToUseHeader {
 font: $font-medium $regular;
}
