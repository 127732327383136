@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';

.headerSection {
    color: $black-shark;
    margin-bottom: 10px;
}
.headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    h3 {
        margin: 0;
    }
}
.header {
    font: 26px $demi-bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    display: inline;
    max-width: 100%;
}

.tickWrapper {
    display: flex;
    min-height: 30px;
    gap: 10px;
}

.tick {
    display: flex;
    padding: 5px;
    align-items: center;
    gap: 10px;
    font: $font-small $regular;
    border-radius: 58px;
    border: 1px solid $blue-azul;
    background: $blue-powder;
    width: fit-content;
}

.addDeviceSection {
    background-color: $white;
    border-radius: 10px;
    padding: 10px;
    margin-top: 5px;
}

.buttonWrapper {
    gap: 10px;
    display: flex;
}

.deviceInfo {
    display: flex;
    gap: 10px;
    max-width: 100%;
    align-items: center;
}
