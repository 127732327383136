@import './variables/colors.scss';
@import './variables/fonts.scss';

.qr-label {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    overflow-x: auto;

    &__container {
        height: 200px;
        &--blue {
            background: $blue-aura;
            min-width: 148px;
            display: flex;
            flex-direction: column;
        }
        &--white {
            background: $white;
            min-width: 230px;
            padding: 16px 5px 18px 5px;
        }
    }
    &__airthings-logo {
        width: 97px;
        fill: $white;
        margin: 10px 0 0 0;
        padding-left: 16px;
        padding-bottom: 16px;
        margin-top: auto;
    }
    &__organization-logo {
        max-height: 30px;
        max-width: 100%;
    }
    &__header {
        padding-top: 16px;
        padding-left: 16px;
        word-wrap: break-word;
    }
    &__device-info-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
    }
    &__text {
        &--header {
            font-family: $demi-bold;
            color: $white;
            font-size: 18px;
            width: 111px;
        }
        &--sub-header {
            font-family: $regular;
            color: $white;
            font-size: 12px;
            width: 111px;
            padding-top: 7px;
        }
        &--organization {
            font-family: $medium;
            color: $black-shark;
            font-size: 19px;
            width: 100%;
            -webkit-line-clamp: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        &--body {
            font-family: $regular;
            color: $black-shark;
            font-size: 8px;
        }

    }
}
