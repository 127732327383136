@import './variables/colors.scss';
@import './variables/fonts.scss';
@import './variables/media.scss';

.inline-header-lined {
    width: 100%;
    height: 15px;
    border-bottom: 1px solid $oslo-gray;
    text-align: center;
    margin: 30px 0 20px 0;
    &__text {
        font: 20px $regular;
        background-color: $grey-concrete;
        color: $oslo-gray;
        padding: 0 20px;
        display: inline;
        &--white-background {
            background-color: $white;
        }
    }
    &__info-chip {
        border: 1px solid $oslo-gray;
        margin: 0 10px;
        padding: 1px 10px;
        border-radius: 11.5px;
        font-size: 15px;
        text-align: center;
    }
    &--extra-margin {
        margin-bottom: 40px;
    }
    &--less-margin {
        margin: 10px 0;
    }
    &--no-margin-top {
        margin-top: 0;
    }
}

.page-header {
    &__title {
        font-size: 28px;
        color: $grey-shuttle;

        &--no-margin-bottom {
            margin-bottom: 0;
        }
    }
    .dropdown-options__list {
        z-index: 501 !important;
    }
}

.page-header-underlined {
    display: flex;
    border-bottom: 1px solid $grey-ash;
    align-items: center;
    width: 100%;
    max-width: 1166px;
    margin: 15px auto 30px auto;
    padding: 0 0 10px 0;

    h1 {
        margin-left: 12px;
    }
    &--padding-top {
        padding-top: 30px;
    }
    &--space-between {
        justify-content: space-between;
    }

    .btn {
        margin-left: 15px;
    }
}

.info-header {
    width: 100%;
    background-color: $white;
    border-radius: 10px;
    display: flex;
    padding: 20px 30px;
    align-items: center;
    margin: 14px 0 30px;
    flex-wrap: wrap;
    &--block {
        display: block;
        padding: 20px;
    }
    &--large-gap {
        gap: 10px 40px;
    }

    &--hub {
        padding: 10px 30px;
    }

    &__info-button--slim {
        margin-left: 15px;
        @media screen and (min-width: 701px) {
            display: none;
        }
    }

    &--img-container {
        margin-right: 5px;
        .calibration-line {
            width: fit-content;
            margin: 5px auto 0 auto;
        }
    }
    &__img {
        width: 80px;
        display: flex;
        justify-content: center;
        img {
            width: 80px;
        }
        &--fit-width {
            width: auto;
        }
    }
    &__content {
        display: flex;
        &--padded {
            padding: 15px 10px;
        }
        .sensor__calibrating-text {
            color: $oslo-gray;
        }
        &--spesifications {
            @media screen and (max-width: 700px) {
                display: block;
                .info-header__content {
                    justify-content: space-between;
                }
            }
        }
        &__specs {
            justify-content: center;
            display: flex;
            flex-direction: column;
            &--calibrating {
                justify-content: space-between;
                padding-top: 10px;
            }
        }
        &__spesifications {
            font: 13px/18px $regular;
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 15px;
            .battery-icon {
                margin-bottom: 6px;
                height: 12px;
            }
            &--left {
                align-items: start;
                .battery-icon {
                    margin-bottom: 3px;
                    margin-right: 5px;
                }
            }
            &--greyed {
                color: $oslo-gray;
            }
        }
        &__details {
            margin: auto 15px;
            color: $black-shark;
            font: 13px/18px $regular;
            &--sensors-header {
                font: 15px/18px $demi-bold;
                margin: 5px 0;
            }
        }
        &__icons {
            display: flex;
        }
    }
    &__calibration {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .sensors-tile {
        &__sensors-grid {
            display: grid;
            grid-template-columns: repeat( auto-fit, minmax(103px, 1fr) );
            justify-items: center;
        }
        &__sensors-grid-item {
            display: table-cell;
        }
    }
}

.info-header__content__icons > * {
    margin: auto 15px;
}


.modal-icon-header {
    border-bottom: unset;
    padding: 0 35px;
    font: 18px/25px $demi-bold;
    display: flex;
    align-items: center;
    svg {
        min-width: 48px;
        min-height: 48px;
        margin-right: 12px;
    }
}

.tab-header-container {
    justify-content: center;
    display: flex;
    border-bottom: 1px solid $oslo-gray;
}

.outdoor__header {
    display: flex;
    border-bottom: 1px solid $grey-ash;
    width: 100%;
    min-height: 120px;
    margin-bottom: 30px;
    background: $white;
    padding: 30px 0 10px 70px;
    h1 {
        margin: 0;
    }
    @media screen and (max-width: $sidebar-disappear) {
        padding-top: 0;
        padding-left: 30px;
        min-height: 70px;
        h1 {

            margin-top: 0;
            margin-bottom: 7px;
        }
    }
    &__info {
        padding-top: 3px;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        &__top {
            width: 100%;
            display: flex;
            align-items: baseline;
        }

        &__bottom {
            display: flex;
            align-items: baseline;
            font: 18px/25px $regular;
            p {
                margin: 0;
                color: $black-shark;
            }
        }
    }
}

.single-device__header {
    margin-top: 11px;
    z-index: 1;
    display: flex;
    &--subtext {
        margin-top: unset;
    }

    &--full-width {
        width: 100%;
    }

    h1 {
        margin: 0;
    }
    @media screen and (max-width: $sidebar-disappear) {
        padding-top: 0;
        padding-left: 0;
        min-height: 70px;
        display: flex;
        align-items: center;
        &--subtext {
            padding-top: 20px;
        }
        h1 {
            margin-top: 0;
            margin-bottom: 7px;
        }
    }

    &__edit-button {
        border: none;
        background: none;
        cursor: pointer;
        padding: 0;
        width: 100%;
        &__content {
            display: flex;
            align-items: center;
            max-width: calc(95% - 90px);
            h1 {
                margin-bottom: 0 !important;
            }
            @media screen and (max-width: $sidebar-disappear) {
                max-width: 95%;
            }
            h1 {
                text-align: left;
                word-break: break-word;
            }
        }
        &__icon {
            color: $oslo-gray;
            margin-left: 5px;
            font-size: 20px;
        }
    }
    &__edit {
        display: flex;
        &__input {
            font-size: 29px;
            font-family: $demi-bold;
            color: $grey-shuttle;
        }
    }
}

.battery-container {
    margin-top: 4px;
    display: flex;
    align-items: center;
    font: 12px/16px $regular;
    color: $grey-shuttle;
    gap: 10px;
    .battery-icon {
        margin-left: 5px;
        display: inline-block;
        width: 24px;
        margin-bottom: -2px;
    }
    &--hub {
        fill: $black-shark;
        color: $black-shark;
        justify-content: space-around;
    }
    &--padded {
        color: $black-shark;
        margin: 4px 0 0 20px;
    }
    &--tooltip {
        font-family: $regular;
        color: $white;
    }
}

.single-device__header__device-last-sync {
    margin: 0 20px;
    font: 14px $regular;
}

.dropdown-options {
    &__list {
        &__link {
            font: 13px/18px $medium;
            color: $black-shark;
            display: block;
            white-space: nowrap;
            text-decoration: none;
            padding: 5px 12px;
            transition: all .2s ease;
        }

        &__option {
            list-style-type: none;

            &:hover {
                .dropdown-options__list__link {
                    cursor: pointer;
                    background: $black-shark;
                    color: $white;
                }
            }
        }
    }
}
