@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';


.floorDetails {
    display: flex;
    gap: 10px;
    padding: 10px;
    align-items: flex-end;
    flex-wrap: wrap;
}

.editForm {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
}
