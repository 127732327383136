@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';

.ratePlanSection {
    flex: 1;
    background: $white;
    border-radius: 10px;
    padding: 20px;
    min-height: inherit;
    min-width: 330px;
    @media screen {
        min-height: 300px;
    }
}

.header {
    font: $font-medium $demi-bold;
}

.content {
    width: 100%;
    margin: 10px 0;
}

.text {
    font: $font-small $regular;
    margin: auto 0 0 0;
    text-align: left;
}
