@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';

.input {
    width: 250px;
}

.newPropertyWrapper {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 5px;
}

.inputWrapper {
    width: 250px;
    max-width: 100%;
}
