@import './variables/colors.scss';
@import './variables/media.scss';
@import './variables/fonts.scss';

.threshold-ranges {
    display: flex;
    flex-wrap: wrap;
    &__element {
        margin: 15px;
        &__margin-row {
            margin-left: 26px;
        }
    }
}
