@import "~commons/src/style/variables/colors";
@import "~commons/src/style/variables/fonts";
@import "~commons/src/style/variables/media";

.insight-table {
    width: 100%;
    min-width: 995px;
    font: 14px/20px $regular;
    &--5-width {
        min-width: 800px;
    }
    &__row {
        border-top: 1px solid $oslo-gray;
        display: flex;
        width: 100%;
        &--header {
            border-top: unset;
        }
        &--clickable {
            cursor: pointer;
        }
    }
    &__cell {
        padding: 19px 15px 15px 15px;
        display: flex;
        align-items: center;
        width: 225px;
        box-shadow: unset !important;
        &--first-in-row {
            width: calc(100% - 225px*3);
            padding: 19px 15px 15px 0;
            min-width: 320px;
            &--text {
                padding-left: 15px;
                font: 20px $demi-bold;
            }
            &--5-width {
                min-width: 250px;
                width: calc(100% - 225px*4);
            }
        }
        .list-tile__header__title--name {
            font-size: 18px;
            font-family: $demi-bold;
        }
    }
    &__cell-header {
        min-width: 320px;
        width: calc(100% - 225px*3);
        font-size: 18px;
        font-family: $demi-bold;
        box-shadow: unset !important;
    }
}
