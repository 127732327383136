@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';

.tooltipContainer {
    position: relative;
    display: inline-flex;
}

.tooltip {
    position: absolute;
    background-color: $white;
    color: $black-shark;
    padding: 6px 8px;
    font-family: $demi-bold;
    border-radius: 5px;
    line-height: 15.6px;
    font-size: $font-small;
    font-weight: 400;
    white-space: nowrap;
    box-shadow: 0px 3px 4px 2px rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    z-index: 1000;
}

.tooltipContainer:hover .tooltip {
    opacity: 1;
    visibility: visible;
}

.top {
    bottom: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
}

.bottom {
    top: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
}

.left {
    right: calc(100% + 10px);
    top: 50%;
    transform: translateY(-50%);
}

.right {
    left: calc(100% + 10px);
    top: 50%;
    transform: translateY(-50%);
}
