@import "~commons/src/style/variables/colors";
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.body {
    background-color: $grey-porcelain;
    margin: 15px 20px;
    padding: 10px 25px;
    text-align: center;

    @media screen and (max-width: $mobile-max) {
        padding: 10px;
        margin: 20px 10px;
    }
}

.content {
    margin: 15px 10px;
    padding: 15px;
    background-color: $white;

    @media screen and (max-width: $mobile-max) {
        padding: 10px;
        margin: 10px 5px;
    }
}

.logo {
    height: 110px;
    margin-top: 10px;
}

.h3 {
    margin-bottom: 5px;
}

.subHeader {
    color: $oslo-gray;
}

.sensorBlock {
    max-width: 345px;
    margin: 10px auto 10px auto;
}

.sensorIcon {
    margin: 10px 5px 0 0;
}

.flexCenter {
    display: flex;
    align-items: center;
}

.hyphenSpacing {
    margin: 0 3px;
}

.location {
    margin-bottom: 7px;
    background-color: $grey-porcelain;
    padding: 5px 10px;
    min-height: 135px;
    border-radius: 10px;
}

.locationName {
    color: $blue-azul;
    margin-right: 10px;
    font: 15px/20px $regular;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;

    @media screen and (max-width: $mobile-max) {
        max-width: 130px;
    }
}

.segment {
    font: 13px/16px $regular;
    color: $black-shark;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}
