@import './variables/fonts.scss';
@import './variables/colors.scss';

.sensor {
    &__block {
        display: block;
        margin-left: 10px;
        margin-bottom: 10px;
        margin-top: 10px;
        height: 58px;
        width: 103px;
        text-align: left;
        color: $black-shark;
        &--pollutant {
            margin-left: 20px;
        }
        &--inline {
            display: inline-block;
            text-align: right;
        }
        &--no-unit {
            padding-top: 10px;
        }
        #iconHumidity,
        #iconCo2,
        #iconTemp,
        #iconVirusRisk,
        #iconRadon,
        #iconPressure,
        #iconControlSignal,
        #iconStraighten,
        #iconMoistGuard,
        #iconFlashOn,
        #iconVoc,
        #iconNoise,
        #iconLight,
        #iconMold,
        #iconPM,
        #iconNo2,
        #iconNoise,
        #iconVentilationRate,
        #iconOccupancy,
        .sensor-icon {
            fill: $oslo-gray;
            color: $oslo-gray;
        }
    }
    &__lined {
        width: 80%;
        height: 15px;
        border-bottom: 1px solid $grey-ash;
        margin: 10px 0 10px 12px;
    }
    &__icon {
        display: inline-block;
        margin-right: 5px;
        vertical-align: top;
        padding-top: 3px;
        margin-bottom: -3px;
        &--unit-top {
            padding-top: 12px;
        }
        svg {
            width: 24px;
            height: 24px;
        }
        &__material {
            font-size: 27px;
            color: $oslo-gray;
        }
    }
    &__data {
        display: inline-block;
        text-align: left;
    }
    &__value {
        font-family: $bold;
        font-size: 22px;
        display: flex;
        margin-bottom: -10px;
        &--pollutant {
            font-size: 28px;
        }
        &--single-line {
            line-height: 1;
        }
    }
    &__unit {
        font-family: $medium;
        font-size: 14px;
        margin-top: 3px;
        &--top {
            font-family: $medium;
            margin-bottom: -5px;
            font-size: 12px;
        }
        &--risk {
            font-size: 18px;
            margin-top: 12px;
        }
        &--font-regular {
            font-family: $regular;
        }
    }
    &__description {
        line-height: 12px;
        display: flex;
        &--pollutant {
            padding-left: 10px;
        }
        &--padded {
            padding-left: 22px;
        }
    }
    &__name {
        text-transform: uppercase;
        font-size: 14px;
    }
    &__calibrating-container {
        font-size: 11px;
    }
    &__calibrating {
        height: 4px;
        width: 6px;
        background-color: $blue-aura;
        display: inline-block;
        margin-left: 2px;
        margin-bottom: 1px;
        &--today {
            opacity: 0.4;
        }
        &--left {
            background-color: $grey-ash;
        }
    }
    &__calibrating-text {
        font: 14px $regular;
        margin-left: 5px;
    }
    &--large {
        height: 70%;
        width: 80%;
        margin-bottom: 8%;
        .sensor {
            &__grid {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(103px, 1fr));
                justify-items: center;
                &--top {
                    height: 80%;
                }
                &--bottom {
                    height: 20%;
                }
            }

            &__grid-item {
                display: flex;
                width: 100%;
                height: 100%;
                justify-content: center;
                align-items: center;
                svg {
                    fill: $black-shark;
                    height: 100%;
                    width: 50%;
                }
                &--right {
                    justify-content: flex-start;
                }
                &--baseline {
                    align-items: baseline;
                }
                &--name {
                    height: 95%;
                    max-width: 100%;
                }
            }
            &__line {
                min-width: 15px;
                min-height: 15px;
                height: 1.5vw;
                width: 1.5vw;
                border-radius: 50%;
            }
        }
        &__value {
            font-family: $demi-bold;
            align-items: flex-end !important;
        }
        &__unit {
            height: 50%;
            max-width: 30%;
            font-family: $regular;
            min-font-size: 20px;
        }
    }
}
