@import "~commons/src/style/variables/colors";
@import "~commons/src/style/variables/fonts";

.building-tile {
    height: 130px;
    color: $black-shark;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
    background-color: $white;
    width: 370px;
    margin: 14px;
    border-radius: 10px;
    display: flex;

    &--white-background {
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.2);
    }

    &:hover:not(&--no-hover) {
        cursor: pointer;
        box-shadow: 0 14px 28px rgba(162, 164, 165, 0.1), 0 10px 10px rgba(162, 164, 165, 0.1);
        -webkit-box-shadow: 0 14px 28px rgba(162, 164, 165, 0.1), 0 10px 10px rgba(162, 164, 165, 0.1);
    }
    &--focused {
        border: 3px solid $blue-aura;
        box-sizing: border-box;
        border-radius: 5px;
        background: transparentize($blue-aura, 0.8);
    }

    &__link {
        text-decoration: none;
        color: $black-shark;
    }

    &--small {
        width: 275px;
        height: 90px;
        display: block;
        padding: 10px 19px;
    }

    &__image {
        &__icon {
            font-size: 48px;
            color: $oslo-gray;
        }
    }
    &__content {
        width: 100%;
        padding: 10px 15px;

        &__sensor-status {
            width: 50%;
            &__sensor {
                display: flex;
                align-content: center;
                margin-top: 5px;
                margin-bottom: 2px;
            }
            &--greyed {
                color: $oslo-gray;
                fill: $oslo-gray;
            }
            &--margin-left {
                margin-left: 5px;
            }
        }

        &__header {
            width: 100%;
            justify-content: space-between;
            display: flex;
        }
        &__text {
            font: 13px/18px $regular;
            color: $black-shark;
            &--title {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                font: 18px/28px $demi-bold;
                font-weight: 600;
                &--small {
                    line-height: 21px;
                    margin: 10px 0;
                }
            }
            &--large {
                font-family: $demi-bold;
            }
            &--address {
                font: 13.5px/16px $regular;
                color: $oslo-gray;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                height: 32px;
                margin-bottom: 10px;
            }
        }
    }
    &__set-focus {
        &--focused,
        &:focus,
        &:hover {
            color: $blue-aura;
            outline: none;
        }
        &--focused {
            &:focus {
                color: $black-shark;
            }
        }
    }
}
