@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';


.dropdownWrapper {
    position: relative;
}
.button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font: 14px/19px $medium;
    padding: 0;
    width: 24px;
    height: 27px;
    color: $black-shark;
    background: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:focus {
      background: $white;
    }
    &:hover {
        background: $grey-porcelain;
    }
}
.dotButton {
    height: 37px;
    margin: 0 !important;
    min-width: 32px;
}

.list {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.10);
    position: absolute;
    left: 0;
    top: 25px;
    min-width: 160px;
    background: $white;
    z-index: 2;
    border-radius: 10px;
    padding: 7px 0;
    margin: 0;
}

.leftAligned {
    left: -155px;
    width: 172px;
}

.rightAligned {
    top:0;
    right: 0;
    left: 40px;

}
.listOption {
    display: flex;
    align-items: center;
    font: $font-medium $regular;
    color: $black-shark;
    box-sizing: border-box;
    white-space: nowrap;
    text-decoration: none;
    padding: 5px 10px;
    transition: all .2s ease;
    background: none;
    outline: none;
    border: none;
    width: 100%;
    text-align: left;
    height: 32px;
    &:hover {
        cursor: pointer;
        background: $grey-porcelain;
        border-radius: 5px;
    }
}
.optionIcon {
    margin-right: 10px;
    display: flex;

    svg {
        height: 24px;
        fill: $black-shark;
    }
}


.optionIsFocused {
    cursor: pointer;
    background: $grey-porcelain;
    border-radius: 5px;
}

.selectorOption {
    padding-left: 35px;
}

.optionSelected {
    padding-left: 5px;
    gap: 5px;
}

.bottomLine {
    border-bottom: 1px solid $grey-ash;
}

.optionDisabled {
    background: $grey-porcelain;
    color: $oslo-gray;

    &:hover {
        background-color: $grey-porcelain;
        color: $oslo-gray;
    }
}
