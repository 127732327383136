@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';

.button {
    background: transparent;
    border-color: transparent;
    border-radius: 10px;
    color: $black-shark;
    font: $font-medium $regular;
    white-space: nowrap;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 30px;
    padding: 0 2px;
    margin: 10px 0;
    &:hover {
        color: $oslo-gray;
    }
}
.slimButton {
    composes: button;
    margin: 0;
}
