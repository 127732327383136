@import "~commons/src/style/variables/colors";
@import "~commons/src/style/variables/fonts";

.consumer-navigation {
    .sidebar-navigation {
        &__logo {
            &--open {
                margin: 0 20px;
            }
        }
    }
}
