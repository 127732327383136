@import './variables/colors.scss';
@import './variables/fonts.scss';
@import './variables/media.scss';

.radon-report {
    &__header {
        margin: 20px 20px 0 20px;
        padding-top: 20px;
        &__text {
            font-family: $regular;
            font-size: 18px;
            color: $black-shark;
            text-align: center;
            white-space: pre-wrap;
            &--no-margin-bottom {
                margin-bottom: 5px;
            }
            &--small {
                font-size: 16px;
                font-family: $demi-bold;
            }
        }
    }
    &__disclaimer {
        font: 12px/12px $regular;
    }
    &__form {
        padding: 0 12px;
        margin: 30px;

        .small-text {
            font-size: 12px;
        }

        &__section {
            margin-bottom: 16px;
            &--border {
                border-bottom: 1px solid $grey-ash;
                padding-bottom: 18px;
            }
            &__header {
                font: 16px $demi-bold;
                padding-bottom: 16px;
            }
            &--text {
                font-size: 14px;
                white-space: pre-wrap;
            }
            .input-container__icon {
                display: none;
            }

            .dropdown {
                margin-bottom: 20px;
            }

            &__row-resp-column-collapse {
                display: flex;
                min-height: 79px;

                @media screen and (max-width: $mobile-max) {
                    flex-direction: column;
                }
                @media screen and (min-width: $mobile-max) {
                    div:first-of-type {
                        &.input-container {
                            margin-right: 10px;
                        }
                    }
                }

                .input-container {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    @media screen and (min-width: $mobile-max) {
                        flex-basis: 100px;
                    }
                }

                &--first-largest {
                    div:first-of-type {
                        &.input-container {
                            flex-grow: 2;

                            @media screen and (max-width: $mobile-max) {
                                flex-grow: 1;
                            }
                        }
                    }
                }
            }

            &__row {
                display: flex;
                min-height: 79px;
                .input-container {
                    width: 50%;
                }
                .ant-picker {
                    width: 100%;
                }
                div:first-of-type {
                    &.input-container {
                        margin-right: 10px;
                    }
                }
                &--first-largest {
                    div:first-of-type {
                        &.input-container {
                            width: 63%;
                        }
                    }
                    div:nth-child(2) {
                        &.input-container {
                            width: 37%;
                        }
                    }
                }
                &--space-between {
                    max-width: 600px;
                    justify-content: space-between;
                    div:first-of-type {
                        &.input-container {
                            margin-right: 0;
                        }
                    }
                    .input-container {
                        width: inherit;
                    }
                    @media screen and (max-width: $mobile-max) {
                        display: block;
                        margin-bottom: 20px;
                    }

                }
                &--three {
                    .input-container {
                        width: 33%;
                    }
                    div:nth-child(2) {
                        margin-right: 10px;
                    }
                }

                &--four {
                    .input-container {
                        width: 25%;
                    }
                    div:nth-child(2) {
                        margin-right: 10px;
                    }
                }
                &--buttons {
                    justify-content: center;
                    min-height: unset;
                    padding-bottom: 16px;
                    .btn {
                        min-width: 136px;
                        margin: 0 7px;
                        transition: all .2s ease;
                    }
                }
            }
        }
        &--submit-button {
            display: flex;
            flex-grow: 1;
            justify-content: flex-end;

            .btn {
                margin-top: 20px;
                min-width: 138px;
            }

            @media screen and (max-width: $mobile-max) {
                justify-content: center;
            }
        }
    }
}
