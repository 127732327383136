@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';

.sparkLine {
    border-radius: 10px;
    padding: 5px;
    width: 160px;
    min-width: 160px;
    height: fit-content;
    font: $font-small $regular;
    color: $grey-shuttle;
    cursor: pointer;
    &:focus {
        border: 1px solid $blue-aura;
        color: $black-shark;
    }
}

.selectedSensor {
    composes: sparkLine;
    border: 1px solid $blue-aura;
}

.graphWrapper {
    display: flex;
    gap: 5px;
    align-items: center;
}

.value {
    white-space: nowrap;
}
