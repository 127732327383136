@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';

.sensors {
    background-color: $grey-porcelain;
    border-radius: 10px;
    max-height: 290px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    overflow: scroll;
}

.header {
    margin-bottom: 10px;
    font: $font-medium $demi-bold;
}

.body {
    margin: 0 10px;
}

.sensor {
    margin: 9px 10px;
}
