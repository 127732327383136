@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';


@mixin shared-cell-attributes {
    .cell {
        display: flex;
        align-items: center;
        font: $font-medium $regular;
        padding: 0 10px;
    }
    .rightAlignedCell {
        justify-content: flex-end;
        flex-basis: 90px;
        max-width: 200px;
        flex-shrink: 0;
        flex-grow: 2;
        white-space: nowrap;
    }

    .firstCell {
        flex-basis: 140px;
        flex-grow: 1;
        flex-shrink: 0;
    }
    .seatsCell {
        justify-content: flex-end;
        flex-basis: 30px;
        flex-shrink: 0;
        flex-grow: 2;
        text-align: right;
    }
    .costDescriptionCell {
        justify-content: flex-end;
        flex-basis: 200px;
        flex-shrink: 1;
        flex-grow: 2;
        text-align: right;
        white-space: pre-wrap;
        margin: 5px 0;
    }
}

.table {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    background-color: $grey-concrete;
    border-radius: 10px;
}
.loadingTable {
    composes: table;
    opacity: 0.5;
}
.exclTax {
    font: $font-medium;
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.row {
    display: flex;
    padding: 0 5px;
    min-height: 45px;
    font-size: 15px;
    font-family: $regular;
    border-top: 1px solid $grey-nepal;
    @include shared-cell-attributes();
}

.header {
    display: flex;
    background: $grey-mist;
    border-radius: 10px 10px 0 0;
    font: $font-small $regular;
    padding: 5px 10px;
    @include shared-cell-attributes();

    .cell {
        white-space: nowrap;
    }
}

.largeText {
    font-size: $font-large;
}

.boldText {
    font-family: $demi-bold !important;
}

.productCost {
    font-size: $font-small;
}

.previewWrapper {
    position: relative;
}

.spinner {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.subText {
    font: $font-small $regular;
}
