@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';

.wrapper {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 30px;
    height: 100%;
}

.icon {
    font-size: 56px;
}
.text {
    white-space: pre-wrap;
    text-align: center;
    font: $font-medium $regular;
    margin: 0;
}

.title {
    font: $font-large $demi-bold;
    color: $black-shark;
    margin: 20px 0;
}
