@import '~commons/src/style/variables/media.scss';
@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';

.infoHeader {
    width: 100%;
    padding: 10px;
    background-color: $white;
    border-radius: 10px;
    font: $font-medium $regular;
    margin: 10px 0;
}

.topSection {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.deviceList {
    gap: 10px;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
}

.deviceInfo {
    display: flex;
    gap: 10px 20px;
    flex-wrap: wrap;
    align-items: center;
}

.deviceStatus {
    display: flex;
    gap: 10px 20px;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    flex-wrap: wrap;
}

.iconAndName {
    font-family: $demi-bold;
    font-size: $font-medium;
    display: flex;
    gap: 5px;
    align-items: center;
    max-width: calc(100% - 10px);
}

.name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.nameAndSerial {
    display: flex;
    gap: 5px;
    align-items: center;
    flex-wrap: wrap;
    max-width: 100%;
}

.iconAndText {
    display: flex;
    gap: 5px;
    align-items: center;
}

.serialNumber {
    font-family: $regular;
}

.offlineDetails {
    composes: iconAndText;
    color: $oslo-gray;
}

.alert {
    color: $red-auburn;
}

.noDevicesConnected {
    font-size: $font-medium;
}
