@import '../../style/variables/colors.scss';

%dot {
    left: 16px;
    top: -2px;
    position: absolute;
    content: "●";
    font-size: 14px;
}

.dotStatus {
    position: relative;
}

.dotStatusGood {
    &:after {
        @extend %dot;
        color: $green-biosphere;
    }
}

.dotStatusWait {
    &:after {
        @extend %dot;
        color: $yellow-hot-butter;
    }
}

.dotStatusBad {
    &:after {
        @extend %dot;
        color: $red-persian;
    }
}
