@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
.pendingElement {
    border-radius: 100px;
    padding: 2px 10px;
    border: 1px solid $grey-nepal;
    background-color: $white;
    white-space: nowrap;
}

.pendingCell {
    display: flex;
    justify-content: flex-end;
}

.summaryTile {
    background-color: $white;
    display: flex;
    font: $font-large $demi-bold;
    border-radius: 10px;
    align-items: center;
    width: 200px;
    padding: 13px 20px;
    gap: 5px;
}

.summaryTileText {
    font-family: $regular;
    text-transform: lowercase;
}

.tableRow {
    &:focus {
        background: $black-shark !important;
    }
}
