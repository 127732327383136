@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';

.topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 10px;
    margin-top: 30px;
}

.timeSelector {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 35px;
}

.flipButton {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.rightAligned {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}
