@import './variables/colors.scss';
@import './variables/fonts.scss';
@import './variables/media.scss';

$img-dir: "../img" !default;

.ended-measurements {
    height: 265px;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
    background-color: $white;
    width: calc(100% - 38px);
    margin: 14px;
    border-radius: 10px;
}
.tile-wrapper {
    height: 265px;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
    background-color: $white;
    width: 370px;
    margin: 14px;
    border-radius: 10px;

    &--full-height {
        height: unset;
        min-height: 265px;
    }

    &.placeholder {
        .tile-body,
        .tile-header {
            justify-content: start;
            align-items: start;
            padding: 0 20%;
        }
    }

    &.placeholder:hover {
        cursor: auto;
        -webkit-box-shadow: 0 1px 3px rgba(162, 164, 165, 0.12), 0 1px 2px rgba(162, 164, 165, 0.24);
        box-shadow: 0 1px 3px rgba(162, 164, 165, 0.12), 0 1px 2px rgba(162, 164, 165, 0.24);
    }

    &:hover:not(&--no-hover) {
        cursor: pointer;
        box-shadow: 0 14px 28px rgba(162, 164, 165, 0.1), 0 10px 10px rgba(162, 164, 165, 0.1);
        -webkit-box-shadow: 0 14px 28px rgba(162, 164, 165, 0.1), 0 10px 10px rgba(162, 164, 165, 0.1);
    }

    &__link {
        text-decoration: none;
        color: $black-shark;
    }

    &__title {
        padding: 20px 0 15px 0;
        text-align: center;
        font-family: $bold;
        font-size: 18px;
        font-weight: 900;
        line-height: 25px;
        color: $grey-shuttle;
    }
}

.tile-header {
    height: 88px;
    border-bottom: 1px solid $grey-ash;
    padding: 20px 15px 0 30px;
    display: flex;
    justify-content: space-between;
    position: relative;
    text-align: left;
    &__space-between {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-right: 15px;
    }
    &--background {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
        &--sunny {
            background: url("#{$img-dir}/forecast-icons/forecast-image-sunny.png");
            background-size: cover;
        }
        &--partly-cloudy {
            background: url("#{$img-dir}/forecast-icons/forecast-image-partly-cloudy.png");
            background-size: cover;
        }
        &--cloudy-thunder {
            background: url("#{$img-dir}/forecast-icons/forecast-image-cloud-thunder.png");
            background-size: cover;
        }
        &--clear-hazy {
            background: url("#{$img-dir}/forecast-icons/forecast-image-hazy.png");
            background-size: cover;
        }
        &--foggy {
            background: url("#{$img-dir}/forecast-icons/forecast-image-foggy.png");
            background-size: cover;
        }
        &--cloudy {
            background: url("#{$img-dir}/forecast-icons/forecast-image-cloudy.png");
            background-size: cover;
        }
        &--overcast {
            background: url("#{$img-dir}/forecast-icons/forecast-image-overcast.png");
            background-size: cover;
        }
        &--rain {
            background: url("#{$img-dir}/forecast-icons/forecast-image-rain.png");
            background-size: cover;
        }
        &--snow {
            background: url("#{$img-dir}/forecast-icons/forecast-image-snow.png");
            background-size: cover;
        }
        &--rain-thunder {
            background: url("#{$img-dir}/forecast-icons/forecast-image-cloud-thunder.png");
            background-size: cover;
        }
        &--snow-rain {
            background: url("#{$img-dir}/forecast-icons/forecast-image-rain-snow.png");
            background-size: cover;
        }
    }
    &__controls {
        display: flex;
        justify-content: space-between;
        &>div:last-child {
            position: relative;
            .dropdown-options__caption {
                margin: 10px 0 0 10px;
            }
        }
        .options-row__filter-buttons {
            background: transparent;
        }
        .menu-ellipsis {
            fill: $oslo-gray;
            width: 15px;
            height: 15px;
            margin: 0;
        }
        &--weather {
            color: $black-shark;
            .menu-ellipsis, .weather-icon {
                fill: $black-shark;
            }
            &--dark {
                color: $white;
                .menu-ellipsis, .weather-icon {
                    fill: $white;
                }
            }
        }
        &--outdoor-comparison {
            min-width: 120px;
        }
    }
    &--info {
        display: flex;
        .sensor__block {
            margin-left: 45px;
        }
    }
    &__title {
        &--name {
            margin: 0;
            font-size: 20px;
            line-height: 27px;
            font-family: $bold;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &--background {
                background: $white;
                padding: 0 6px;
            }
        }
        &--address {
            font-family: $regular;
            font-size: 14px;
            color: $grey-shuttle;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &--background {
                background: $white;
                padding: 0 6px;
                display: inline;
            }
        }
    }
    &__last-synced {
        font-size: 12px;
        font-family: $regular;
        color: $grey-shuttle;
    }
    &__info {
        margin-top: 7px;
        &--chip {
            width: fit-content;
            color: $black-shark;
            padding: 0 15px;
            margin: 0 15px 0 0;
            border: 1px solid $black-shark;
            border-radius: 11.5px;
            font-size: 12px;
            text-align: center;
            line-height: 20px;
            height: 20px;
            white-space: nowrap;
            &--slim-margin {
                margin-right: 5px;
            }
        }
        &--text {
            margin: 0 15px 0 0;
            font-size: 12px;
            color: $grey-shuttle;
            text-align: right;
        }
    }
    &__weather {
        padding-right: 10px;
        display: inline-flex;
        &--value {
            float: right;
            font-family: $demi-bold;
            font-size: 22px;
        }
        &--description {
            font-size: 14px;
            font-family: $regular;
            padding-right: 3px;
        }
    }
}

.tile-body {
    height: 175px;
    padding: 0 5%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &--table {
        justify-content: flex-start;
    }
    &--full-height {
        height: calc(100% - 88px);
    }

    &--devices {
        flex-wrap: wrap;
        padding: 10px 10px 0 0;
        justify-content: unset;
    }
    &--compare {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    &--icon-wrapper {
        font-size: 56px;
        font-family: $demi-bold;
        color: $black-shark;
        fill: $black-shark;
        text-align: center;

        &--icon {
            display: block;
            margin: 15px auto;
        }
    }
    &--archive-icon {
        width: 57px;
        height: auto;
    }
    &__hub {
        display: flex;
    }

    &__message {
        text-transform: none;
        font-family: $regular;
        color: $black-shark;
        font-size: 18px;
        height: 25px;
        line-height: 25px;
        &--uppercase {
            font-family: $medium;
            text-transform: uppercase;
        }
        &--date {
            font-size: 14px;
            font-family: $regular;
        }
    }

    &-line {
        width: 200px;
        height: 10px;
        border-radius: 9px;
        margin: 15px auto;

    }

    &--sensor {
        padding: 0;
        display: flex;
        align-items: center;
        div:first-child:not(.centered__content) {
            width: 100%;
            height: 97%;
            margin-top: 3px;
        }

        .centered {
            display: flex;
            align-items: center;
            justify-content: center;

            &__content {
                .tile-body--icon-wrapper--icon {
                    margin-top: 0;
                }
            }
        }
    }

    &__inner {
        &--centered-content {
            .tile-body--icon-wrapper--icon {
                margin-top: 0;
            }
        }
    }
}
