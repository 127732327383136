@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';

.wrapper {
    background: $white;
    border-radius: 10px;
    height: 80vh;
    width: calc(100% - 240px);
    @media screen and (max-width: $mobile-max) {
        width: 100%;
        height: 70vh;
    }
}

.newFloorPlanWrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.newFloorPlanHeader {
    font: $font-large $demi-bold;
}

.spinnerBlock {
    height: 100%;
}

.fullscreenMode {
    height: 97vh;
}
