@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';

.wrapper {
    display: flex;
}

.inner {
    border: 1px solid $grey-mist;
    background-color: $white;
    color: $black-shark;
    width: auto;
    height: 30px;
    padding: 3px 7px 2px;
    font: 16px/16px $medium;
    display: flex;
    align-items: center;

    &:hover {
        cursor: pointer;
        background: darken($white, 5%);
    }
}
.leftButton {
    composes: inner;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.rightButton {
    composes: inner;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.selected {
    z-index: 1;
    background-color: $yellow-sunlight;
    border-color: $yellow-sunlight;
    &:hover {
        background-color: $yellow-sunlight;
        cursor: auto;
    }
}
.capitalized {
    text-transform: uppercase;
}
