@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';

.dropdown {
    max-width: 280px;
    margin-bottom: 20px;
}

.timeRangeText {
    text-align: left;
    color: $black-shark;
    font: $font-small $regular;
    height: 24px;
}

.datePickers {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    width: 100%;
    margin-bottom: 20px;
}

.datePicker {
    width: 212px;
}

.modalBody {
    padding: 10px 30px;
    white-space: pre-wrap;
    font: $font-medium $regular;
}

.modalContent {
    text-align: center;
    color: $black-shark;
    margin-bottom: 5px;
    font: $font-medium $regular;
}

.linksWrapper {
    margin-top: 15px;
}

.downloadLink {
    text-decoration: none;
    color: $blue-azul;
}

.downloadIcon {
    color: $oslo-gray;
    padding-right: 10px;
}
.button {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    gap: 15px;
}
