@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';

.row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
}

.bold {
    font: $font-medium $demi-bold;
}

.font {
    font: $font-small $regular;
}
