@import "~commons/src/style/variables/colors";
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 15px;

    @media screen and (min-width: $mobile-max) {
        padding: 0 64px;
    }
}

.statusRow {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    @media screen and (max-width: 1270px) {
        justify-content: flex-end;
        display: block;
    }
}

.content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
}
.buttonWrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}
