@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';

.body {
    background: $grey-ash;
    border-radius: 10px;
    padding: 2px 20px;
    align-items: center;
    font: $font-medium $demi-bold;
    display: flex;
    gap: 10px;
}

.sensorsWrapper {
    display: flex;
    width: 100%;
}

.sensors {
    display: flex;
    flex-flow: row wrap;
    overflow: hidden;
    gap: 10px;
    height: 32px;
}

.columnCommon {
    cursor: pointer;
    display: flex;
    height: 32px;
    padding: 2px 0;
    align-items: center;
}

.sensorColumn {
    composes: columnCommon;
    width: 150px;
}

.floorColumn {
    composes: columnCommon;
    white-space: nowrap;
    width: 50px;
    min-width: 50px;
}

.nameColumn {
    composes: columnCommon;
    max-width: 250px;
    width: 100%;
    min-width: 150px;
}

.tuneColumn {
    display: flex;
    padding: 3px;
    justify-content: flex-end;
    align-items: center;
    margin-left: -30px;
    flex: 1 0 0;
}
