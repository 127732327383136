@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';

.subscriptionSection {
    margin-top: 20px;
    background: $white;
    border-radius: 10px;
}

.supportText {
    font-size: 15px;
    font-family: $medium;
    padding: 20px;
    margin-top: unset;
}

.errorWrapper {
    padding: 20px;
}

.startSubscriptionButton {
    padding: 20px 0 0 20px;
}
