@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';
@import '~commons/src/style/mixins.scss';

.content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font: $font-medium $regular;
    height: 200px;
    justify-content: center;
}

.bold {
    font: $font-medium $demi-bold;
}

.row {
    display: flex;
    gap: 10px;
}


.alertIcon {
    color: $red-persian;
}

.buttons {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 20px;
    gap: 20px;
    flex-wrap: wrap;
}

.errorWrapper {
    width: 100%;
}
