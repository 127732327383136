@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';

.header {
    font: $font-medium $demi-bold;
    height: 22px;
    padding: 10px;
    display: block;
}

.list {
    overflow: scroll;
    min-height: 200px;
    max-height: 300px;
    width: 100%;
    border-bottom: 1px solid $grey-mist;
}

.noSpacesList {
    min-height: unset;
    max-height: unset;
    height: 40px;
}

.listWrapper {
    width: 100%;
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
}

.noSpaces {
    font: $font-medium $regular;
    color: $oslo-gray;
    margin-left: 10px;
}

.listElement {
    font: $font-medium $regular;
    color: $black-shark;
    white-space: nowrap;
    padding: 5px 12px;
    height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    &:focus,
    &:hover {
        cursor: pointer;
        background: $grey-porcelain;
        border-radius: 10px;
    }
}

.button {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 15px 10px;
}
