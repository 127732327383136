@import '../fonts.scss';
$regular: 'OpenSans-Regular', sans-serif;
$bold: 'OpenSans-TrueBold', sans-serif;
$demi-bold: 'OpenSans-DemiBold', sans-serif;
$medium: 'OpenSans-Medium', sans-serif;
$ultra-thin: 'OpenSans-Light', sans-serif;

$font-small: 13px;
$font-medium: 15px;
$font-large: 18px;
$font-h1: 34px;
$font-h2: 26px;
