@import '~commons/src/style/variables/colors.scss';
@import '~commons/src/style/variables/fonts.scss';

.chipsInputContainer {
    min-width: 100%;
    margin-bottom: 10px;
}

.inputContainer {
    label:focus-within {
        outline: -webkit-focus-ring-color auto 5px;
    }
}

.chips {
    min-height: 34px;
    color: $oslo-gray;
    border: 1px solid $grey-mist;
    border-radius: 10px;
    padding: 5px;
    text-align: left;
    display: block;
    align-items: center;
    margin-bottom: 10px;

    input {
        border: none;
        appearance: none;
    }
    input:focus {
        outline: none;
    }
}

.chipsInput {
    font-size: $font-medium;
    font-family: $regular;
    height: 38px;
    min-width: 80px;
    border-radius: 10px;
}

.invalid {
    background-color: $red-chablis;
}

.disabled {
    background: $grey-ash;
}
.inputField {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}
