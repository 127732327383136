@mixin content-divider-x($gap) {
    column-gap: $gap;
    & > :not(:only-child, :last-child) {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            height: 100%;
            top: 0;
            right: calc($gap / -2);
            @content;
        }
    }
}

@mixin content-divider-y($gap) {
    row-gap: $gap;
    & > :not(:only-child, :last-child) {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            left: 0;
            bottom: calc($gap / -2);
            @content;
        }
    }
}

@mixin unbutton {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    text-align: inherit;
    font: inherit;
    border-radius: 0;
    appearance: none;
    outline: 0;
    &:focus {
        outline: none;
    }
}
