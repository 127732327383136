@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';

@keyframes change-background {
    0% {
        background: $green-ottoman;
    }
    100% {
        background: $white;
    }
}

.tableRow {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    border-bottom: 1px solid $grey-nepal;
    color: $black-shark;
}

.newCustomer {
    animation: change-background 2s linear;
}

.header {
    font: $font-medium $demi-bold;
    margin-top: 0;
}
.element {
    font: $font-medium $regular;
    height: 30px;
    display: flex;
}

.elementName {
    flex-basis: 40%;
}
.elementValue {
    flex-basis: 60%;
}
