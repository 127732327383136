@import '~commons/src/style/variables/fonts.scss';

.buttonWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
}

.text {
    font: $font-medium $regular;
}
