@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.infoHeader {
    width: 100%;
    padding: 0 10px;
    background-color: $white;
    border-radius: 10px;
    font: $font-medium $regular;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.deviceInfo {
    display: flex;
    margin: 12px 0;
    gap: 10px 30px;
    flex-wrap: wrap;
}

.dropdownContent {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 30px;
    padding-bottom: 10px;
}

.content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.aligned {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px;
}

.iconAndText {
    display: flex;
    gap: 5px;
    align-items: center;
}

.serialNumber {
    font-family: $demi-bold;
}

.notSynced {
    display: flex;
    padding: 5px;
    align-items: center;
    border-radius: 10px;
    background: $yellow-scotch-mist
}


.yellowIcon {
    color: $yellow-sunlight;
}

.redIcon {
    color:$red-persian;
}

.offline {
    display: flex;
    padding: 5px;
    align-items: center;
    border-radius: 10px;
    background: $red-salmon;
}

.chip {
    width: fit-content;
    color: $black-shark;
    padding: 0 15px;
    margin: 0;
    border: 1px solid $black-shark;
    border-radius: 11.5px;
    font-size: 12px;
    text-align: center;
    line-height: 20px;
    height: 20px;
    white-space: nowrap;
}

.ethernet {
    width: 24px;
    height: 24px;
    fill: $black-shark;
}

.ethernetAndSimText {
    color: $oslo-gray;
    margin-left: 5px;
}

.ethernetAndSim {
    composes: iconAndText;
    svg {
        width: 24px;
        height: 24px;
        fill: $black-shark;
    }
}

.alert {
    color: $red-auburn;
}
