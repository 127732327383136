@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';
@import '~commons/src/style/mixins';

.body {
    box-sizing: border-box;
    width: 100%;
    background-color: $white;
    padding: 10px 15px 15px 15px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px 20px;
}

.deviceValuesRow {
    display: flex;
    margin-left: 5px;
    width: 100%;
    justify-content: space-between;
    @media screen and (max-width: $mobile-break) {
        display: block;
    }
}

.accordionButton {
    @media screen and (max-width: $mobile-break) {
     display: flex;
        width: 100%;
        justify-content: flex-end;
    }
}

.currentValues {
    min-height: 46px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $black-shark;
    flex-flow: row wrap;
    gap: 15px 0;
}

.currentValueBlock {
    width: 160px;
    @media screen and (max-width: $sidebar-disappear) {
        width: 145px;
    }
}

.deviceTopBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    border-bottom: 1px solid $grey-ash;

    @media screen and (max-width: $tablet-min) {
        align-items: unset;
    }
}

.deviceInfo {
    display: flex;
    align-items: center;
    gap: 10px 20px;
    flex-wrap: wrap;
    max-width: calc(100% - 52px);
}

.lastSynced {
    font: $font-medium $regular;
}

.dots {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.status {
    display: flex;
    height: 46px;
    justify-content: flex-start;
    align-items: center;
}
.deviceNameAndSerial {
    display: flex;
    gap: 5px;
    align-items: center;
    flex-wrap: wrap;
    max-width: 100%;
}

.iconAndName {
    display: flex;
    gap: 5px;
    align-items: center;
    max-width: 100%;
}
.name {
    font-family: $demi-bold;
    font-size: $font-medium;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.deviceIcon {
    width: 24px;
    min-width: 24px;
}

.serialNumber {
    font: $font-medium $regular;
}
