@import '~commons/src/style/variables/colors.scss';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

@keyframes change-background {
    0% {
        background: $green-ottoman;
    }
    100% {
        background: $white;
    }
}

.table {
    @media screen and (max-width: $mobile-max) {
        display: none !important;
    }
}
.visuallyHidden {
    display: none;
}

.summaryCellTextSmall {
    font-size: $font-small !important;
}
.customerName {
    max-width: 20vw;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: $demi-bold;
    white-space: nowrap;
}
.bold {
    font-family: $demi-bold;
}

.tableRow:focus {
    border: 2px solid $blue-aura;
}

.tableRow {
    cursor: pointer;
}

.newCustomerRow {
    td {
        animation: change-background 5s linear;
    }
}

.headerCell:focus {
    color: $blue-aura !important;
}

.seatsWrapper {
    display: flex;
    justify-content: flex-end;
}
