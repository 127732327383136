@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';

.button {
    position: relative;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    outline: none;
    box-sizing: border-box;
    text-decoration: none;
    cursor: pointer;
    height: 32px;
    padding: 0 10px;
    min-width: 130px;
    color: $black-shark;
    border: 1px solid $black-shark;
    border-radius: 10px;
    background-color: $white;
    font: $font-medium $regular;
    transition: all 0.2s ease;
    white-space: nowrap;
    max-width: 100%;
    > *:not(:last-child) {
        margin-right: 8px;
    }
    &:hover {
        cursor: pointer;
        background: $grey-porcelain;
    }
    &:focus {
        outline: -webkit-focus-ring-color auto 5px;
    }
}

.title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.primary {
    background: $yellow-sunlight;
    border-color: $yellow-sunlight;
    color: $black-shark;

    &:hover {
        background: $yellow-glow;
        border-color: $yellow-glow;
    }
}

.secondary {
    color: $black-shark;
    background-color: $white;
    border-color: $black-shark;
    &:hover {
        background: $grey-porcelain;
    }
}

.alert {
    background: $red-persian;
    color: $white;
    border: 1px solid $red-persian;

    &:hover {
        background: $red-auburn;
        border: 1px solid $red-auburn;
    }
}


.confirmed {
    background: $green-biosphere;
    border-color: $green-biosphere;
    color: $black-shark;
    &:hover {
        background: $green-jade;
        border-color: $green-jade;
    }
}

.tertiary {
    background: transparent;
    border-color: transparent;
    color: $black-shark;

    &:hover {
        background: $grey-porcelain;
        border-color: $grey-porcelain;
    }
}

.iconButton {
    padding: 0 3px;
    min-width: unset;
}

.disabled {
    background: $grey-porcelain;
    border-color: $oslo-gray;
    color: $oslo-gray;
    &:hover {
        cursor: auto;
        border-color: $oslo-gray;
        background: $grey-porcelain;
    }
}
