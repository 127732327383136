@import '../../style/variables/colors';
@import '../../style/variables/media.scss';
@import '../../style/variables/fonts.scss';

.infoHeader {
    width: 100%;
    padding: 0 10px;
    background-color: $white;
    border-radius: 10px;
    font: $font-medium $regular;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.deviceInfo {
    display: flex;
    margin: 12px 0;
    gap: 10px 30px;
    flex-wrap: wrap;
}

.serialNumberAndDevice {
    display: flex;
    gap: 5px;
    align-items: center;

}

.serialNumber {
    font-family: $demi-bold;
}

.battery {
    align-items: center;
    display: flex;
    gap: 2px;
}


.offlineRssi {
    align-items: center;
    display: flex;
    gap: 2px;
    color: $oslo-gray;
}

.content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.dropdownContent {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 30px;
    padding-bottom: 10px;
}

.batteryAndRssi {
    display: flex;
    align-items: center;
    gap: 10px;
}

.notSynced {
    display: flex;
    padding: 5px;
    align-items: center;
    border-radius: 10px;
    background: $yellow-scotch-mist
}

.aligned {
    display: flex;
    align-items: center;
    gap: 5px;
}

.yellowIcon {
    color: $yellow-sunlight;
}

.redIcon{
    color:$red-persian;
}

.offline {
    display: flex;
    padding: 5px;
    align-items: center;
    border-radius: 10px;
    background: $red-salmon;
}

.chip {
    width: fit-content;
    color: $black-shark;
    padding: 0 15px;
    margin: 0;
    border: 1px solid $black-shark;
    border-radius: 11.5px;
    font-size: 12px;
    text-align: center;
    line-height: 20px;
    height: 20px;
    white-space: nowrap;
}

