@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';

.spacesWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.groupValue {
    font: $font-medium $demi-bold;
    height: 40px;
    margin-left: 20px;
    display: flex;
    align-items: center;
}

.sensorsHeader {
    position: sticky;
    top: 10px;
    z-index: 1;
}

.groupBy {
    display: flex;
    align-items: center;
    > div > div {
        margin-bottom: 0;
    }
}

.buttonWrapper {
    margin: 10px 0;
}

.groupSection {
    gap: 10px;
    display: flex;
    flex-direction: column;
}
