@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';
@import '../../style/variables/media.scss';

.dropdownHeader {
    display: flex;
    align-items: center;
}

.endIcon {
    margin-left: 5px;
}
