@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';

.body {
    width: 260px;
    height: 350px;
    border-radius: 10px;
    border: 1px solid $oslo-gray;
    background: $white;
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.20);
    position: absolute;
    z-index: 500;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid $grey-mist;
}

.headerTitle {
    font: $font-medium $demi-bold;
    color: $black-shark;
}

.content {
    padding: 10px;
}

.devices {
    height: 210px;
    display: flex;
    flex-direction: column;
}

.deviceNavigation {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 10px 0;
    height: 10px;
}

.navIcon {
    width: 8px;
    height: 8px;
    border-radius: 50%;
}
.navIconActive {
    composes: navIcon;
    background: $blue-azul;
}
.navIconInactive {
    composes: navIcon;
    background: $grey-mist;
}

.toSpaceButton {
    margin-top: 15px;
}


