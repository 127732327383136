@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';
@import './Common.module';

.list {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    background-color: $white;
    border-radius: 10px;
}

.item {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas:
        "header"
        "offline-info";
    padding: 15px 20px;

    & + & {
        border-top: 1px solid $grey-nepal;
    }
}

.itemHeader {
    grid-area: header;
    font-family: $medium;
    padding-bottom: 16px;
    font-size: 18px;
}

.itemOfflineInfo {
    grid-area: offline-info;
    font-family: $regular;
    display: flex;
    flex-direction: row;
    gap: 30px;
    line-height: 30px;

    & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.mobileHeader {
    background-color: $grey-nepal;
    font-family: $demi-bold;
    border-radius: 10px 10px 0 0;
    padding: 10px;

}
