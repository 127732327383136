@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';

.body {
    position: relative;
}

.input {
    display: flex;
    font: $font-medium $regular;
    background-color: $white;
    height: 46px;
    width: 100%;
    margin: 0 0 20px 0;
    padding: 0 10px;
    border-radius: 10px;
    border: 1px solid $grey-mist;
}

.minimumWidth {
    width: 70px;
}

.maximumWidth {
    width: 280px;
}

.small {
    height: 32px;
}

.validation {
    margin-bottom: 10px;
}

.noBottomMargin {
    margin-bottom: 0;
}

.disabled {
    background: $grey-porcelain;
}

.invalid {
    background: $red-chablis;
}

.error {
    color: $red-persian;
    font: $font-small $regular;
    margin-bottom: 18px;
}
