@import "~commons/src/style/variables/colors";
@import "~commons/src/style/variables/fonts";

.tree-view {
    max-height: 278px;
    overflow-y: scroll;
    max-width: 400px;
    box-sizing: border-box;
    border: 1px solid $grey-mist;
    border-radius: 10px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.04);
    min-height: 290px;

    ul {
        margin-bottom: 0;
    }

    &__option {
        display: flex;
        align-items: center;
        height: 36px;
        padding-left: 6px;
        border-bottom: 1px solid $grey-nepal;

        .material-symbols-outlined {
            &:hover {
                cursor: pointer;
            }
        }
        &--level-0 {
            background-color: $blue-marble;
            padding-left: 0;
        }
        &--level-1 {
            background-color: $grey-porcelain;
            &--no-arrow {
                padding-left: 20px;
            }
        }
        &--level-2 {
            padding-left: 70px;
            &--no-arrow {
                padding-left: 35px;
            }
        }
        .input-container {
            min-width: 280px;
            flex-grow: 1;
            margin-left: 10px; // Added for old checkbox
        }
    }

}
