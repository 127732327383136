@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';


.button {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    outline: none;
    background: none;
    box-sizing: border-box;
    text-decoration: none;
    cursor: pointer;
    border-radius: 50%;
    font: 24px $regular;
    border: none;
    svg {
        max-height: 24px;
        max-width: 24px;
    }
}
.primary {
    background: $yellow-sunlight;
    color: $black-shark;
    fill: $black-shark;
    &:hover {
        background: $yellow-glow;
    }
}
.secondary {
    background: $grey-ash;
    color: $black-shark;
    fill: $black-shark;
    &:hover {
        border: 2px solid $blue-aura;
    }
}

.tertiary {
    background: $white;
    color: $black-shark;
    fill: $black-shark;
    &:hover {
        border: 2px solid $blue-aura;
    }
}

.quaternary {
    background: $white;
    color: $black-shark;
    fill: $black-shark;
    border: 1px solid $grey-nepal;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.20);
    &:hover {
        border: 2px solid $blue-aura;
    }
}

.confirmed {
    background: $green-biosphere;
    color: $white;
    fill: $white;
    &:hover {
        background: $green-jade;
    }
}

.alert {
    background: $red-persian;
    color: $white;
    fill: $white;
    &:hover {
        background: $red-auburn;
    }
}

.disabled {
    cursor: unset;
    background: $grey-porcelain;
    color: $oslo-gray;
    &:hover, &:focus {
        background: $grey-porcelain;
        border: none;

    }
}

.medium {
    min-width: 48px;
    height: 48px;
    width: 48px;
    font-size: 24px;
}

.small {
    min-width: 30px;
    height: 30px;
    width: 30px;
    font-size: $font-medium;
}
