@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';

.datePickerWrapper {
    display: flex;
    gap: 10px;
    > div {
        div:first-child {
            height: 33px;
            border: none;
            margin-bottom: unset;
        }
    }
}
