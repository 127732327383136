@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';
@import '../../style/variables/media.scss';

.dropdown {
    z-index: 501;
    background-color: white;
    padding: 5px;
    border-radius: 10px;
    width: 140px !important;
    margin: 0 !important;
    position: absolute;
    top: 50px;
    display: block !important;
    transition: max-height 500ms ease-in;
}
