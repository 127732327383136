@import "~commons/src/style/variables/colors";
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';


.overviewHeader {
    margin-bottom: 10px;
}
.graphWrapper {
    width: 100%;
    overflow: scroll;
}

.graphWrapperContent {
    width: 100%;
    min-width: 550px;
}

.deviceBreakDown {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 20px;
}

.breakDownNaming {
    width: 200px;
}

.breakDownGraph {
    width: calc(100% - 200px);
    overflow: scroll;
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
}
.onlyCsvHeader {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
    background-color: $white;
    margin: 14px;
    padding: 20px;
    gap: 10px;
    width: 100%;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
}
