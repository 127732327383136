@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';

.fileName {
    font: $font-medium $regular;
    color: $black-shark;
}
.invalidFileMessage {
    font: $font-small $regular;
    color: $red-persian;

}

.imageSection {
    width: 100%;
    min-height: 200px;
    border-radius: 10px;
}
.uploadImageSection {
    composes: imageSection;
    background: $white;
    height: 200px;
    border: 2px dashed $blue-cerulean;
    cursor: pointer;

}
.selectImage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 10px;
    font: $font-small $regular;
}
.selectedImage {
    width: 220px;
    max-height: 220px;
}

.uploadImageInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.placeholderImage {
    font-size: 48px;
    color: $oslo-gray;
}

.floorDetails {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    align-items: flex-end;
}

.selectedImageWrapper {
    composes: imageSection;
    background: $blue-powder;
    border: 1px solid $blue-cerulean;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
}
