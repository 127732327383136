@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';

.body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.description {
    font: $regular $font-small;
    color: $black-shark;
    line-height: 24px;
    margin-bottom: 20px;
}

.marginTop{
    margin-top: 10px;
}

.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
}

.headerSensor {
    display: flex;
    align-items: flex-end;
    font: $font-medium $demi-bold;
    gap: 8px;
}

.fullWidth {
    width: 100%;
}

.button {
    margin-top: 15px;
}

.slider {
    position: relative;
    width: 100%;
    margin: 10px 0 20px 0;
}
.inputs {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    align-items: center;
    justify-content: flex-start;
}
.inputWrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
}

.inputTitle {
    display: flex;
    align-items: center;
    gap: 10px 5px;
    margin-bottom: 5px;
    font: $font-small $regular;
}

.dotColor {
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.dotColorYellow {
    composes: dotColor;
    background-color: $yellow-glow;
}

.dotColorRed {
    composes: dotColor;
    background: $red-persian;
}

.errorWrapper {
    margin-top: 5px;
}

.threshold {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.poor {
    color: $red-persian;
}

.fair {
    color: $yellow-glow;
}

.good {
    color: $green-forest;
}

.grid{
    display: grid;
    grid-template-columns: 70% 30%;
}

.thresholdOptions {
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.thresholdType {
    font: $font-medium $regular;
    color: $oslo-gray;
    margin-left: 5px;
    line-height: 15.6px;
}

.customHeader{
    margin-bottom: 6px;
}

.resetButton{
    padding-top: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
}
