@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.eventColorBlock {
    width: 24px;
    height: 24px;
    border-radius: 4px;
}

.warmerOutsideOpeningHours {
    composes: eventColorBlock;
    background-color: $red-auburn;
}
.heatingOnClosedDay {
    composes: eventColorBlock;
    background-color: $yellow-hot-butter;

}
.colderOutsideOpeningHours {
    composes: eventColorBlock;
    background-color: $blue-bondi;
}
.coolingOnClosedDay {
    composes: eventColorBlock;
    background-color: $green-jade;
}
.colderThan5C {
    composes: eventColorBlock;
    background-color: $blue-azul;
}

.noData  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    height: 300px;
    background-color: $white;
    border-radius: 10px;
    font: $font-large $demi-bold;
}

.enableOpeningHours {
    font: $font-medium $regular;
}

.graphCard {
    background-color: $white;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    margin: 20px 0;
}

.cardContent {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.graphWrapper {
    width: calc(100% - 250px);
    min-width: 500px;
    overflow: scroll;
    @media screen and (max-width: 1000px) {
        width: 100%;
        min-width: unset;
    }
}
.graphHeader {
    display: flex;
    flex-wrap: wrap;
    font: $font-medium $regular;
    align-items: center;
    gap: 5px;
    padding-bottom: 20px;
}
.boldHeader {
    font: $font-large $demi-bold;
    display: flex;
    gap: 4px;
}
.graph {
    width: 100%;
    min-width: 500px;
}

.graphBar {
    opacity: 1;
    cursor: pointer;
}

.graphFooter {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
    font: $font-medium $regular;
}

.link {
    font: $font-medium $regular;
    display: flex;
    align-items: center;
}

.colderOutsideOpeningHoursBar {
    composes: graphBar;
    rect {
        fill: #469DC8;
    }
}
.coolingOnClosedDayBar {
    composes: graphBar;
    rect {
        fill: #699C72;
    }
}
.colderThan5CBar {
    composes: graphBar;
    rect {
        fill: $blue-venice;
    }
}
.heatingOnClosedDayBar {
    composes: graphBar;
    rect {
        fill: #D58020;
    }
}
.warmerOutsideOpeningHoursBar {
    composes: graphBar;
    rect {
        fill: $red-auburn;
    }
}
.legend {
    rect {
        rx: 10;
    }
}
