@import "~commons/src/style/variables/colors";
@import '~commons/src/style/variables/fonts.scss';

.body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 0 60px;
    height: 55vh;
}

.content {
    max-width: 450px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.textContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: $black-shark;
}

.title {
    font: $font-h2 $medium;
}

.subTitle {
    font: $font-large $regular;
}
