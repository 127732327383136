@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';

.marker {
    display: flex;
    min-width: 40px;
    padding: 2px 7px;
    align-items: center;
    gap: 2px;
    min-height: 20px;
    justify-content: center;
    border-radius: 24px;
    font: $font-small $regular;
    background: $white;
    box-shadow: 0 3px 4px 2px rgba(0, 0, 0, 0.20);
}

.offlineMarker {
    font-size: 18px;
}
