// color names generated from http://chir.ag/projects/name-that-color/ - use naming from design when adding new colors.
// Include color type in naming <color-type>-<color-name>

$blue-powder: #E1F1FD;
$blue-marble: #C0E2FC;
$sky-blue: #92CFFF;
$blue-bondi: #61BAFF;
$blue-aura: #3CA9F6;
$blue-celestial: #26A0F7;
$blue-cerulean: #0884DD;
$blue-azul: #056AB8;
$blue-venice: #04508B;


$green-ottoman: #F3FCF7;
$green-honeydew: #DCF4E6;
$green-padua: #C5EDD6;
$green-spring: #8CDEB1;
$green-biosphere: #4BC682;
$green-jade: #32B36C;
$green-forest: #257E4D;
$green-moss: #1C633C;
$green-racing: #114529;

$yellow-scotch-mist: #FFFADC;
$yellow-honey: #FFEDAD;
$yellow-naples: #FFDE70;
$yellow-glow: #FFD138;
$yellow-sunlight: #FFC000;
$yellow-hot-butter: #DF9501;
$yellow-caramel: #C68006;
$yellow-copper: #976007;
$yellow-sepia: #774B09;

$red-chablis: #FFF6F7;
$red-salmon: #FFD8D8;
$red-melon: #FBB1B1;
$red-coral: #ED7D7D;
$red-persian: #D32E2F;
$red-auburn: #A82424;
$red-burgundy: #7F1A1A;
$red-cherry: #631313;
$red-barn: #470B0B;

$white: #ffffff;
$grey-concrete: #F2F2F2;
$grey-porcelain: #F1F3F4;
$grey-ash: #DBE4EB;
$grey-mist: #B7CAD7;
$grey-nepal: #99B1C2;
$oslo-gray: #738B9C;
$grey-shuttle: #576975;
$grey-river-bed: #3F4E5A;
$black-shark: #21282D;

:export {
    yellowGlow: $yellow-glow;
    yellowSunlight: $yellow-sunlight;
    yellowHotButter: $yellow-hot-butter;
    yellowCaramel: $yellow-caramel;
    yellowSepia: $yellow-sepia;
    redAuburn: $red-auburn;
    redPersian: $red-persian;
    redBurgundy: $red-burgundy;
    blueBondi: $blue-bondi;
    blueAura: $blue-aura;
    blueCelestial: $blue-celestial;
    blueCerulean: $blue-cerulean;
    blueAzul: $blue-azul;
    blueVenice: $blue-venice;
    greenSpring: $green-spring;
    greenBiosphere: $green-biosphere;
    greenJade: $green-jade;
    greenForest: $green-forest;
    greenRacing: $green-racing;
    white: $white;
    greyConcrete: $grey-concrete;
    greyPorcelain: $grey-porcelain;
    greyAsh: $grey-ash;
    greyMist: $grey-mist;
    greyNepal: $grey-nepal;
    osloGray: $oslo-gray;
    greyShuttle: $grey-shuttle;
    greyRiverBed: $grey-river-bed;
    blackShark: $black-shark;

}
