@import './variables/colors.scss';
@import './variables/fonts.scss';
@import './variables/media.scss';

.settings {
    &__header {
        margin-top: 30px;
        margin-bottom: 10px;

        &--no-margin-top {
            margin-top: 10px;
        }
    }

    &__row {
        padding-bottom: 18px;
        margin-bottom: 16px;

        &--spread {
            max-width: 600px;
            display: flex;
            justify-content: space-between;
            width: unset;
        }

        &__profile-container {
            display: flex;

            .security-settings {
                margin-top: auto;
                margin-bottom: auto;
            }

            @media screen and (max-width: $mobile-max) {
                display: block;

                .security-settings {
                    margin-top: unset;
                    margin-bottom: 20px;
                }
            }
        }
        &--invite-settings {
            padding-bottom: 0;
            margin-bottom: 10px;
            display: flex;
            flex-wrap: wrap;
        }

        &__header {
            font: 14px/19px $demi-bold;
            color: $black-shark;

            &--invite-settings {
                display: flex;
                font-family: $regular;
                align-items: center;
                width: 100px;
            }
        }

        &__content {
            font: 14px/19px $regular;
            color: $black-shark;
        }

        &__inner {
            display: flex;
            justify-content: space-between;
            font: 14px/19px $regular;
            color: $black-shark;
            padding-bottom: 5px;
            align-items: center;

            &--add {
                padding-bottom: 0;
                align-items: center;
                height: 38px;
            }

            &--center {
                display: table;
            }

            &--flex-center {
                justify-content: center;
            }

            &--flex-end {
                justify-content: flex-end;
            }

            &--large {
                font: 18px/24px $regular;
                height: 45px;
            }

            &--address-preview {
                min-height: 66px;
                padding-bottom: 20px;
            }

            &__center {
                width: 100%;
                display: table-cell;
                vertical-align: middle;

                &--padding-right {
                    padding-right: 10px;
                }

                &--padding-left {
                    padding-left: 10px;
                }

                .slider {
                    width: 100%;
                }
            }

            &__icon-block {
                text-align: center;
                margin: 0 20px;
            }

            .delete-button {
                height: 21px;
                width: 33px;
                background-color: transparent;
                border-color: transparent;
                fill: $red-auburn;
                cursor: pointer;
                margin-left: 15px;
                padding: 0 7px;
            }

            .battery-icon {
                margin: 0;
                height: 12px;
            }

            .sensor__rssi {
                margin: 0;
            }
        }

        &__arrow {
            color: $black-shark;
            display: inline-block;

            &--rotate {
                transform: rotate(90deg);
            }
        }

        &--left-margin {
            margin-left: 15px;
        }

        &--button {
            cursor: pointer;
            display: flex;
            align-items: center;
        }

        &--open {
            border-bottom: none;
            padding: 0;
        }

        &__inner-expand {
            display: table;
            margin-top: 10px;
            font: 14px/15px $regular;

            &__icon {
                margin-right: 10px;
                height: 18px;
                width: 18px;
                color: $black-shark;
                display: inline-block;
            }

            &--header {
                display: table-cell;
                vertical-align: middle;
            }
        }

        .form__attr--checkbox {
            margin-bottom: 0;
            text-align: left;
        }

        &--header {
            padding-bottom: 0;
            margin-bottom: 10px;
            margin-top: 35px;
            font: 14px/20px $regular;
            text-transform: uppercase;
            color: $oslo-gray;
        }
    }

    &__buttons {
        display: flex;
        justify-content: space-evenly;
    }

    &__form {
        max-width: 600px;
    }
}

.security-settings {
    width: 100%;
}

.menu-modal {
    position: absolute;
    background-color: rgba(41, 34, 34, 0.52);
    margin-top: 50px;
    height: 100%;
    width: 100%;
    transition: 500ms;
    z-index: 10;

    &--component-tab {
        background-color: unset;
    }
}

.tab-menu-mobile {
    z-index: 15;

    &__button {
        @media screen and (min-width: $break-submenu) {
            display: none;
        }

        background-color: white;
        display: flex;
        justify-content: center;
        height: 50px;
        flex-wrap: wrap;
        border-bottom: 3px solid $yellow-sunlight;
        align-items: center;
    }

    &__dropdown {
        z-index: 501;

        @media screen and (min-width: $break-submenu) {
            display: none;
        }

        background-color: white;
        padding: 5px;
        border-radius: 10px;
        flex-wrap: wrap;
        position: absolute;
        width: 80%;
        margin: 0 10%;
        display: none;
        transition: max-height 500ms ease-in;

        &--component-tab {
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
        }

        .sidebar-navigation__items-container__item {
            color: $black-shark;
            justify-content: center;
            height: 50px;
            font-family: $medium;
            border-radius: 10px;
            background-color: white;

            &--active {
                font-family: $demi-bold;
                background-color: $grey-porcelain;
            }

            &:hover {
                font-family: $demi-bold;
                background-color: $grey-porcelain;
            }
            &--inverted {
                &:hover {
                    font-family: $demi-bold;
                    background-color: $white;
                }
            }
        }
    }

    .position-root {
        position: relative;
        width: 100%;

        .fixed-menu {
            &--active {
                display: block;
            }
        }
    }

    >i {
        align-self: center;
    }
}

.settings-container {
    @media screen and (max-width: $break-submenu) {
        flex-direction: column;
    }
}

.submit-container {
    display: flex;
    padding: 20px 0;
    justify-content: flex-start;
}

.settings-details-container {
    flex-grow: 1;
    padding: 25px 30px;
    max-width: 800px;
    background-color: $white;
    border-radius: 10px;
    min-width: 0;
    width: 100%;
    white-space: pre-wrap;

    h2 {
        margin-bottom: 18px;
    }

    h3 {
        margin-top: 0;
    }

    &__content {
        margin-top: 25px;
    }

    &--margin {
        margin-bottom: 30px;
    }

    &--center {
        display: flex;
        justify-content: center;
    }


    @media screen and (max-width: $sidebar-disappear) {
        .submit-container {
            button {
                flex-grow: 1;
            }
        }
    }

}
