@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';

.iconPadding {
    margin-right: 5px;
}

.container {
    margin-top: 10px;
}

.disclaimerMessage {
    display: flex;
    margin: 20px 0 20px 0;
    font: $font-medium $regular;
}

.selectDevicesInfo {
    font: $font-medium $regular;
    margin-bottom: 8px;
}

.textSpacing {
    margin-bottom: 5px;
}

.buttons {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    gap: 15px;
}

.errorWrapper {
    width: 100%;
    margin-top: 15px;
}
