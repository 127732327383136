@import "~commons/src/style/variables/colors";
@import "~commons/src/style/variables/fonts";
@import "~commons/src/style/variables/media";

.insight-tile {
    min-height: 245px;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
    background-color: $white;
    width: 580px;
    margin: 14px;
    border-radius: 10px;
    padding: 10px 35px 35px 35px;
    color: $black-shark;
    &--full-width {
        width: 100%;
        overflow: hidden;
    }
    .inline-header-lined {
        width: calc(100% + 70px);
        margin-left: -35px;
        &--no-margin-top {
            margin-top: 0;
            height: 1px;
        }
    }

    &--slim {
        max-width: 580px;
        min-width: 410px;
        width: calc(50% - 28px);

        @media screen and (max-width: 1140px) {
            width: 100%;
            min-width: unset;
        }
    }
    &__header {
        display: flex;
        flex-wrap: wrap;
        padding: 0 40px;
        margin: 0 -35px;
        justify-content: space-between;
        border-bottom: 1px solid $oslo-gray;
        &--no-border {
            border-bottom: unset;
        }
        &--with-sub-header {
            padding-bottom: 25px;
            h3 {
                margin: unset;
            }
        }
        &--padded {
            padding-top: 21px;
            padding-bottom: 21px;
        }
        &__name {
            width: 280px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 48px;
            &--full-text {
                white-space: unset;
                line-height: 25px;
                min-height: 48px;
            }
        }
        &__sensor {
            width: 200px;
        }
        &__details {
            display: flex;
            align-items: center;
            .labeled-text__value {
                height: 26px;
                margin: unset;
            }
            &__text {
                white-space: pre-wrap;
                font: 13px/16px $regular;
                padding: 0 30px 0 18px;
            }
            .sensor-value {
                font: 36px/49px $demi-bold;
            }
        }
        .edit-device-info {
            width: 285px;
            padding-right: 25px;
            &__icon {
                padding-left: 0;
            }
        }
    }
    &--single-line {
        min-height: unset;
        padding-bottom: 10px;
        .insight-tile__header__name {
            width: 264px;
        }
    }
    &__measurement-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 20px;
        &__content {
            margin: 25px 0;
        }
    }
    &__measurement-result {
        max-width: 450px;
        min-width: 330px;
        margin-right: 10px;
        display: inline-block;
        width: calc(50% - 20px);

        @media screen and (max-width: 1040px) {
            width: 100%;
            min-width: unset;
        }
    }
    &__risk-factors {
        display: flex;
        flex-wrap: wrap;
        max-width: 405px;
        &__factor {
           width: 200px;
            padding-top: 8px;
        }
    }
    &__content {
        display: flex;
        align-items: center;
        &--wrap {
            flex-wrap: wrap;
        }
        &--padded {
            padding-bottom: 25px;
        }
        &--graph {
            display: block;
            overflow: auto;

            div:first-child:not(.centered__content) {
                width: 100%;
                min-width: 685px;
                height: 248px;
                .data-highcharts-chart {
                    overflow: auto;
                }
            }
            &--tall { // need taller graph area when the hovered value is displayed above the graph
                div:first-child:not(.centered__content) {
                    height: 288px;
                }
            }
        }
        &--center {
            flex-direction: column;
            align-items: center;
        }
        &--table {
            overflow: auto;

        }
        &--sensor {
            margin-right: 12px;
            .sensor__data {
                margin: 0 19px;
            }
            .sensor__value {
                font: 36px/49px $demi-bold;
            }
            .sensor__unit--top {
                font-size: 13px;
            }
            &--small-padded {
                margin-left: 70px;
                margin-top: 15px;
            }
            &--padding-bottom {
                margin-bottom: 10px;
            }
        }
        &__building-summary {
            width: calc(100% - 350px);
            margin-right: 20px;
            min-width: 300px;
            @media screen and (max-width: 1030px) {
                width: 100%;
            }
        }
        &__button-row-element {
            margin-left: 10px;
            margin-bottom: 10px;
            svg {
                height: 48px;
                width: 48px;
                min-height: 48px;
                min-width: 48px;
            }
        }
        &__text-block {
            width: 100%;
            display: flex;
            align-items: center;
        }
        &__thresholds {
            min-width: 300px;
            &__row {
                border-bottom: 1px solid $grey-ash;
                display: flex;
                width: 275px;
                justify-content: space-between;
                padding-bottom: 10px;
                margin-bottom: 11px;
                &--no-border {
                    border-bottom: unset;
                }
            }
            &__margin-row {
                margin-left: 26px;
            }
        }
        &__text {
            font: 14px/18px $regular;
            color: $black-shark;
            white-space: pre-wrap;
            &--large {
                font-family: $demi-bold;
            }
            &--left {
                display: flex;
                justify-content: flex-end;
            }
        }
        &--spaced {
            margin-top: 30px;
            width: 100%;
            max-width: 516px;
            justify-content: space-between;
            @media screen and (max-width: $sidebar-disappear) {
                .insight-tile__content__text {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

            }
        }
    }
}
.insight {
    &__footer {
        page-break-inside: avoid;
        break-inside: avoid;
        position: relative;
        width: 100%;
        color: $blue-venice;
        font-size: 14px;
        margin: 14px;
        white-space: pre-wrap;
       &__details {
           display: flex;
           flex-wrap: wrap;
           justify-content: space-between;
       }
    }
    &__comment-footer {
        display: flex;
        width: 100%;
        margin: 14px;
        font-size: 14px;
        flex-wrap: wrap;
        justify-content: space-between;
        &__details {
            width: 350px;
            &__element {
                padding-bottom: 16px;
                color: $blue-venice;
            }
        }
        &__comment {
            width: 50%;
            max-width: 600px;
            min-width: 350px;
            @media screen and (max-width: $break-submenu){
                width: 100%;
            }
        }
    }
    &__sensor-peak {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &__element {
            padding-right: 20px;
            display: flex;
            align-items: center;
            &__description {
                max-width: 150px;
            }
        }
    }
    &__slim-labeled-text {
        .labeled-text {
            margin-top: 20px;
            &__value {
                height: 25px;
            }
        }
    }
}

.radon-insight-view {
    position: relative;
    .edit-device-info {
        margin: 15px 0;
    }
    iframe {
        width: 100%;
        height: 1000px;
    }
}
.device-insight {
    &__error-response {
        display: flex;
        align-items: center;
        height: 84px;
        width: 345px;

        .icon-color--red {
            margin-right: 10px;
        }
        &--slim {
            height: fit-content;
            width: 100%;
        }
    }
}

.outdoor-insight-view {
    position: relative;
    .edit-device-info {
        margin: 15px 0;
     }
    .margin-bottom {
        margin-bottom: 5px;
    }
    &__graph-date-info {
        display: flex;
        align-items: center;
        font-size: 13px;

        &__paragraph {
            padding-right: 10px;
            text-transform: capitalize;
            &--no-uppercase {
                text-transform: none;
            }
        }
    }
}

.outdoor-insight-tile {
    padding: 10px 35px 10px 35px;

    .weather-icon {
        width: 24px;
        height: 24px;
    }
    &__content {
        display: flex;
        align-items: center;
        &--wrap {
            flex-wrap: wrap;
        }
        &--graph {
            display: block;
        }

        .inline-header-lined {
            width: calc(100% + 70px);
            margin-left: -35px;
            margin: 0 0 30px;
            &--no-margin-top {
                margin-top: 0;
                height: 1px;
            }
        }
    }

    &__header {
        display: flex;
        flex-wrap: wrap;
        padding: 0 40px;
        margin: 0 -35px;
        justify-content: space-between;
        border-bottom: 1px solid $oslo-gray;
        &--no-border {
            border-bottom: unset;
        }
        &__name {
            white-space: nowrap;
            line-height: 48px;
        }

    }

}

.insight-main-tile {
    max-width: 569px;
    min-width: 410px;
    width: calc(50% - 28px * 2);
    margin: 14px;

    @media screen and (max-width: 1140px) {
        width: 100%;
        min-width: unset;
    }
    &__header {
        padding-bottom: 10px;
    }
    &__content {
        border-radius: 10px;
        background-color: $white;
        padding: 30px;
        &__elements {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
        }
    }
    &__image-wrapper {
        height: 160px;
        width: 100%;
        >img {
            max-width: 100%;
            max-height: 100%;
        }
        svg {
            max-width: 100%;
            max-height: 100%;
        }
        &--flex-center {
            display: flex;
            margin: auto;
        }
    }
}

.insight-sensor {
    &__value {
        display: flex;
        margin-bottom: -10px;
        font: 34px/46px $demi-bold;
        &--small {
            font: 22px/30px $demi-bold;
        }
    }
    &__sensor {
        font: 18px/28px $regular;
    }
    &__top-text {
        font: 16px/20px $regular;
        &--small {
            font: 13px/18px $regular;
        }
    }
}

