@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';


.wrapper {
    display: flex;
    flex-wrap: wrap;
    background: $white;
    border-radius: 10px;
    padding: 10px;
    position: relative;
    z-index: 1;
    gap: 5px;
}

.filterButton {
    gap: 5px;
    background-color: $grey-porcelain !important;
    border-radius: 10px;
    color: $black-shark !important;
}

.filterModal {
    position: absolute;
    width: 300px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    background: $white;
    border-radius: 10px;
    top: 47px;
    padding: 10px;
}

.buttons {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}
