@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';


.button {
    text-align: center;
    outline: none;
    box-sizing: border-box;
    text-decoration: none;
    cursor: pointer;
    display: inline-flex;
    height: 28px;
    min-width: 30px;
    padding: 0 5px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    flex-shrink: 0;
    border-radius: 10px;
    background: $white;
    color: $oslo-gray;
    border: 1px solid transparent;
    font: $font-small $regular;
    &:hover {
        color: $black-shark;
    }
    &:focus {
        color: $black-shark;
        outline: 1px solid $blue-azul;
    }
}

.selected {
    border: 1px solid $blue-azul;
    color: $black-shark;
}

.filled {
    background: $grey-porcelain;
    color: $black-shark;
    &:hover {
        background: $grey-ash;
    }
    &:focus {
        background: $grey-ash;
        outline: none;
    }
}

.filled.selected {
    background: $grey-ash;
    border: unset;
}
