@import "~commons/src/style/variables/media";
@import "~commons/src/style/variables/colors";
@import "~commons/src/style/variables/fonts";

.building-insight {
    &__header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @media screen and (max-width: $mobile-break) {
            display: block;
        }
        &__selector {
            display: flex;
            align-items: center;
            @media screen and (max-width: $mobile-break) {
                display: block;
            }
        }
    }
    &__time-selector {
        width: 100%;
        background-color: $white;
        margin-bottom: 20px;
        border-radius: 10px;
        padding: 10px 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &__elements {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            align-items: center;
            gap: 5px;

            .ant-picker {
                height: 33px;
                border: none;
                margin-bottom: unset;
            }
        }
    }
    &__card {
        width: 100%;
        border-radius: 10px;
        padding: 20px;
        background-color: $white;
        margin-bottom: 20px;
        &__header {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            &--padded {
                > span, svg:not(.spinner), button {
                    margin-bottom: 10px;
                }
            }
            .input-container {
                margin: 0 10px;
            }
        }

        &__body {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            &--empty-state {
                height: 216px;
                align-content: center;
                &__icon {
                    color: $yellow-sunlight;
                    padding-bottom: 10px;
                }
            }
        }
        &__section {
            width: 50%;
            min-width: 490px;
            height: 216px;
            padding: 20px 10px 20px 0;
            &--with-legend {
                padding-bottom: 0;
            }
            &--content {
                width: 100%;
                min-width: 490px;
                &--breakdown {
                    display: flex;
                    &--expanded {
                        width: 50%
                    }
                }
            }
            &--sub {
                height: 180px;
                display: flex;
                justify-content: center;
                padding-bottom: 5px;

                div:first-child:not(.centered__content) {
                    width: 100%;
                    height: 97%;
                    margin-top: 3px;
                }
            }
            @media screen and (max-width: 1200px) {
                min-width: unset;
                width: 100%;
                overflow: scroll;
                &--content {
                    width: 100%;
                    min-width: 550px;
                    &--breakdown {
                        min-width: unset;
                        flex-wrap: wrap;
                    }
                }
                &--display-expand {
                    margin-top: 25px;
                }
            }
            &--relative {
                position: relative;
                overflow: visible;
                &__expand-button {
                    background: $yellow-sunlight;
                    position: absolute;
                    transform: rotate(-90deg);
                    border: unset;
                    width: 70px;
                    height: 25px;
                    overflow: hidden;
                    left: -48px;
                    top: 97px;
                    padding: 0 10px;
                    border-radius: 10px 10px 0 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @media screen and (max-width: 1200px) {
                        transform: unset;
                        top: -25px;
                        left: calc(50% - 35px);
                    }
                }
            }
            &--expanded {
                width: 100%;
                display: flex;
                @media screen and (max-width: 1200px) {
                    padding-bottom: 20px !important;
                }
                &.building-insight__card__section--display-expand {
                    margin-left: 20px;
                }
            }
            &--breakdown {
                background-color: $grey-concrete;
                border-radius: 10px;
                padding: 20px 20px 0 20px;
                height: unset;
                min-height: 216px;
                flex-wrap: wrap;
                &--top-padded {
                    padding: 20px;
                    justify-content: space-between;
                }
                &__graph-background {
                    background: $white;
                    border-radius: 10px;
                    padding-top: 5px
                }
                &__graph {
                    width: 65%;
                    min-width: 250px;
                    @media screen and (max-width: $mobile-break) {
                        width: 100%;
                    }
                }
                &__info {
                    min-width: 100px;
                    width: 35%;
                    padding-bottom: 20px;
                    @media screen and (max-width: $mobile-break) {
                        width: 100%;
                    }
                }
            }
            &__graph {
                rect {
                    fill: $red-persian;
                    width: 15px;
                    stroke: none;
                    &:hover {
                        opacity: 0.7;
                        cursor: pointer;
                    }
                }
                .highcharts-point-select {
                    fill: $red-persian !important;
                    opacity: 0.7;
                    stroke: none !important;
                }
                &--details {
                    .highcharts-background {
                        fill: transparent;
                    }
                }
                &--lower {
                    rect {
                        fill: $blue-celestial;
                        width: 15px;
                        &:hover {
                            opacity: 0.7;
                            cursor: pointer;
                        }
                    }
                }
            }
            &__breach {
                color: $red-persian;
                margin-left: 10px;
            }
        }
    }
}

.threshold-insight-rule {
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .dropdown {
        margin-bottom: 0;
    }

    .input-container {
        margin: 0 10px 0 0;
    }
    &--no-padding {
        padding-bottom: 0;
    }
}
