@import '~commons/src/style/variables/colors.scss';
@import '~commons/src/style/variables/fonts.scss';

.notification-alert {
    &__icons {
        display: flex;
        align-items: center;

        &__circle {
            display: flex;
            justify-content: center;
            border-radius: 50%;
            line-height: 48px;
            background-color: $grey-porcelain;
            color: $black-shark;
            align-items: center;

            &--yellow {
                background-color: $yellow-sunlight;
            }
        }

        &__text {
            display: flex;
            margin-left: 13px;
            color: $black-shark;
            font: $font-medium $regular;

            &--uppercase {
                text-transform: uppercase;
            }
        }
        &__system-notification-text{
            font: 15px/20px $regular;
            color: $black-shark;
            margin-left: 13px;
        }
        &__unit {
            margin-left: 0.5rem;
        }

        svg {
            height: 22px;
            width: 22px;
            display: block;
            margin: auto;
        }
    }

    &__sensor-slider {
        max-width: 300px;
        width: 100%;

        .input__slider__value-label > span > span {
            display: flex;
            font-size: 13px;
            font-family: $regular;
        }

        .PrivateValueLabel-offset-3 {
            top: -30px;
        }

        .input__slider__line {
            margin-top: 7px;
        }

        &__text {
            font: 14px/19px $regular;
            color: $black-shark;
        }
    }

    &__sensor-input-container {
        text-align: center;
        @media screen and (max-width: $mobile-max) {
            text-align: unset;
            display: flex;
            align-items: center;
            margin: -35px 10px 15px 10px;
        }

        &__input_field {
            margin-left: 40px;
            width: 130px;
            display: flex;
            font: 14px/10px $medium;
            color: $grey-shuttle;
            align-items: center;
            @media screen and (max-width: $mobile-max) {
                margin-left: 10px;
            }
        }

        &__margin-sides {
            margin: auto 10px;
        }
    }

    &__chips {
        margin: 10px 0;
        display: flex;
        flex-wrap: wrap;
        white-space: pre-wrap;
        gap: 5px;
        &--height-unset {
            min-height: unset;
            margin-left: 0;
        }
    }

    &__info-text {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;

        &__icon {
            padding-left: 8px;
            margin-right: 0;
            color: $black-shark;
        }
    }

    &__form {
        > button {
            width: 100%;
            max-width: 200px;
        }

        &__section-container {
            margin-bottom: 50px;
        }
    }

    &__checkbox-field {
        align-items: center;
    }

    &__img {
        height: 25px;
    }

    &__indented-row {
        padding-left: 40px;

        &--padded {
            margin-top: 15px;
            width: 100%;

            .mdc-checkbox {
                padding: 11px 5px 11px 0;
            }

            .mdc-checkbox .mdc-checkbox__background {
                left: 2px;
            }

            .mdc-form-field {
                padding-bottom: 5px;
            }
        }

        &__dropdown {
            width: 280px;
        }

        &__paragraph {
            font: 15px/20px $regular;
            color: $black-shark;
            margin: 0;
        }
    }

    &__container {
        padding-right: 25px;
    }

    &__email {
        color: $blue-aura;

        &__text {
            padding-left: 5px;
            font: 16px/20px $regular;
            color: $black-shark;
        }
    }

    &__history {
        .inline-header-lined {
            width: calc(100% + 70px);
            margin-left: -35px;
            border-color: $grey-ash;
            &--margin-top {
                margin-top: 15px;
            }
        }

        &__header {
            display: flex;
        }

        &__event-row {
            display: flex;
            color: $black-shark;
            padding-top: 20px;

            .integration-row__text {
                max-width: calc(100% - 40px);
            }
        }

        &__sensor_content {
            display: flex;
            flex-direction: column;
            margin-right: 20px;
            justify-content: center;
            align-items: center;
            white-space: nowrap;

            &__text {
                margin-bottom: 5px;
                font: 13px/16px $regular;
            }
        }
    }
}

