@import './variables/colors.scss';
@import './variables/fonts.scss';

.pollutant-sensor {
    padding: 15px;
    width: 100%;
    height: 100%;
    color: $black-shark;

    &--tile {
        width: 113px;
        height: 78px;
    }

    &__value {
        font-size: 28px;
        font-family: $bold;
        padding-top: 5px;
    }

    &__name {
        display: block;
        font-size: 16px;
        &--tile {
            margin-top: -3px;
            padding-right: 20px;
        }
    }

    &__block {
        display: flex;
        justify-content: center;
        vertical-align: middle;
        align-items: center;
        height: fit-content;
        font-size: 32px;
        font-family: $bold;
        &--tile {
            font-size: 18px;
            font-family: $regular;
        }
        &__unit {
            font-size: 12px;
            font-family: $medium;
        }
    }
}

.weather-header {
    &__content {
        display: flex;
        justify-content: flex-end;
        align-items:baseline;
        &__value {
            font-size: 22px;
            color: $black-shark;
            padding-left: 3px;
        }
        &__icon {
            .weather-icon {
                width: 45px;
                height: 45px;
                fill: $oslo-gray;
                margin-bottom: -10px;
            }
        }
    }
    &__description {
        color: $black-shark;
        font-size: 16px;
    }
}

.breezometer-link {
    font-size: 12px;
    color: $black-shark;
}
