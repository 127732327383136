@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.saveButton {
    display: flex;
    background-color: $white;
    border-radius: 10px;
    align-items: center;
    text-align: left;
    transition: 300ms;
    width: 100%;
}

.saveText {
    max-width: 260px;
    color: $black-shark;
    font-size: $font-small;
    padding-left: 10px;
}

.errorWrapper {
    margin: 15px 10px;
}
