@import './variables/colors.scss';
@import './variables/fonts.scss';

.ant-picker {
    border-radius: 10px;
    height: 46px;
    border-color: $grey-mist;
    margin-bottom: 10px;
}
.ant-picker-suffix {
    color: $black-shark;
}
.ant-picker-panel-container {
    margin-top: 2px;
    border-radius: 10px;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: $blue-azul;
}
.ant-picker-focused {
    border-color: $blue-celestial;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: $blue-azul;
}
