@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';

.deviceNameWrapper {
    display: inline-block;
    flex-direction: column;
    gap: 3px;
    font-size: $font-medium;
    font-weight: 400;
    text-overflow: ellipsis;
    width: calc(100%); // Width in px is needed for ellipsis to work
    a:hover {
        text-decoration: underline;
    }
}

.deviceName {
    overflow: hidden;
    display: block;
    user-select: auto;
    text-overflow: ellipsis;
    &:focus, &:focus-within{
        outline: 2px solid $blue-aura;
    }
}

.deviceSerialNumber {
    user-select: all;
}
