@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';

.errorWrapper {
    width: 100%;
    max-width: 800px;
    margin: 20px auto;
    padding: 15px;
    background: $white;
    border-radius: 10px;
}
