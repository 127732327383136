@import '~./../../style/variables/colors';
@import '~./../../style/variables/fonts';

.modalContainer {
    padding: 4px 12px;
    border-radius: 10px;
    margin-bottom: 16px;
    background: $white;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
}

.modalContent {
    height: auto;
    border-radius: 10px;
}
