@import "~commons/src/style/variables/media";
@import "~commons/src/style/variables/colors";
@import "~commons/src/style/variables/fonts";

.integration-row__text {
    max-width: calc(100% - 95px);
}

.third-party {
    color: $black-shark;

    &__header {
        padding-bottom: 50px;
    }

    &__img-container {
        display: flex;
        justify-content: center;
        padding-bottom: 15px;
        &__img {
            height: 35px;
        }
    }

    &__error-view {
        padding-bottom: 15px;

        &__error-circle {
            height: 48px;
            width: 48px;
            border-radius: 50%;
            background: $red-persian;
            color: $white;
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
        }
    }

    &__padding {
        &--medium {
            padding-bottom: 30px;
        }
        &--large {
            padding-bottom: 50px;
        }
    }

    &--link {
        color: $blue-aura;
    }

    &--border {
        border-bottom: 1px solid $grey-ash;
    }

    &__button-margin {
        &--small {
            margin-top: 85px;
        }
        &--medium {
            margin-top: 120px;
        }
        &--large {
            margin-top: 150px;
        }
    }

    &__mapping {
        justify-content: space-between;
        display: flex;

        @media screen and (max-width: 550px) {
            margin-left: -10px;
        }
        &__wrapper {
            width: 45%;
            min-width: 325px;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
        &__unlink-button {
            position: absolute;
            left: 50%;
            margin: -23px 0 0 -23px;

            @media screen and (max-width: 550px) {
                    right: 0;
                    left: auto;
                    margin: 3px -25px 0 0;
            }

        }

        &--invert-margin {
            margin-right: -5px;
        }

        &__selector-header {
            font: 18px/25px $demi-bold;
            margin-bottom: 0;
        }

        &__button {
            margin-bottom: 50px;
            padding-bottom: 30px;
        }

        &__tile {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            justify-content: space-between;
            width: 100%;

            &__block-wrapper {
                width: 100%;
            }

            &__straight-line {
                border: 2px solid $green-biosphere;
                min-width: 71px;
                position: relative;
            }

            &__square-line {
                border: 2px solid $green-biosphere;
                border-left: none;
                height: 58px;
                width: 43px;
                margin: -8px 10px 0 0;
                position: relative;
            }

            &__block {
                border: 2px solid $green-biosphere;
                background-color: $grey-porcelain;
                width: 100%;
                height: 48px;
                display: flex;
                align-items: center;
                font: 18px/28px $regular;
                padding: 0 14px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &__text {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            @media screen and (max-width: 550px) {
                &__block {
                    margin-bottom: 8px;
                }
            }
        }
    }

    &__integrations-list {
        flex-direction: column;
        max-width: 425px;
        margin: 50px auto 100px auto;

        &__row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 120px;
            border-bottom: 0.5px solid $grey-mist;

            &--padding-top {
                padding-top: 25px;
            }

            img {
                max-height: 35px;
                margin-top: 5px;
            }

            &__text {
                font: 15px/26px $regular;
            }
        }
    }
}

.webhooks-form {
    &__event-types-container {
        display: flex;
        flex-wrap: wrap;
    }

    &__event-types-label {
        margin-top: 25px;
        margin-bottom: 10px;
    }

    .mdc-form-field > label {
        margin: 0 10px 0 -7px;
    }
}

.webhook-events {
    &> * {
        color: $grey-shuttle;
    }

    &__chips {
        margin: 5px 0 15px -5px;
        display: flex;
        flex-wrap: wrap;
    }

    .list__row__text-container {
        overflow-y: scroll;
        white-space: nowrap;
    }

    &__json-wrapper {
        overflow-y: scroll;
        margin-left: 10px;
        font: 13px/20px $regular;
        white-space: pre;
        overflow: unset;
        text-overflow: unset;
    }

    &__icon-expand {
        color: $blue-aura;
        line-height: 0;
        &:hover {
            cursor: pointer;
        }
    }
}
