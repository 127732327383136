@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';

.input {
    width: 100%;
    max-width: 240px;
}

.body {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 44px;
}
