@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';

.subHeader {
    color: $black-shark;
    font: $font-medium $demi-bold;
    margin-bottom: 10px;
}
.organizePropsHeader {
    font: $font-medium $regular;
}

.dimensionDescription {
    font: $font-small $regular;
    margin: 5px 0 10px 0;
}

.editSpaceText {
    font: $font-small $regular;
    color: $black-shark;
    margin: -15px 0 15px 0;
}

.row {
    display: flex;
    gap: 0 20px;

    @media screen and (max-width: 400px) {
        flex-direction: column;
    }
}

.dropdownWithButton {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.numbersRow {
    display: flex;
    gap: 0 20px;
    width: 100%;
    > * {
        max-width: 120px;
    }
}

.checkbox {
    margin: -20px 0 15px 0;
}

.editButton {
    margin-top: 30px;
}
