@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';

.text {
    font: $font-medium $regular;
    margin-bottom: 10px;
}

.body {
    display: flex;
    flex-direction: column;
    max-width: 210px;
    @media screen and (max-width: 1240px) {
        max-width: unset;
    }
}

.storeLinks {
    display: flex;
    gap: 10px;
}
