@import './variables/colors.scss';
@import './variables/fonts.scss';

.edit-device-info {
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-top: 30px;
  margin-bottom: 30px;
    &--no-margin {
        margin: unset;
    }

  &__icon {
    padding-left: 10px;
    padding-right: 18px;
  }

  &__labels {
    display: flex;
    flex-direction: column;
  }
}

.edit-device {
    &__screen-selector {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__screen-preview {
        position: relative;
        &__img {
            max-width: 297px;
            width: 100%;
        }
        &__content {
            width: calc(100% - (30px * 2));
            position: absolute;
            top: calc((100% - (100px))/2);
            left: 35px;
            display: flex;
            font: 20px/27px $demi-bold;
            text-transform: uppercase;
            padding-top: 10px;
            &--triple-screen {
                position: absolute;
                top: 0;
                left: 25px;
            }
            &--small {
                font: 16px/24px $demi-bold;
            }
            svg {
                height: 64px;
                width: 64px;
                margin-right: 10px;
            }
            #qrCode {
                margin-right: 10px;
            }
            &__value {
                font: 48px/48px $demi-bold;
            }
            &__unit {

            }
            &__text {
                overflow: hidden;
            }
            &--double {
                font: 16px/22px $demi-bold;
                .edit-device__screen-preview__content__value {
                    font: 40px/55px $demi-bold;

                }
                svg {
                    height: 24px;
                    width: 24px;
                    min-width: 24px;
                    margin-right: 5px;
                }
            }
            &__double-wrapper {
                width: calc(50%);
                padding-right: 5px;
            }
            &__single-wrapper {
                width: calc(100% - 64px);
                overflow: hidden;
            }
        }
    }
    &__circle-button-placeholder {
        width: 48px;
        min-width: 48px;
    }
    &__screen-numbers {
        display: flex;
        justify-content: center;
        &__number {
            margin: 4px;
            font: 7px/10px $regular;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $grey-mist;
            border-radius: 50%;
            width: 11px;
            height: 11px;

            &--selected {
                background-color: $black-shark;
            }
        }
    }
}
