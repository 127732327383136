@import './variables/colors.scss';
.spinner {
    height: 22px;
    width: 22px;
    border-radius: 50%;
	animation-name: preload-animation;
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
    stop {
        stop-color: $black-shark;
    }
	&--large {
		height: 50px;
		width: 50px;
	}
    &--path {
        fill: url('#SVGID_1_')
    }
	.stop-1 {
		stop-opacity: 0;
	}
	&__circle {
		width: 100%;
		height: 100%;
		stroke: url(#spinner-circle-gradient);
		fill: none;
		stroke-width: 15;
	}
}
@keyframes preload-animation {
	100% {
		transform: rotate(360deg);
	}
}
