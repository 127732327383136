@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';

.subHeader {
    display: flex;
    padding: 0 30px 10px 30px;
    font: $font-medium $regular;
    color: $black-shark;
}

.icon {
    display: flex;
    align-items: center;
    padding-right: 10px;
}

.timeRangeBox {
    padding: 0 30px;
}

.sensorsBox {
    padding: 0 30px;
}

.sensors {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 10px 30px;
}

.checkbox {
    width: 50%;
    min-width: 180px;
    margin-bottom: 10px;
}

.errorWrapper {
    margin: 0 30px;
}

.exportText {
    padding-bottom: 20px;
}
