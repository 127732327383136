@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';


.wrapper {
    width: fit-content;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}
.innerWrapper {
    max-width: 100%;
}

.slim {
    composes: innerWrapper;
    width: 500px;
}

.medium {
    composes: innerWrapper;
    width: 800px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.wide {
    composes: innerWrapper;
    width: 1194px;
}
.full {
    composes: innerWrapper;
}

.white {
    background: $white;
    border-radius: 10px;
}
