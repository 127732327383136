@import '~commons/src/style/variables/colors.scss';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';
@import '~commons/src/style/mixins.scss';
$table-break: 630px;

.tableWrapper {
    background: $white;
    border-radius: 10px;
}
.desktopTable {
    border-radius: 10px;
    background: $white;
    width: 100%;
    overflow-x: scroll;
    @media screen and (max-width: $table-break) {
        display: none;
    }
    display: block;
}

.table {
    overflow-x: scroll;
    white-space: nowrap;
    table-layout: fixed;
    isolation: isolate;
}

.tableHeader {
    width: 100%;
    padding-right: 58px;
    position: relative !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

.scrollBody {
    &[data-scrolled='true'] {
        td[data-sticky='true'],
        th[data-sticky='true'] {
            &:after {
                z-index: 3;
                position: absolute;
                display: block;
                content: '';
                height: 100%;
                top: 0;
                right: 0;
                width: 5px;
                box-shadow: 0 0 15px rgba(0, 0, 0, 0.65);
                clip-path: inset(0px -15px 0px 0px);
            }
        }
    }
    &[data-scrolled='false'] {
        td[data-sticky='true'],
        th[data-sticky='true'] {
            &:after {
                display: none;
            }
        }
    }
}

.tableRow {
    width: 100%;
    padding-right: 58px;
    background-color: $white;
    &:not(:has(th)):hover {
        background-color: $grey-concrete !important;
    }
    &:last-child {
        td {
            border-bottom: none;
        }
    }
}

.tableCell {
    background-color: inherit;
    z-index: 1;
}

.sticky {
    position: sticky;
    left: 0;
    z-index: 2;
}

.dataCell {
    font-size: $font-small !important;
}

.cellBody {
    display: flex;
    align-items: center;
}

.header:not(:disabled) {
    &:hover {
        color: $blue-aura !important;
    }
    &:focus,
    &:focus-within {
        color: $blue-aura !important;
        outline: 2px solid $blue-aura;
        outline-offset: 2px;
    }
}

.showMoreColumnsButton {
    position: absolute !important;
    top: 4px;
    right: 4px;
    color: $black-shark !important;
    z-index: 2;
    &:hover {
        color: $blue-aura !important;
    }
    &:focus,
    &:focus-within {
        outline: 2px solid $blue-aura;
        color: $blue-aura !important;
    }
}

.filterModal {
    width: 300px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    background: $white;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
}

.buttons {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.columnList {
    display: flex;
    flex-direction: column;
    border: 1px $grey-nepal solid;
    border-radius: 10px;
    padding: 5px 0;
    @include content-divider-y(10px) {
        border: 1px solid $grey-nepal;
    }
}

.columnListElement {
    padding: 3px 15px;
    display: inline-flex;
    font-size: $font-medium;
    font-weight: 400;
    color: $oslo-gray;
    gap: 10px;
}

.label {
    color: $black-shark;
    &:disabled {
        opacity: 0.5;
    }
}
