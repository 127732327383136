@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';

.deviceCounts {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.deviceCountElement {
    font: $font-small $regular;
    width: 170px;
}

.deviceCountElementHeader {
    font: $font-medium $demi-bold;
}

.subscriptionInfo {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px 25px;
}

.nextInvoiceDate {
    display: flex;
    align-items: center;
    gap: 10px;
    font: $font-small $regular;
}
.invoiceDate {
    font: $font-large $medium;
}
.subscriptionInfoWrapper {
    gap: 20px;
    display: flex;
    flex-direction: column;
}

.subscriptionSection {
    margin-top: 20px;
    background: $white;
    border-radius: 10px;
}

.supportText {
    font-size: 15px;
    font-family: $medium;
    padding: 20px;
    margin-top: unset;
}

.startSubscriptionButton {
    padding: 20px 0 0 20px;
}
