@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.header {
    font: $font-large $medium;
    margin: unset;
}

.graphCard {
    background-color: $white;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    margin: 20px 0;
}

.heatMapWrapper {
    height: 250px;
    width: 430px;
    max-width: 100%;
}

.cardContent {
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 1150px) {
     justify-content: center;
    }
}
