@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.redirectBox {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font: $font-medium $regular;
}

.redirectLabel {
    font: $font-medium $demi-bold;
}

.redirectLabelBox {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}
