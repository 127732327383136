@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';

.body {
    display: flex;
    flex-direction: column;
    max-height: 240px;
    overflow: scroll;
}
