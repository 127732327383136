@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';

.organizationInfo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
}
.dateCreated {
    font: $font-medium $regular;
}

.dateCreatedHeader  {
    font-family: $demi-bold;
    white-space: pre;
}
.missingOrgAccess {
    font: $font-small $regular;
    display: flex;
    justify-content: flex-end;
    white-space: pre-wrap;
}

.organizationAccess {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media screen and (max-width: 620px) {
        align-items: flex-start;
    }
}

.pending {
    margin: 10px 0;
    width: fit-content;
}
.accountInfo {
    display: flex;
}
