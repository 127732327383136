@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';

.firmwareWrapper{
    display: inline-flex;
    flex-wrap: wrap;
    gap: 2px;
    line-height: 0.9;
    overflow: hidden;
    text-overflow: ellipsis;
}

.version{
    font-size: $font-small;
}

.version:not(:last-child){
    &::after{
        content: ', ';
    }
}
