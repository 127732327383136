@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';

.subHeader {
    font-size: $font-small;
}
.value {
    font-size: $font-medium;
}
.boldValue {
    font-family: $demi-bold;
}

.outsideBreachWrapper {
    display: flex;
    justify-content: space-between;
 }

.graphWrapper {
    display: flex;
    font-size: $font-small;
    color: $red-persian;
    align-items: center;
}

.longestBreach {
    padding-top: 6px;
}

.longestBreachDevice {
    font-size: $font-medium;
    display: flex;
}
.icon {
    color: $yellow-sunlight;
}

.deviceName {
    font-family: $demi-bold;
    max-width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 5px;
}

.freshAir {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 30px;
    background: $yellow-scotch-mist;
    border-radius: 10px;
    margin-top: 10px;
}
