@import '~commons/src/style/variables/colors';

.add-logo {
    &__container {
        width: 95%;
        max-width: 282px;
        margin: 0;
    }
    &__input-upload {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        color: $black-shark;
        font: 14px/19px $regular;
        margin: 15px 0 5px 0;
    }
    &__file-name {
        padding-left: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__remove-upload {
       cursor: pointer;
        background: none;
        border: none;
        &:hover, &:focus {
            color: $blue-aura;
            outline: none;
        }
    }
}
