@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';

.modalContent {
    display: flex;
    padding: 10px 10px 0 10px;
    gap: 10px;
    flex-direction: column;
}

.tableWrapper {
    overflow-x: scroll;
    width: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
}

.headerText {
    display: flex;
    gap: 5px;
    font-size: $font-large;
    align-items: center;
    svg {
        width: 24px;
        height: 24px;
    }
}

.textBig {
    font-size: 26px;
    font-family: $demi-bold;
}

.redIcon {
    color: $red-persian;
}
