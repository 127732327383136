@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';

.eventElement {
    font: $font-medium $regular;
    color: $black-shark;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.completedAt {
    composes: eventElement;
    font: $font-medium $demi-bold;
}

