@import '../style/variables/colors';
@import '../style/variables/fonts.scss';


.chips {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 3px;
}
