@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.headerText {
    font: $font-large $demi-bold;
    margin: 0;
    padding: 15px 10px;
    text-align: left;
}

.verifyBox {
    display: flex;
    align-items: center;
    width: 130px;
}

.verifyText {
    font: $font-medium;
    margin: 0;
    padding: 0;
    text-align: right;
    color: $oslo-gray;
}

.activateSection {
    display: flex;
    flex-wrap: wrap;
    background-color: $white;
    border-radius: 10px;
    align-items: center;
    text-align: center;
    transition: 300ms;
    width: 100%;
}

.activateText {
    margin-left: 10px;
    text-align: left;
    font: $font-medium $regular;
    line-height: 120%;
}

.articleLink {
    font-size: $font-medium;
}

.errorMessage {
    font: $font-medium $regular;
    color: $red-persian;
}

.errorWrapper {
    margin: 15px 10px;
}
