@import './variables/colors.scss';
@import './variables/media.scss';

.layout {
    margin: -14px 0;
}
.react-grid-item {
    background-color: transparent;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.added-module {
        -webkit-animation: fadein ease-in 1;
        /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
        -moz-animation: fadein ease-in 1;
        animation: fadein ease-in 1;
        -webkit-animation-fill-mode:forwards;
        /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
        -moz-animation-fill-mode:forwards;
        animation-fill-mode:forwards;
        -webkit-animation-duration:.5s;
        -moz-animation-duration:.5s;
        animation-duration:.5s;
        .highcharts-background,
        .tile-wrapper {
            -webkit-animation: colorfade ease-in 1;
            /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
            -moz-animation: colorfade ease-in 1;
            animation: colorfade ease-in 1;
            -webkit-animation-fill-mode:forwards;
            /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
            -moz-animation-fill-mode:forwards;
            animation-fill-mode:forwards;
            -webkit-animation-duration:3s;
            -moz-animation-duration:3s;
            animation-duration: 3s;
        }

    }
}
@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes colorfade {
    from {
        background-color: $yellow-scotch-mist;
        fill: $yellow-scotch-mist
    }
    to {
        background-color: $white;
        fill: $white;
    }
}

.react-grid-placeholder {
    background-color: transparent !important;
    border: 3px dashed $black-shark;
    opacity: 1 !important;
}
@media screen and (max-width: $mobile-max) {
    .dashboard {
        overflow: inherit;
    }
    .react-grid-layout {
        display: flex !important;
        justify-content: center;
        max-width: 370px;
    }
    .react-grid-item {
        margin: 0;
        min-height: 265px;
        height: auto !important;
        .tile-wrapper {
            margin: 0;
        }
    }
}
