@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';

.body {
    gap: 20px 0;
    display: flex;
    flex-direction: column;
    font: $font-medium $regular;
    padding: 10px 20px 10px 20px;
}

.currentPo {
    display: flex;
    gap: 5px
}
.poRef {
    font-family: $demi-bold;
}

.buttonWrapper {
    display: flex;
    gap: 10px;
    justify-content: center;
}
