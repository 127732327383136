@import '~commons/src/style/variables/colors.scss';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';
@import '~commons/src/style/mixins.scss';

.wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-spacing: 0;
    display: block !important;
    border-radius: 15px;
    margin-bottom: 15px;
}

.tableHeader {
    width: 100%;
    background-color: $white;
    color: $black-shark;
    font-weight: 600;
    display: block !important;
    th {
        padding: 4px 5px;
    }
    th:first-child {
        padding-left: 75px;
    }
}

.tableBody {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.tableRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    border-spacing: 0;
}

.cellHeader {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.cellBody {
    width: 100%;
    overflow: hidden;
}

.hideRow {
    display: none;
}
