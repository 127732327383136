@import './variables/colors.scss';
@import './variables/fonts.scss';
@import './variables/media.scss';

@keyframes fade {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
}

.icon-color {
    &--red {
        color: $red-persian;
    }
}
