@import './variables/colors.scss';
@import './variables/fonts.scss';
@import './variables/media.scss';
@import './App.scss';
@import './forms.scss';
@import './buttons.scss';
@import './headers.scss';
@import './deviceTile.scss';
@import './indoorVsOutdoorTile.scss';
@import './placeholder.scss';
@import './page.scss';
@import './tile.scss';
@import './navigation.scss';
@import './spinner.scss';
@import './sensors.scss';
@import './sensorOptions.scss';
@import './addNewDevice.scss';
@import './settings.scss';
@import './dropdowns.scss';
@import './datePicker.scss';
@import './dashboard.scss';
@import './modals.scss';
@import './responseMessageBox.scss';
@import './addModule.scss';
@import './alertBox.scss';
@import './corentiumHomeReportFormPage.scss';
@import './cookieBanner.scss';
@import './supportBanner.scss';
@import './outdoorAir.scss';
@import './table.scss';
@import './wizard.scss';
@import './listTile.scss';
@import './pageHeader.scss';
@import './hubPage.scss';
@import './list.scss';
@import './segmentProperties.scss';
@import './editDevice.scss';
@import './qrLabel.scss';
@import './infoListTile.scss';
@import './thresholdRanges.scss';
@import './unsubscribe.scss';
@import './selectorList.scss';
@import './input.scss';

:root {
    --mdc-theme-primary: #21282d;
    --mdc-theme-secondary: #3CA9F6;
    --mdc-theme-on-primary: #3CA9F6;
    --mdc-theme-on-secondary: #3CA9F6;
}

#root:not(.no-margin) {
    margin-left: 300px;
    @media print {
        margin-left: 0;
    }
}

.blue-body {
    background: $grey-mist;
}

@media screen and (max-width: 1500px) {
    #root:not(.no-margin) {
        margin-left: 90px;
    }
}

@media screen and (max-width: 545px) {
    #root:not(.no-margin) {
        margin-left: 0;
        margin-top: 54px;
    }
}

.print-view {
    display: none !important;
}

@media print {
    #Header,
    #Footer {
        display: none !important;
    }
    .non-print-view {
        display: none !important;
    }
    .print-view {
        display: unset !important;
    }
    .blue-body {
        background: none;
    }
    #root {
        margin-left: 0;
    }
    .sidebar-navigation {
        display: none;
    }
    .hamburger-navigation-container {
        display: none;
    }
    @page {
        margin: 50px 0;
    }
    @page :first {
        margin-top: 0;
    }
}

div,
input {
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
}

body {
    margin: 0;
    padding: 0 0 100px 0;
    font-family: $regular;
    background-color: $grey-concrete;
}

/*
  Ant Design's css has it's own body styles which are applied to the dashboard's existing body.
  These must be unset. This concerns body, html and headers.
**/
body,
html {
    width: unset;
    height: unset;
    line-height: unset;
    font-size: unset;
    color: unset;
    --scroll-bar: unset;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: revert;
}

.container {
    max-width: 1194px;
    margin: 0 auto;
    padding: 0 15px;
}

a {
    text-decoration: unset;
    color: $blue-azul;
}

.centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

h1 {
    font-family: $demi-bold;
    font-size: 34px;
    color: $black-shark;
}

h2 {
    font-family: $medium;
    font-size: 26px;
    color: $black-shark;
    margin: 0;
    font-weight: normal;
}

h3 {
    font: 18px/25px $demi-bold;
    color: $black-shark;
}

.prevent-page-scroll {
    // used to prevent page scroll when inactive subscription on page
    overflow-y: hidden;
    position: absolute;
    height: 100vh;
    width: calc(100vw - 300px);

    @media screen and (max-width: $sidebar-slim) {
        width: calc(100vw - 90px);
    }
    @media screen and (max-width: $sidebar-disappear) {
        width: 100vw;
    }
}

.mdc-radio__outer-circle {
    border: 1px solid $blue-aura !important;
}

.mdc-cancel__background {
    font-size: 16px;
    cursor: pointer;
    padding-left: 5px;
}

.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
    border-color: $blue-aura;
}

.mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
    background-color: $grey-ash;
}

.mdc-checkbox__background {
    height: 16px;
    width: 16px;
    border: 1px solid $blue-aura;
}

.text-paragraph {
    color: $black-shark;
}

.header-2 {
    margin-bottom: 18px;
}

.text {
    &-small {
        font: 13px/16px $regular;
    }

    &-medium {
        font: 15px/20px $regular;
    }

    &-large {
        font: 18px/25px $regular;
    }

    &-bold {
        font-family: $demi-bold;
    }

    &-uppercase {
        text-transform: uppercase;
    }

    &-capitalize {
        text-transform: capitalize;
    }

    &-center {
        text-align: center;
    }

    &-right {
        text-align: right;
    }

    &-left {
        text-align: left;
    }

    &-whitespace {
        white-space: pre-wrap;
    }
}

.hidden {
    display: none;
}

.error-icon {
    font-size: 24px;
    color: $red-auburn;
    margin: auto 10px;
}

.error-message {
    font: 13px/18px $medium;
    color: $red-auburn;
    margin: 10px auto;

    &--center {
        text-align: center;
    }

    &--with-icon {
        align-items: center;
        display: flex;

        .material-symbols-outlined {
            padding-right: 10px;
            padding-bottom: 4px;
        }
    }

    &--battery-settings {
        margin-top: 0;
        margin-bottom: 30px;
    }
}

.success-message {
    font: 13px/18px $medium;
    color: $black-shark;
    margin: 5px auto;

    &--center {
        text-align: center;
    }
}

.flex {
    display: flex;

    &--spaced {
        justify-content: space-between;
    }

    &--column {
        flex-direction: column;
    }

    &--wrap {
        flex-wrap: wrap;
    }

    &--align-center {
        align-items: center;
    }

    &--center {
        justify-content: center;
        text-align: center;
    }
}

.block {
    display: block;
}

.fit-height {
    height: fit-content;
}

.full-width {
    width: 100%;
}

.unset-width {
    width: unset;
}

.small-padding {
    &-right {
        padding-right: 10px;
    }

    &-left {
        padding-left: 10px;
    }

    &-top {
        padding-top: 10px;
    }

    &-bottom {
        padding-bottom: 10px;
    }
}

.margin-20 {
    &-bottom {
        margin-bottom: 20px;
    }
}

.margin-10 {
    &-right {
        margin-right: 10px;
    }
    &-bottom {
        margin-bottom: 10px;
    }
}

.elevation-2 {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.elevation-6 {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-center {
    display: flex;
    align-items: center;
}

.self-flex-right {
    margin-left: auto;
}

.text-wrap {
    white-space: pre;
}

.flex-wrap {
    flex-wrap: wrap;
}

.svg-icon-fill {
    fill: $black-shark;
}

.device-svg-icons {
    @extend .svg-icon-fill;
    width: 48px;
    min-width: 48px;
    height: 48px;

    &--circled {
        display: flex;
        justify-content: center;
        border-radius: 50%;
        line-height: 48px;
        background-color: $grey-porcelain;
        color: $black-shark;
        align-items: center;
    }

    &--margin-right {
        margin-right: 10px;
    }
}

.labeled-text {
    display: flex;
    flex-direction: column;
    font: 14px/19px $regular;

    &__label {
        color: $oslo-gray;

        &--red {
            color: $red-auburn;
        }
    }

    &__multiline {
        white-space: pre;
        padding-top: 13px;
        margin-bottom: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__value {
        height: 46px;
        justify-self: center;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        &--no-padding {
            margin-bottom: 0;
            height: unset;
        }
    }
}

.hover {
    &:hover {
        cursor: pointer;
    }
}
