@import './variables/colors.scss';
@import './variables/media.scss';
@import './variables/fonts.scss';

.list {
    border-radius: 5px;
    padding: 10px 0;
    &__margin {
        margin-bottom: 25px;
    }
    &__margin-top {
        margin-top: 30px;
    }
    &__row {
        display: flex;
        background-color: $white;
        padding: 15px;
        border-radius: 10px;
        align-items: center;
        justify-content: space-between;
        transition: 300ms;
        margin-bottom: 25px;
        width: 100%;
        @media screen and (max-width: $mobile-max) {
            flex-direction: column;
            align-items: flex-start;
        }
        &--danger {
            background-color: $red-chablis;
        }
        &--greyed {
            background-color: $grey-ash;
        }
        &--clickable {
            &:hover {
                cursor: pointer;
                box-shadow: 0 14px 28px rgba(162, 164, 165, 0.1), 0 10px 10px rgba(162, 164, 165, 0.1);
                -webkit-box-shadow: 0 14px 28px rgba(162, 164, 165, 0.1), 0 10px 10px rgba(162, 164, 165, 0.1);
            }
        }
        &--left-aligned {
            justify-content: unset;
        }
        &--lined {
            margin-bottom: 0;
            padding: 25px 48px 0 48px;

            &__underline {
                width: 100%;
                padding-top: 25px;
                border-bottom: 1px solid $oslo-gray;
            }
            &__no-line {
                border-bottom: unset;
            }
        }

        &__text-container {
            width: 100%;
            min-width: 0;
            display: flex;
            flex-direction: column;
            font: 13px/18px $regular;
            color: $oslo-gray;
            margin-right: 5px;

            &> * {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &__header {
                margin: 0;
            }
            &__named-content {
                display: flex;
               &__name {
                   flex-basis: 40%;
               }
                &__value {
                    flex-basis: 60%;
                }
            }

            &__single-line-content {
                display: flex;
                align-items: center;

                @media screen and (max-width: $mobile-max) {
                    flex-wrap: wrap;
                }

                &__img {
                    margin-right: 20px;
                    img {
                        max-height: 15px;
                        margin-top: 5px;
                    }
                }
                &__text {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        &__actions {
            display: flex;
            align-items: center;

            &__dropdown {
                width: 150px;
                margin-right: 12.5px;

                .dropdown {
                    margin-bottom: 0;
                }
            }

            @media screen and (max-width: $mobile-max) {
                justify-content: flex-start;
                flex-wrap: wrap;
                button, select {
                    margin-top: 10px;
                }
            }

            button:not(:last-child) {
                margin-right: 12.5px;
            }
        }
    }
}

.integration-row__action-buttons {
    padding-left: 5px;
    @media screen and (max-width: $mobile-max) {
        padding-left: 25px;
        margin-top: 10px;
    }
}
