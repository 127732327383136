@import '~commons/src/style/variables/media.scss';
@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';

.element {
    display: flex;
    flex-wrap: wrap;
    gap: 7px 20px;
    align-items: center;
}
.linkButton {
    width: 150px;
    font: $font-medium $demi-bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    a {
        font: $font-medium $demi-bold;
    }
}

.serialNumber {
    width: 100px;
}

.lastSynced {
    width: 220px;
}

.rssi {
    width: 100px;
}

.deviceInSpaceHeader {
    width: 100%;
    font: $font-medium $demi-bold;
    overflow: hidden;
    text-overflow: ellipsis;
}
