@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';

.building-status-page {
    display: flex;
    justify-content: center;
    margin: 0 15px;
    font-size: 16px;
    min-width: 300px;

    @media screen and (min-width: $mobile-max) {
        margin: 0 64px;
    }

    &__content {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        flex-basis: 1216px;
        // enables offline-device-item ellipsis
        min-width: 0;
        gap: 30px;
        // https://css-flexbox-text-ellipsis.dinhquangtrung.net/
    }

    &__tile {
        border-radius: 10px;
        background-color: $white;
        padding: 16px 20px;
        width: 100%;

        &--body {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            min-width: 0;
        }

        &--head + &--body {
            margin-top: 16px;
        }

        &--empty {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-grow: 1;
            color: $oslo-gray;
        }
    }
}

.building-status-page-section {
    &__header {
        display: flex;
        align-items: center;
        margin-top: 2px;
        margin-bottom: 10px;
        gap: 5px;
    }

    &__row {
        display: flex;
        flex-grow: 1;
        flex-wrap: wrap;
        gap: 16px;
        align-items: flex-start;
    }

    &__row + &__header {
        margin-top: 32px;
    }

    &__row + &__row {
        margin-top: 16px;
    }

    & + & {
        margin-top: 16px;
    }
}

.offline-device-list {
    display: flex;
    flex-direction: column;
}

.offline-device-item {
    display: flex;
    flex: 1 1 auto;
    gap: 5px 32px;
    padding: 2px 5px;
    border-radius: 5px;

    & > button {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        border: unset;
        background-color: transparent;
        text-align: left;
        cursor: pointer;
    }

    &__name {
        flex-basis: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: $blue-azul;
    }

    &__time {
        font-size: 14px;
        white-space: nowrap;
    }

    &:hover {
        background-color: $grey-porcelain;
    }
}

.offline-devices-tile {
    flex-basis: 384px;
    flex-shrink: 1;
    flex-grow: 1;
}

.offline-devices-tile-head {
    display: flex;

    &__header {
        display: flex;
        gap: 5px;
        align-items: center;

        &--good > * {
            color: $oslo-gray;
        }

        & > * {
            margin: 0;
        }
    }

    &__summary {
        display: flex;
        align-items: center;
        font-size: 15px;
        color: $oslo-gray;
    }
}

.offline-devices-tile-body {
    height: 160px;
    overflow-y: scroll;
    padding-right: 10px;
    margin: 0 32px;
    gap: 8px;
}

.offline-summary-tile {
    width: 200px;
    display: flex;
    border-radius: 10px;
    background-color: $white;
    padding: 10px;
    flex-shrink: 1;
    min-width: 0;
    &--grey {
        background-color: $grey-concrete;
    }
    &__indicator {
        display: flex;
        align-items: center;
        margin-right: 10px;
    }
    &__wifi-icon {
        color: $red-persian;
        margin-right: 10px;
        &--good {
            color: $green-biosphere;
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
    }

    &__label {
        font-family: $medium;
        font-size: 18px;
    }
}

.fraction-status {
    font-family: $regular;

    &__numerator {
        font-family: $demi-bold;
        font-size: 34px;
    }

    &__denominator {
        font-size: 26px;
    }
}

.color-dot-status {
    $dot-size: 24px;
    height: $dot-size;
    width: $dot-size;
    border-radius: 100%;

    &--bad {
        background-color: $red-persian;
    }

    &--good {
        background-color: $green-biosphere;
    }
}

.header-tick-icon {
    color: $green-biosphere;
}

.intercom-helper-center-article {
    max-width: 700px;
    word-break: break-word;
    ul {
        list-style: disc;
        padding-left: 32px;

        & ul {
            list-style: circle;
            padding-left: 32px;
        }
    }

    img {
        max-width: 100%;
    }

    hr {
        height: 3px;
        border: none;
        background-color: $grey-mist;
    }

    a {
        color: $blue-azul;
    }
}

.summaryCardPlaceholder {
    display: flex;
    flex-wrap: wrap;
    background-color: $white;
    border-radius: 10px;
    padding: 10px;
    gap: 10px;
    justify-content: space-between;
    font-size: $font-large;
    width: calc(100% / 3 - 34px);
    min-width: 240px;
    max-width: 350px;
    @media screen and (max-width: $break-submenu) { width: 100%; }
}

