@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.wrapper {
    background: $white;
    border-radius: 10px;
    padding: 20px;
}

.buttonWrapper {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 10px;
    margin-top: 20px;
    justify-content: center;
}
.invoiceInfo {
    font: $font-medium $regular;
}
