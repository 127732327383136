@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

@mixin shared-cell-attributes {
    .cell {
        display: flex;
        padding: 8px;
        align-items: center;
        justify-content: flex-start;
    }

    .firstColumnCell {
        flex-basis: 358px;
        flex-grow: 0;
        padding: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .textColumnCell {
        flex-basis: 180px;
        flex-grow: 0;
        padding: 8px;
        justify-content: flex-end;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.csvButton {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.row {
    display: flex;
    padding: 8px 24px;
    font-size: 15px;
    font-family: $regular;
    @include shared-cell-attributes();

    &:hover {
        background-color: $grey-ash;
    }

    & + & {
        border-top: 1px solid $grey-nepal;
    }
}

.table {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    background-color: $white;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 30px;
}

.tableWrapper {
    margin-bottom: 20px;
}

.textCell {
    text-align: end
}

.header {
    display: flex;
    font-size: 18px;
    padding: 0 24px;
    border-bottom: 1px solid $grey-nepal;
    @include shared-cell-attributes();

    .cell {
        white-space: nowrap;
        font-family: $medium;
    }
}
.darkHeader {
    background-color: $grey-mist;
    border-radius: 10px 10px 0 0;
}

.thresholdText {
    font-size: 13px;
    color: $grey-shuttle;
}
