@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.h2 {
    margin-top: 30px;
}
.h3 {
    margin: 10px 0;
}

.sectionWrapper {
    display: inline-flex;
    flex-direction: column;
}

.suggestedActionBadge {
    border-radius: 10px;
    background-color: $yellow-scotch-mist;
    font-family: $regular;
    padding: 5px;
}

.alignedCellContent {
    display: flex;
    align-items: center;
}

.expandTableElement {
    padding-right: 26px;
}

.paddedIcon {
    margin-right: 5px;
}

.syncProblemIcon {
    margin-right: 5px;
    color: $red-persian;
}

.nameWithInfo {
    display: flex;
    align-items: center;
    gap: 10px;

}
.infoButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: $black-shark;
    font-size: 22px;
    &:hover {
        color: $blue-celestial;
    }
}

.emptyTable {
    display: flex;
    height: 100px;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: $regular;
    font-size: 18px;
    gap: 16px;
}

.emptyTableIcon {
    color: $green-biosphere;
    font-size: 24px;
}

.placeholderTable {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    background-color: $white;
    width: 100%;
    border-radius: 10px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.placeholderHeader {
    display: flex;
    width: 100%;
    height: 38px !important;
    background-color: $grey-mist !important;
    border-radius: 10px 10px 0 0;
    margin-top: unset !important;
}
.placeholderBody {
    width: 100%;
    padding: 0 40px 40px 40px;
}

.infoModalPlaceholder {
    padding: 0 30px 20px 30px;
}
