@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';

.linkToFloorPlan {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.flipButton {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}
