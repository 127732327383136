@import "~commons/src/style/variables/media";
@import "~commons/src/style/variables/colors";
@import "~commons/src/style/variables/fonts";

$background-darker-transparent: rgba(82, 82, 82, 0.38);

.floorplan {
    margin-top: 20px;
    font-family: $regular;

    button {
        outline:none;
    }

    &__border-box {
        &__no-content {
            display: flex;
            justify-content: center;
            padding-top: 15vh;
            align-items: center;
            flex-direction: column;
            color: $black-shark;
            >i {
                font-size: 96px;
            }
            &__header {
                font: 18px/25px $demi-bold;
                margin: 15px 0;
            }
            &__description {
                font: 18px/28px $ultra-thin;
                padding: 0 20px;
            }
        }
    }

    &__no-content {
        box-sizing: border-box;
        width: 100%;
        height: 90vh;
        padding: 1px;
        background: $white;
    }

    &__map {
        grid-area: content;
        background: white;
        border-top: 1px solid $oslo-gray;
    }
}

.floorlist {
    display: flex;
    justify-content: space-between;
    margin: 20px 10px 40px 10px;
    flex-wrap: wrap;
    &__list {
        display: flex;
        align-items: flex-end;
        justify-content: left;
        gap: 15px;
        .dropdown, .input-container {
            min-width: 200px;
            margin-bottom: 0;
        }
        .form__attr--input {
            margin-bottom: 0;
        }
    }
    &__button {
        margin-top: 20px;
    }
}

.add-floor {
    &__input-upload {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        color: $black-shark;
        font: 14px/19px $regular;
        margin: 15px 0 5px 0;
    }
    &__file-name {
        padding-left: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__remove-upload {
       cursor: pointer;
        background: none;
        border: none;
        &:hover, &:focus {
            color: $blue-aura;
            outline: none;
        }
    }
    &__actions {
        display: flex;
        margin-bottom: 15px;
        justify-content: space-between;
        width: 100%;
    }
}

.floormap {
    width: 100%;
    position: relative;


    &__device-modal-wrapper {
        position: absolute;
        top: 230px;
        right: 10px;
        z-index: 500;

        .tile-wrapper {
            margin: 0;
            border: 1px solid $black-shark;
        }
    }
    &__message-box {
        position: absolute;
        bottom: 130px;
        z-index: 400;
        left: calc(50% - 180px);
        display: flex;
        height: 68px;
        width: 360px;
        color: $white;
        background: $grey-river-bed;
        justify-content: center;
        align-items: center;
        font: 16px/20px $ultra-thin;
        padding: 16px;
    }

    .sensor-value {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50% !important;
        width: 40px !important;
        height: 40px !important;

        &--green {
            color: white;
            background-color: $green-forest;
        }

        &--red {
            color: white;
            background-color: $red-auburn;
        }

        &--yellow {
            color: white;
            background-color: $yellow-hot-butter;
        }
    }

    &__map {
        height: 55vh !important;

        &--zone-view {
            .leaflet-pane {
                svg {
                    background-color: $background-darker-transparent;
                }

                path {
                    stroke: $black-shark;
                }
            }
        }

    }

    .leaflet-container {
        font: 14px $medium;
        background-color: $white;
        .leaflet-top {
            z-index: 500;
        }
    }

    .leaflet-draw-tooltip {
        display: none;
        margin-left: 20px;
        background-color: white;
        border-radius: 5px;
        border: 1px solid;
        padding: 5px;
        width: 100px;
    }

    .leaflet-touch {
        a {
            border-radius: 5px !important;
            line-height: 26px;
        }
    }

    .sr-only {
        display: none;
    }

    .leaflet-control-zoom {
        box-shadow: none;
        border: none;

        a {
            color: $black-shark;
            margin: 3px;
            border: 2px solid $grey-shuttle;
            background-color: white;
        }
    }
}


.leaflet-draw-tooltip {
    display: none;
    margin-left: 20px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid;
    padding: 5px;
    width: 100px;
}

.sr-only {
    display: none;
}

.toolbar {
    position: relative;
    width: 100%;
    height: 118px;
    max-width: 1194px;
    margin: 0 auto;

    &__filter {
        position: absolute;
        z-index: 500;
        top: 20px;
        left: 26px;
        &__selector {
            display: flex;
            align-items: center;
            &__text {
                padding: 0 15px;
                @media screen and (max-width: $sidebar-disappear) {
                    display: none;
                }
            }
        }
    }

    &__delta-logo {
        font-size: 22px;
    }

    &__dropdown-filter {
        height: 34px;
        width: 34px;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        border: 2px solid $grey-shuttle;
        display: flex;

    }

    &__sensors {
        position: absolute;
        background-color: $white;
        border-radius: 5px;
        flex-direction: row;
        flex-wrap: wrap;
        width: 140px;
        justify-content: space-evenly;
        left: 0;
        top: 85px;
        align-items: center;
        box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.2);
        padding: 5px;
        display: none;
        gap: 10px;

        &--show {
            display: flex;
        }
        &__placeholder {
            height: 48px;
            width: 48px;
        }
    }

    &__sensor {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid $grey-mist;
        margin: 3px;
        height: 36px;
        width: 36px;
        border-radius: 50px;

        &--active {
            border: 2px solid $black-shark;
        }
    }
    &__action-wrapper {
        padding: 10px;
        margin-right: 10px;
        border-radius: 35px;
        background-color: $white;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
        gap: 10px;
        display: flex;
        flex-direction: column;
    }

    &__actions {
        position: absolute;
        z-index: 500;
        top: 20px;
        right: 15px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
    }

    &__action {
        border-radius: 100%;
        color: $white;
        cursor: pointer;
        background-color: $black-shark;
        height: 48px;
        width: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px;
        border: none;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

        svg {
            fill: $white;
            width: 25px;
            height: 25px;
        }
    }
}


.leaflet-control-attribution {
    display: none !important;
}

.sensor-marker {
    border-radius: 0 !important
}

.marker {
    background-color: transparent;
    margin-left: -6px !important;
    margin-top: -6px !important;

    &--active {
        border-radius: 0;
        height: 55px;
        width: 43px;
        position: absolute;
        top: -48px;
        left: -17px;

    }

    &--inactive {
        border-radius: 0;
        height: 20px;
        width: 20px;
        position: absolute;
        top: -5px;
        left: -5px;
        margin: auto;
        right: 0;
    }
}

.leaflet-dragging {
    .marker {
    }

    .marker:hover {
        cursor: none !important;
    }

    &:hover {
        cursor: none;
    }
}

