@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';

.content {
    margin: 20px 30px;
    max-height: 380px;
    overflow: scroll;
}
.row {
    display: flex;
    align-items: center;
    font: $font-medium $regular;
    color: $black-shark;
    border-bottom: 1px solid $grey-nepal;
    height: 35px;
    cursor: pointer;
}

.subscriptionNumberCell {
    width: 120px;
}

.poCell {
    display: flex;
    width: calc(100% - 120px);
    justify-content: space-between;
    align-items: center;
}
.poNumber {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
