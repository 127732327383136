@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';

.body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px;
    position: absolute;
    z-index: 1000;
    right: 0;
    bottom: 100px;
}

.sensor {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.unit {
    font: $font-small $regular;
    color: $grey-nepal;
}

.sensorInfo {
    display: flex;
    padding: 2px 7px;
    align-items: center;
    gap: 4px;
    border-radius: 5px;
    background: $white;
    box-shadow: 0 3px 4px 2px rgba(0, 0, 0, 0.20);
    font: $font-small $regular;
}

.battery {
    transform: rotate(180deg);
    font-size: 22px;
}
