@import './variables/colors.scss';
@import './variables/fonts.scss';

.dropdown {
    width: 100%;
    position: relative;
    margin-bottom: 10px;

    &--no-bottom-margin {
        margin-bottom: 0;
    }

    &__search {
        padding-left: 10px;

        .mdc-menu-surface {
            max-width: 400px;
            margin-top: -20px;
            width: 100%;
            max-height: 260px !important;
            font-size: 18px;
            font-weight: 600;
            ::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &__list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 46px;
        padding: 0 10px;
        background: $white;
        border: 1px solid $grey-mist;
        outline: none;
        border-radius: 10px;

        &:hover {
            cursor: pointer;
        }
        &:focus {
            outline: -webkit-focus-ring-color auto 5px;
        }
        &--invalid {
            background: $red-chablis;
        }
        &--disabled {
            background: $grey-ash;
        }

        &__placeholder {
            font: 14px/17px $regular;
            color: $black-shark;
        }

        &__option {
            list-style-type: none;
            font: 14px/16px $regular;
            transition: all .2s ease;
            background: $white;
            color: $black-shark;
            padding: 7px 10px;
            margin: 0;
            width: 100%;
            height: 100%;
            text-align: left;
            cursor: pointer;

            &:hover {
                cursor: pointer;
                background: $black-shark;

                .dropdown__list__option__button {
                    color: $white;
                }
            }
        }
    }
    select {
        font: 14px/16px $regular;
    }

    .input-container__hint {
        top: 0;
    }
}


.device-list {
    background-color: white;
    box-shadow: 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.2);
    border-radius: 10px;
    left: -200px !important;
    top: 85px!important;
    width: 270px;
    z-index: 500;

    &__item {
        padding: 0.5rem;
        display: flex;
        align-items: center;
        color: $black-shark !important;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: $font-large !important;
        font-family: $regular !important;
        &--spaced {
            justify-content: space-between !important;
        }
        svg {
            fill: $black-shark;
            height: 24px;
            width: 24px;
            margin-right: 10px;
        }
        &:hover {
            background: $grey-porcelain;
        }
        &__chip {
            margin-left: 10px;
        }

        &__following-action {
            margin-top: 10px;
        }
        &--empty {
            cursor: auto;
            &:hover {
                background: inherit;
            }
        }
    }
}
