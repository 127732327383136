@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';

.public-device {
    &__header {
        margin-bottom: unset !important;
    }
    &__table {
        border: unset;
        max-width: 100%;
        min-width: 100%;
        padding-bottom: 24px;
        font: 14px/18px $regular;
        &__row {
            display: flex;
            border-top: 1px solid $oslo-gray;
            height: 50px;
            align-content: center;
            align-items: center;
            text-align: center;
            justify-content: space-between;
            &--header {
                color: $oslo-gray;
                border-top: unset;
            }
        }
        &__cell {
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 10px;
            width: 120px;
            white-space: nowrap;
            text-align: left;
            @media screen and (max-width: 700px) {
                display: none;
            }

            &--device-type {
                max-width: 120px;
                width: calc((100% - (120px + 50px))* 0.35);
            }
            &--name {
                font: 18px/25px $regular;
                width: calc((100% - (120px + 50px))* 0.65);
                @media screen and (max-width: 700px) {
                    display: block;
                    width: calc(100% - 50px);
                }
            }
            &--device-details {
                font: 14px/18px $regular;
                display: flex;
                justify-content: space-between;
                @media screen and (min-width: 700px) {
                    display: none;
                }
            }
            &--name-header {
                width: calc((100% - (120px + 50px))* 0.65);
                @media screen and (max-width: 700px) {
                    display: flex;
                    width: calc(100% - 50px);
                }
            }
        }
        &__checkbox {
            width: 50px;
            padding-right: unset;
        }
    }
}
