@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.wrapper {
    background: $white;
    border-radius: 10px;
    padding: 20px;
}

.buttonWrapper {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 10px;
    margin-top: 20px;
    justify-content: center;
}

.imageContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.textWrapper {
    font-size: $font-medium;
    margin: 20px 0;
    gap: 10px;
    display: flex;
    flex-direction: column;
}
