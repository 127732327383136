@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';

.body {
    margin: 0 20px 10px 20px;
}

.buttonWrapper {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 20px;
    flex-wrap: wrap;
}

.errorWrapper {
    width: 100%;
    margin-bottom: -18px;
}
