@import './variables/colors.scss';
@import './variables/fonts.scss';
@import './variables/media.scss';

.cookie-banner {
    background-color: $black-shark;
    position: relative;
    text-align: center;
    width: 100%;
    height: 45px;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;

    &__message, &__link {
        line-height: 23px;
        color: $white;
        font-size: 14px;
        font-family: $ultra-thin;
    }
    &__link {
    margin-left: 10px;
    }

    &__button {
        line-height: 24px;
        margin-left: 10px;
        padding: 0 8px;
        background-color: rgba(255, 255, 255, 0.6);
        border: none;
        border-radius: 3px;
        box-shadow: none;
        font-size: 12px;
        font-weight: 500;
        color: $black-shark;
        cursor: pointer;

    }
    @media screen and (max-width: $mobile-max) {
        height: 65px;
        &__message {
            line-height: 18px;
        }
    }
}
