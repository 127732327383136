@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';

.label {
    font: $font-medium $regular;
    min-width: 30px;
    flex-wrap: nowrap;
    display: flex;
    align-items: baseline;
}
