@import '../../style/variables/colors';
@import '../../style/variables/fonts';

.limitedSubscription {
    background: $blue-marble;
    margin-top: -20px;
    padding: 20px 30px;
    border-bottom: 1px solid $oslo-gray;
    line-height: 12px;
}

.trialSubscription {
    background: $green-spring;
    margin-top: -20px;
    padding: 20px 30px;
    border-bottom: 1px solid $oslo-gray;
    line-height: 12px;
}

.trialExpiryInfo{
    font-size: $font-small;
    margin-top: 10px;
    line-height: 15.6px;
    padding:10px 30px;
}

