@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';

.body {
    align-items: flex-start;
    font: $font-small $regular;
    justify-content: center;
    display: flex;
    flex-direction: column;
    svg {
        width: 28px;
        height: 28px;
    }
}

.batteryGreyed {
    color: $oslo-gray;
    fill: $oslo-gray;
}

.battery {
    font: $font-medium $regular;
    align-items: center;
    display: flex;
    gap: 5px;
}
