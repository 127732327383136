@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';
@import '~commons/src/style/mixins.scss';

.form {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    font: $font-medium $regular;
    gap: 10px;
}

.header {
    font: $font-medium $demi-bold;
}

.input {
    width: 218px;
}

.buttons {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 20px;
    gap: 20px;
    flex-wrap: wrap;
}

.dropdownRow {
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    font: $font-medium $regular;
}
