@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';

.header {
    display: flex;
    padding: 20px;
    border-bottom: 1px solid $grey-ash;
    gap: 10px;
    position: relative;
    z-index:999;
}

.headerDetails {
    display: flex;
    gap: 10px;
    align-items: center;
    position: relative;
    z-index:999;
}

.headerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}

.floorNr {
    font: $font-large $regular;
    color: $oslo-gray;
}
.floorName {
    font: $font-large $demi-bold;
    color: $black-shark;
}
.dropdown {
    margin-top: 4px;
}
