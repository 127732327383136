@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';

.ledButton {
    background: $grey-ash;
    border: 1px solid $grey-mist;
    border-radius: 10px;
    min-height: 70px;
    display: flex;
    align-items: center;
    padding: 10px;
}
.imageWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 40px 0 20px;
}
.color {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin-top: 1px;
}

.red {
    composes: color;
    background: $red-persian;
    box-shadow: 0 0 6px 5px $red-persian;
    border-color: $red-persian;
}
.white {
    composes: color;
    background: $white;
    box-shadow: 0 0 6px 5px $white;
    border-color: $white;
}
.selected {
    composes: color;
    animation: fade 2s linear infinite;
}
