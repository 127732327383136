@import './variables/media.scss';
@import './variables/colors.scss';
@import './variables/fonts.scss';

.page-tabs {
    color: $black-shark;
    height: 50px;
    background-color: white;
    position: relative;

    &--centered {
        display: flex;
        justify-content: center;
    }
}

.page-header {
    background-color: white;
    color: $black-shark;
    min-height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    &__margin {
        padding-left: 90px;
    }

    &__name-padded {
        padding-top: 27px;
    }

    &__back-link {
        font: 15px/20px $regular;
        color: $grey-shuttle;
        text-decoration: none;
        display: flex;
        align-items: center;
        border: none;
        background: transparent;
        cursor: pointer;
        margin-left: -30px;
        &--config-view {
            margin-left: 0;
        }
    }
    h1 {
        margin-top: 0;
        word-break: break-word;
    }
    @media screen and (max-width: $sidebar-disappear) {
        padding-top: 0;
        min-height: 70px;
        &__margin {
            padding-left: 30px;
        }
        &__name-padded {
            padding-top: unset;
        }
        h1 {
            margin-top: 0;
            margin-bottom: 7px;
        }
    }
    &--padded {
        margin-bottom: 30px;
    }
    &__button-container {
        top: 23px;
        height: 56px;
        margin-right: 20px;
        position: relative;
        display: flex;
        width: fit-content;
        margin-left: auto;
        button {
            padding: 0;
        }
        &--mini {
            top: 36px;
        }
        .dropdown-options__list {
            width: fit-content;
            top: 65px;
            left: unset;
            right: 10px;
        }
    }
    @media screen and (max-width: $sidebar-disappear) {
        .notification__menu-item {
            display: none;
        }
    }
}

.sub-header {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto 10px auto;
    max-width: 1194px;
    justify-content: space-between;
    align-items: center;
    &--only-buttons {
        justify-content: flex-end;
    }
    &__button-container {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }
}
