@import "~commons/src/style/variables/media";
@import "~commons/src/style/variables/colors";
@import "~commons/src/style/variables/fonts";


.building-config {
    &__wrapper {
        margin-bottom: 20px;
    }
    &__text {
        white-space: pre-wrap;
        margin-bottom: 0;
    }
    &__radio {
        width: 100%;
        align-items: baseline;
        margin-top: 20px;
        label {
            width: calc(100% - 40px);
        }
    }
    .battery-life {
        &__device-title {
            padding-top: 20px;
            font-weight: bold;
        }

        &__toggle-sensor-wrapper {
            margin-left: auto;
        }
        &__sensor-selector {
            min-width: 200px;
            width: 50%;
        }
        &__outside-opening-hours-info {
            padding-left: 32px;
            white-space: pre-wrap;
            padding-bottom: 20px;
        }
    }
}
