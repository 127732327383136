@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';

.viewWrapper {
    margin: 10px 20px;
}

.billingDuration {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    padding-bottom: 10px;
    gap: 5px;
}
