@import './variables/colors.scss';
@import './variables/fonts.scss';
@import './variables/media.scss';

.page-title {
    width: auto;
    max-width: 1166px;
    text-align: left;
    margin: 26px auto 0 auto;
    &:first-letter {
        text-transform: capitalize;
    }
    &__button-container {
        display: flex;
    }
}

.page-section--full-width {
    width: 100%;
}
.show-loading-animation {
    & > div:first-child {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
}

.page-wrapper-flex {
    display: flex;
    max-width: 1194px;
    margin: 25px 85px 0 85px;
    justify-content: flex-start;
    &--wrap {
        flex-wrap: wrap;
    }
    @media screen and (max-width: $mobile-max) {
        margin: 25px 25px 0 25px;
    }
    @media screen and (max-width: $sidebar-disappear) {
        margin: 25px 14px 0 14px;
    }
    &--no-margin-top {
        margin-top: 14px;
        margin-bottom: 14px;
    }
    &--content-margin {
        flex-wrap: wrap;
        max-width: 1222px;
        margin: 80px 71px 0 71px;
        @media screen and (max-width: $mobile-max) {
            margin: 25px 11px 0 11px;
        }
        @media screen and (max-width: $sidebar-disappear) {
            margin: 25px 3px 0 3px;
        }
    }
}

.page-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 1194px;
    &--grid-layout {
        & > div:first-child {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
        }
    }
    &--block {
        display: block;
        padding: 15px;
    }

    &--column {
        text-decoration: none;
    }
    .tile-header {
        .sensor__block {
            margin: 0;
        }
    }
    &__spinner {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 100px;
    }
    &__column {
        .tile-wrapper {
            .tile-header--info,
            > .tile-header {
                flex-basis: calc(100% - 10px);
                justify-content: space-between;

                .tile-header__title {
                    max-width: 190px;
                    &--full-width {
                        max-width: 290px;
                    }
                }

                .sensor__block {
                    margin-left: 0;
                }
            }
        }

        &--two-cols {
            flex-basis: 796px;

            .tile-wrapper {
                width: ((370px + 14px) * 2); // 768px (tile width + margin multiplied by two)

                .tile-header--info,
                > .tile-header {
                    flex-basis: 370px;
                    justify-content: space-between;

                    .tile-header__title {
                        max-width: 320px;
                    }

                    .sensor__block {
                        margin-left: 25px;
                    }
                }
            }
        }
        &--three-cols {
            flex-basis: 1196px;

            .tile-wrapper {
                width: ((370px + 14px) * 3 + 14px); // 1166px (tile width + margin multiplied by three + margin)

                .tile-header--info,
                > .tile-header {
                    flex-basis: inherit;

                    .tile-header__title {
                        max-width: 488px;
                    }

                    .sensor__block {
                        margin-left: 40px;
                    }
                }
            }
        }

        &:not(.page-wrapper__column--three-cols) {
            .tile-wrapper {
                .tile-header__controls {
                    .dropdown-options__caption--dots {
                        padding-left: 0;
                    }
                }
            }
        }
    }
    &__inner {
        width: 100%;
        margin: 0 auto 20px auto;

        &--slim {
            max-width: 500px;
        }
    }
    .no-content-page {
        width: 100%;
        height: 50vh;
        display: flex !important;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        flex-wrap: nowrap !important;
        &__content {
            max-width: 471px;
        }
        &__image {
            width: 100px;
            height: 100px;
            fill: $black-shark;
        }
        &__icon {
            height: 90px;
            fill: $black-shark;
            font-size: 150px;
            color: $black-shark;
            &--material {
                height: unset;
            }
        }
        &__header {
            margin-bottom: 50px;
        }
        &__title {
            max-width: 100%;
            margin: 25px 0 0 0;
            font-size: 28px;
            font-weight: 500;
            color: $black-shark;
            &--medium-font {
                font-family: $medium;
            }
        }
        &__text {
            white-space: pre-wrap;
            margin: 25px 0 0 0;
            font: 18px/28px $regular;
            font-weight: 500;
            color: $black-shark;
            &--ultra-thin {
                font-family: $ultra-thin;
            }
            &--link {
                color: $blue-venice;
            }
        }
    }
    .not-found {
        height: 80vh;
    }
    .icon-alert {
        width: 90px;
        fill: $grey-shuttle;
    }
    &__medium {
        width: 100%;
        max-width: 800px;
        margin: 20px auto;
        padding: 0 15px;
        &--white {
            background: $white;
            border-radius: 10px;
            padding: 0;
        }
        &--no-margin-top {
            margin-top: 0;
        }
        &--padded {
            padding: 22px 36px 36px 36px;
        }
    }

    &__padded {
        padding: 12.5px;
    }
}

@media screen and (max-width: $tablet-max) and (min-width: $tablet-min) {
    .page-wrapper {
        max-width: 796px;
        &__column {
            &--two-cols,
            &--three-cols {
                .tile-wrapper {
                    width: 768px;
                }
            }
            &--three-cols {
                flex-basis: 100%;
                .tile-header__title {
                    max-width: 156px !important;
                }
                .sensor__block {
                    margin-left: 20px !important;
                }
            }
        }
    }
    .page-title {
        max-width: 772px;
    }
}
@media screen and (max-width: $mobile-max) {
    .page-wrapper {
        max-width: 97%;
        justify-content: center;
        &--show-overflow {
            overflow: unset;
        }
        .wizard__device-module {
            margin: auto;
            border-right: none;
            border-bottom: 1px solid $grey-ash;
        }
        .no-content-page__icon {
            margin: 25% 0 0 0;
        }
        .add-module__title {
            margin: 0 0 15px 0;
        }
        &__column {
            &--two-cols,
            &--three-cols {
                text-align: -webkit-center;
                .tile-wrapper {
                    min-height: 265px;
                    height: auto;
                    width: 370px;
                    .tile-header {
                        min-height: 88px;
                        height: auto;
                        flex-wrap: wrap;
                        &--info {
                            flex-basis: calc(100% - 30px);
                            justify-content: space-between;
                        }
                        &__title {
                            max-width: 170px !important;
                        }
                        .sensor__block {
                            margin-left: 20px !important;
                        }
                        .options-row__filter-buttons {
                            display: none;
                        }
                    }
                }
            }
            &--three-cols {
                flex-basis: 100%;
            }
        }
        &:not(.page-wrapper__column--three-cols) {
            .tile-wrapper {
                .tile-header__controls {
                    .dropdown-options__caption--dots {
                        padding-left: 15px;
                    }
                }
            }
        }
    }
    .page-wrapper--column {
        width: 100%;
        text-align: -webkit-center;
    }
    .page-title {
        max-width: 370px;
        width: 100%;
    }
}
