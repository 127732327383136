@import '~./../../style/variables/colors';
@import '~./../../style/variables/fonts.scss';

.overlay {
    position: fixed;
    inset: 0;
    z-index: 1000;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background: rgba(0, 0, 0, .50);
}

.modal {
    max-height: 80vh;
    overflow: auto;
    min-width: 300px;
    padding: 13px 0;
    margin-top: 15vh;
    box-shadow: 0 2px 12px 0 rgba(41, 34, 34, 0.52);
    background: $white;
    border-radius: 10px;
    border: 1px solid $grey-ash;
}

.small {
    composes: modal;
    width: 350px;
}
.medium {
    composes: modal;
    width: 500px;
}
.large {
    composes: modal;
    width: 800px;
}
.fullscreen {
    composes: modal;
    margin: 20px;
    width: 100%;
    height: 97vh;
    max-height: unset;
    background: $grey-ash;
    padding: 20px;
}
.expandedView {
    border: none;
    padding: 0;
    box-shadow: none;
    background: transparent;
}
