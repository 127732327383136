@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.wrapper {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    background: $white;
    border-radius: 10px;
    padding: 10px;
    font-family: $regular;
}

.selectorOptions  {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    gap: 5px;
}

