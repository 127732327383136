@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';

.graphWrapper {
    position: relative;
    flex:1;
}

.focusView {
    min-height: 350px;
}

.listView {
    min-height: 180px;
}

.noData {
    position: absolute;
    border-radius: 10px;
    font: $font-medium $regular;
    width: 300px;
    max-width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    background: $white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    padding: 10px;
    left: calc(50% - 150px);
}

.noDataFocus {
    top: 140px;
}

.noDataList {
    top: 30%;
}

.spinnerBlock {
    display: flex;
    justify-content: center;
    align-items: center;
}
