@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.energyDescription {
    width: 100%;
    background-color: $white;
    border-radius: 10px;
    padding: 10px;
}

.link {
    font: $font-small $regular;
}

.timeSelectorWrapper {
    width: 100%;
    background-color: $white;
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 10px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
