@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';
@import '../../style/variables/media.scss';

.subMenuWrapper {
    background: $white;
    width: 100%;
    border-radius: 10px;
    padding: 5px;
}

.subMenuWrapperActive {
    composes: subMenuWrapper;
    background: $grey-porcelain;
    border-bottom: 2px solid $white;
}

.subMenuHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    font: $font-large $medium;
    height: 50px;
    cursor: pointer;
}
