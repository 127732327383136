@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.wrapper {
    background-color: $grey-concrete;
    min-height: 260px;
    width: 250px;
    border-radius: 10px;
    padding: 10px;
    position: relative;
    overflow: visible;
    flex-wrap: wrap;
    @media screen and (max-width: 1000px) {
        width: 100%;
        margin-top: 20px;
    }
}

.subWrapper {
    height: 240px;
    overflow: scroll;
    width: 230px;
}

.clickableDeviceName {
    font-family: $regular;
    font-size: $font-medium;
    cursor: pointer;
    &:hover {
        font-family: $demi-bold;
    }
}
.clickableDeviceNameSelected {
    composes: clickableDeviceName;
    font-family: $demi-bold;
}

.dateHeader {
    margin: 0;
    font: $font-medium $demi-bold;
}

.eventName {
    margin: 10px 0;
    display: flex;
    font-size: $font-medium;
    align-items: flex-start;
    gap: 5px;
}

.eventColorBlock {
    margin-top: 3px;
    width: 14px;
    height: 14px;
    border-radius: 4px;
}

.warmerOutsideOpeningHours {
    composes: eventColorBlock;
    background-color: $red-auburn;
}
.heatingOnClosedDay {
    composes: eventColorBlock;
    background-color: $yellow-hot-butter;

}
.colderOutsideOpeningHours {
    composes: eventColorBlock;
    background-color: $blue-bondi;
}
.coolingOnClosedDay {
    composes: eventColorBlock;
    background-color: $green-jade;
}
.colderThan5C {
    composes: eventColorBlock;
    background-color: $blue-azul;
}
