@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';

.mapWrapper {
    width: 100%;
    height: calc(100% - 82px);
    position: relative;
    border-radius: 10px;
}

.container {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background: $white;
}

.fullscreenButton {
    display: flex;
    justify-content: right;
    padding: 2px;
    position: absolute;
    z-index: 999;
    right: 10px;
    top: 20px;
    border: 1px solid $grey-nepal;
    background-color: $white;
    transition: border-color 0.3s;
    &:hover {
        border: 1px solid $blue-aura;
    }
}
