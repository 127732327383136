@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';

.body {
    padding: 20px;
    height: 500px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.centered {
    justify-content: center;
}

.deleteSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
}

.deleteTitle {
    font: $font-large $demi-bold;
}

.deleteSubText {
    font: $font-medium $regular;
}

.deleteButtons {
    display: flex;
    gap: 15px;
}
