@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';


.button {
    display: flex;
    gap: 5px;
    align-items: center;
    text-decoration: none;
    color: $blue-azul;
    white-space: pre-wrap;
    font: $font-medium $regular;
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
    &:focus {
        outline: 2px solid $blue-aura;
        outline-offset: 1px;
        border-radius: 5px;
    }
}

.smallButton {
    composes: button;
    font: $font-small $medium;
    padding: 2px 0;
}

.noLineBreak {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.icon {
    text-underline: none;
}
