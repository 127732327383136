@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';

.rssi_wrapper {
    display: flex;
    align-items: flex-end;
    column-gap: calc(var(--barHeight) * (2 / 11));
    height: var(--barHeight);
}

.rssi_with_description {
    display: flex;
    align-items: center;
    gap: 3px;
}

.rssi_description {
    margin-right: 10px;
    font: 12px/16px $regular;
}

.rssi_bar {
    display: inline-block;
    height: 100%;
    width: calc(var(--barHeight) * (3 / 11));
    border-radius: calc(var(--barHeight) * (5 / 11));
    background: $grey-ash;
}

.rssi_one_bar {
    height: calc(100% * (5 / 11));
}

.rssi_two_bars {
    height: calc(100% * (7 / 11));
}

.rssi_three_bars {
    height: calc(100% * (9 / 11));
}

.rssi_four_bars {
    height: 100%;
}

.black {
    background-color: $black-shark;
}

.inverted {
    background-color: $oslo-gray;
}

.disconnected {
    background-color: $grey-ash;
}

.blue {
    background-color: $blue-azul;
}

.none {
    background-color: $grey-ash;
}

.red {
    background-color: $red-persian;
}

.yellow {
    background-color: $yellow-sunlight;
}

.green {
    background-color: $green-biosphere;
}
