@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';

.billingDetailsSection {
    background: $white;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.section {
    white-space: pre-wrap;
    width: calc(50% - 10px);
    min-width: 200px;
    font-size: $font-small;
    @media screen and (max-width: 580px) {
       width: 100%;
    }
}

.sectionHeader {
    font-family: $demi-bold;
    font-size: $font-medium;
}

.details {
    display: flex;
    white-space: pre-wrap;
    word-break: break-word;
}

.errorWrapper {
    width: 100%;
}

.supportInfo {
    font: $font-medium $regular;
}
