@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';
@import '../../style/variables/media.scss';

.subHeader {
    margin: 0;
    font: 26px $medium;
    color: $black-shark;
}
.subHeaderWrapper {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    margin-top: 30px;
}

.initialHeaderWrapper {
    composes: subHeaderWrapper;
    margin-top: 0;
}

.headerAndLinksWrapper {
    margin-bottom: 10px;
    gap: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: baseline;
    button {
        font: $font-small $regular;
        padding: 0;
    }
}

.timeSelectorWrapper {
    width: 100%;
    background-color: $white;
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 10px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
