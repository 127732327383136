@import './navigation';
@import '~commons/src/style/variables/media.scss';
@import '~commons/src/style/variables/colors.scss';
@import './buildingTile';
@import './buildingPage';
@import './buildingStatus';
@import './floorplan';
@import './treeView';
@import './integrations';
@import './insightTile';
@import './notificationAlerts';
@import './organizationProfile';
@import './insightTable';
@import './inletAirThresholds';
@import './publicDevices';
@import './buildingConfig';
@import './userManagement';
@import './partnerPortal';
@import './buildingAddDevice';
@import './buildingInsight.scss';
@import './mqtt.scss';

ol, ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.error-alert {
    display: flex;
    background-color: $red-chablis;
    padding: 12.5px;
    margin: 12.5px 0;
    border-radius: 5px;
    align-items: center;
    justify-content: space-between;

    .error-message {
        margin: 12.5px;
    }

}
.icon-rotated {
    transform: rotate(90deg);
}

.blur-pages {
    .subscription-ended__blur {
      //  filter: blur(2px);
    }
}
