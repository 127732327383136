@import './variables/colors.scss';
@import './variables/fonts.scss';

.unsubscribe-icon {
    color: $black-shark;
    font-size: 110px;
}

.unsubscribe-page {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
