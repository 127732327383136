@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';
@import '../../style/variables/media.scss';

:root {
    --header-height: 110px;
}

.overlay {
    display: flex;
    position: fixed;
    left: 300px; // width of navigation - need to change based on navigation
    top: var(--header-height); // height of header
    right: 0;
    bottom: 0;
    z-index: 500;
    align-items: flex-start;
    justify-content: center;
    background: rgba(0, 0, 0, .30);
    backdrop-filter: blur(5px);
    @media screen and (max-width: $sidebar-slim) {
        left: 90px;
    }
    @media screen and (max-width: $sidebar-disappear) {
        left: 0;
    }
}

