@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.ratePlanSections {
    display: flex;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    max-width: 800px;
}

.iconWithText {
    display: flex;
    align-items: center;
    gap: 5px;
}

.headerWithCalendar {
    display: flex;
    font: $font-medium $demi-bold;
    padding-bottom: 20px;
    align-items: center;
    gap: 5px;
}

.container {
    padding: 20px;
}

.content {
    width: 100%;
    display: flex;
    align-items: flex-end;
    align-self: stretch;
    gap: 10px;
    @media screen and (max-width: $mobile-break) {
        flex-wrap: wrap;
        gap: unset;
    }
}

.inputContainer {
    min-width: 280px;
    @media screen and (max-width: $mobile-break) {
        min-width: unset;
        width: 100%;
    }
}

.text {
    font: $font-small $regular;
    padding: 0 20px 20px 20px;
    margin: auto 0 0 0;
}

.pOText, .startDateText {
    font: $font-small $regular;
    margin: auto 0;
}

.buttonsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
}
