@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';

.page {
    width: 100%;
}

.item {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas:
        "header"
        "offline-info";
    padding: 15px 20px;

    & + & {
        border-top: 1px solid $grey-nepal;
    }
}

.itemRow {
    display: flex;
    gap: 50px;
}

.itemColumn {
    display: flex;
    flex-direction: column;
}

.sensorColumn {
    composes: itemColumn;
    gap: 30px;
}

.itemColumnPerThresholdValues {
    composes: itemColumn;
    flex-basis: 130px;
    gap: 8px;
}

.itemColumnDevice {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    color: $black-shark;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 150px;
}

.sensorBreachWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.itemHeader {
    grid-area: header;
    font-family: $demi-bold;
    padding-bottom: 16px;
    font-size: 18px;
    color: $black-shark;
}

.thresholdText {
    font-size: 13px;
    color: $grey-shuttle;
}

.list {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    background-color: $white;
    border-radius: 10px;
    margin-bottom: 20px;
}
