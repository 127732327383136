@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';

.wrapper {
    display: flex;
    align-items: center;
    max-height: 35px;
    border-radius: 5px;
    gap: 10px;
}

.fullWidth {
    composes: wrapper;
    width: 100%;
    justify-content: space-evenly;
}
