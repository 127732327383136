@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';


.chip {
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 10px;
    background-color: $blue-azul;
    color: $white;
    font: $font-small $regular;
    height: 26px;
    box-sizing: border-box;
    white-space: pre-wrap;
    cursor: pointer;
    gap: 10px;
    &:hover {
        background-color: $blue-venice;
    }
    >i {
        font-size: 20px;
    }
}

.disabled {
    composes: chip;
    cursor: default;
    &:hover {
        background-color: $blue-azul;
    }
}

.cancel {
    cursor: pointer;
}
