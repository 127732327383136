@import '../../style/variables/colors';
@import '../../style/variables/fonts';

.pill {
    display: inline-flex;
    border-radius: 20px;
    padding: 1px 5px;
    border: 1px solid $grey-ash;
    background: $grey-ash;
    color: $black-shark;
    align-items: center;
    font: $font-medium $regular;
    gap: 2px;
    height: fit-content;
    > i {
        font-size: 20px;

    }
}

.small {
    font: $font-small $regular;
    padding: 0 5px;
    > i {
        font-size: 16px;
    }
}

.success {
    composes: pill;
    background: $green-biosphere;
    border-color: $green-biosphere;
}

.outline-success {
    composes: success;
    background: $green-ottoman;
    color: $green-moss;
    > i {
        color: $green-moss;
    }
}

.warning {
    composes: pill;
    border-color: $yellow-sunlight;
    background: $yellow-sunlight;
}
.outline-warning {
    composes: warning;
    background: $yellow-scotch-mist;
    color: $yellow-sepia;
    > i {
        color: $yellow-sepia;
    }
}

.alert {
    composes: pill;
    border-color: $red-persian;
    background: $red-persian;
    color: $white
}

.outline-alert {
    composes: alert;
    background: $red-chablis;
    color: $red-auburn;
    > i {
        color: $red-auburn;
    }
}

.info {
    composes: pill;
    border-color: $blue-azul;
    background: $blue-azul;
    color: $white
}

.outline-info {
    composes: info;
    background: $blue-powder;
    color: $blue-azul;
    > i {
        color: $blue-azul;
    }
}

.neutral {
    composes: pill;
    background: $grey-ash;
    border-color: $grey-ash;
    color: $black-shark
}

.outline-neutral {
    composes: neutral;
    background: $grey-porcelain;
    border-color: $oslo-gray;
}

.rotate90 {
    transform: rotate(90deg);
}

.rotate180 {
    transform: rotate(180deg);
}
