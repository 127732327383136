@import "~commons/src/style/variables/media";
@import "~commons/src/style/variables/colors";
@import "~commons/src/style/variables/fonts";

.edit-building {
    display: flex;
    &__form {
        width: calc(100% - (12px * 2));
        margin-top: 10px;
        padding: 0 12px;
    }
    &__align-margin {
        margin: -15px 0 0 -5px;
    }
}

.building-card {
    height: 135px;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04);
    background-color: $white;
    width: 100%;
    max-width: 1220px;
    border-radius: 10px;
    display: flex;
    margin-bottom: 30px;
    &--no-max-height {
        height: unset;
    }
    &--min-height {
        min-height: 105px;
    }

    &__groups {
        display: block;
        padding-top: 12px;
        padding-bottom: 16px;
        &--display-flex {
            display: flex;
        }
        &__header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0 25px;
            align-items: center;

            @media screen and (max-width: $mobile-max) {
                flex-wrap: wrap;
            }

            &__description {
                padding: 10px 10px 10px 0;
            }
        }
        &__section {
            padding: 0 10px 16px 10px;
            &__header {
                padding: 0 25px;
            }
            &__wrapper {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }
    &__content {
        justify-content: center;
        flex-direction: column;
        display: flex;
        width: calc(100% - 135px);
        padding: 0 50px;
        @media screen and (max-width: $sidebar-disappear) {
            width: 100%;
        }
        &--full-width {
            width: 100%;
        }
        &__text {
            height: 30px;
            font: 18px/24px $regular;
            color: $black-shark;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &--title {
                font-family: $demi-bold;
                font-weight: 600;
                line-height: 40px;
            }
            &--large {
                font-size: 24px;
            }
        }
    }
}


