@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';

.wrapper {
    display: flex;
    gap: 20px;
    @media screen and (max-width: $mobile-max) {
        flex-direction: column;
    }
}
