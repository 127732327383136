@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';


.locationSelector {
    margin-left: 10px;
}

.responseBox {
    margin: 20px 0 0 10px;
}
