@import '~commons/src/style/variables/colors.scss';
@import '~commons/src/style/variables/fonts.scss';
@import '~commons/src/style/variables/media.scss';
@import '~commons/src/style/mixins.scss';

$table-break: 630px;

.mobileTable {
    border-radius: 10px;
    background: $white;
    @media screen and (max-width: $table-break) {
        display: block;
    }
    display: none;
}

.table {
    width: 100%;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

.mobileCell {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding: 10px 20px;
    font-size: $font-small;
    font-weight: 400;
    box-sizing: border-box;
}

.mobileRow {
    width: 100%;
    display: flex;
    column-gap: 15px;
    align-items: center;
    > span {
        max-width: 120px;
        width: 100%;
    }
    div {
        width: 100%;
        flex-grow: 1;
    }
}

.mobileTableRow {
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    &:not(:last-child) {
        border-bottom: 1px solid $grey-nepal;
    }
}

.mobileProperties {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
