@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';

.element {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 5px;
    border-radius: 10px;
    width: 300px;
    max-width: 100%;
    border: 2px solid $sky-blue;
    background: $blue-powder;
    margin-bottom: 15px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font: $font-medium $regular;
}
.providedBy {
    font-size: $font-small;
    text-align: end;
}

.sensorSection {
    display: flex;
    gap: 5px;
    font: $font-large $medium;
    justify-content: space-between;
    flex-wrap: wrap;
}
.dotColor {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-right: 5px;
}
.red {
    composes: dotColor;
    background: $red-persian;
}
.yellow {
    composes: dotColor;
    background: $yellow-sunlight

}
.green {
    composes: dotColor;
    background: $green-biosphere;
}

.sensorElement {
    display: flex;
    align-items: center;
    gap: 3px;
}
