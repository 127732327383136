@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';

.wrapper {
    width: 100%;
    height: 475px;
    position: relative;
}

.backgroundImage {
    max-width: 100%;
    height: 100%;
}

.overlay {
    display: flex;
    position: absolute;
    border-radius: 10px;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 500;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .30);
    backdrop-filter: blur(5px);
}

.messageWrapper {
    height: 350px;
    max-width: 400px;
    width: 100%;
    background: $white;
    border-radius: 10px;
}
