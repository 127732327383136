@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';


.button {
    position: relative;
    width: 270px;
    height: 100px;
    font: 24px/30px $demi-bold;
    justify-content: left;
    text-align: left;
    white-space: unset;
    display: flex;
    align-content: center;
    align-items: center;
    outline: none;
    box-sizing: border-box;
    text-decoration: none;
    cursor: pointer;
    padding: 10px 16px;
    min-width: 130px;
    color: $black-shark;
    border: 1px solid $black-shark;
    border-radius: 10px;
    background-color: $white;
    transition: all .2s ease;
    > *:not(:last-child) {
        margin-right: 8px;
    }
    &:hover {
        cursor: pointer;
        background: $grey-porcelain;
    }
    &:focus {
        outline: -webkit-focus-ring-color auto 5px;
    }
}

.small {
    padding: 5px 10px;
}

.large {
    width: 270px;
    height: 100px;
    font: 24px/30px $demi-bold;
    justify-content: left;
    text-align: left;
    white-space: unset;
}

.sensorDot {
    min-width: 10px;
    height: 10px;
    width: 10px;
    margin-left: 8px;
    margin-right: 9px;
    border-radius: 9px;
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 0;
}

.alertDot {
    composes: sensorDot;
    background: $red-persian;
}
.okDot {
    composes: sensorDot;
    background: $green-biosphere;
}
.warningDot {
    composes: sensorDot;
    background: $yellow-sunlight;
}
