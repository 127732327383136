@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts.scss';

.deviceCounts {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.deviceCountElement {
    font: $font-small $regular;
    width: 170px;
}

.deviceCountElementHeader {
    font: $font-medium $demi-bold;
}
