@import '../../style/variables/colors';
@import '../../style/variables/fonts.scss';

.buttonWrapper {
    position: relative;
    display: flex;
    cursor: pointer;
    height: 32px;
}


.button {
    font: $font-medium $regular;
    height: 32px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid $yellow-sunlight;
    color: $black-shark;
    background-color: $yellow-sunlight;
    &:hover {
        background: $yellow-glow;
        border-color: $yellow-glow;
    }
}

.leftButton {
    composes: button;
    border-radius: 10px 0 0 10px;
    border-right: 1px solid $black-shark !important;
}
.rightButton {
    composes: button;
    border-radius: 0 10px 10px 0;
}


.inFocus {
    cursor: pointer;
    background: $grey-porcelain;
    border-radius: 10px;
}

.list {
    position: absolute;
    right: 0;
    top: 32px;
    min-width: 100%;
    background: white;
    border-radius: 10px;
    z-index: 2;
    padding: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
}

.listElement {
    display: flex;
    align-items: center;
    font: $font-medium $regular;
    color: $black-shark;
    white-space: nowrap;
    padding: 5px 12px;
    transition: all .2s ease;
    width: 100%;
    text-align: left;
    height: 32px;
    &:hover {
        cursor: pointer;
        background: $grey-porcelain;
        border-radius: 10px;
    }
}
