.brandingBar {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0 0 20px 0;
    max-width: unset !important;
    padding: 0 20px;
    justify-content: right;
}

.brandingDescription {
    display: inline-flex;
    align-items: center;
    margin-right: 25px;
}
