@import './variables/colors.scss';
@import './variables/fonts.scss';

.settings-view,
.add-new-device,
.change-location {
    display: flex;
    &__description {
        max-width: 500px;
        width: 100%;
        justify-content: center;
        display: flex !important;
        flex-wrap: wrap;
        margin-bottom: 40px;
    }
    &__form {
        width: 100%;
        margin-top: 15px;
        padding: 0 12px;
        &.add-new-module {
            .input-container {
                margin: 0 0 20px 0;
            }
        }

        &__labels {
            margin-left: -10px;
            margin-top: 20px;
        }

        &__buttons {
            display: flex;
            justify-content: center;
            margin-top: 30px;
            gap: 10px;
        }
        &--no-header {
            padding-top: 50px;
        }
    }
    &__charger-image {
        width: 80px;
        margin-right: 15px;
    }
    &--wizard {
        max-width: 381px;
    }
}

#changeLocationButton {
    margin: 11px 0 0 0;
}
