@import 'commons/src/style/variables/fonts.scss';
@import 'commons/src/style/variables/colors.scss';

.paragraph {
    max-width: 600px;
    margin-bottom: 10px;
    font: 13px/16px $regular;
    color: $black-shark;
}

.content {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    justify-content: space-between;
}
