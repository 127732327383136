.body {
    margin: 0 20px 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 265px;
}

.modalHeight {
    min-height: 350px;
}
