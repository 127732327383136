@import '~commons/src/style/variables/colors';
@import '~commons/src/style/variables/fonts';
@import '~commons/src/style/variables/media';

.tableElement {
    cursor: pointer;
    display: grid;
    grid-template-columns: 10% 90%;
    grid-template-rows: auto;
    grid-template-areas:
    "expand-button element-header"
        "expand-button subscription-info";
    border-top: 1px solid $grey-nepal;
    font: $font-medium $regular;
    line-height: 170%;
}

.firstTableElement {
    composes: tableElement;
    border-top: unset;
}

.expandIconWrapper {
    grid-area: expand-button;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header {
    grid-area: element-header;
    font: $font-large $medium;
    margin: 10px 0;
}
.elementContent {
    grid-area: subscription-info;
    font-family: $regular;
    display: flex;
    flex-direction: row;
    gap: 30px;
    padding-bottom: 15px;
}
.items {
    display: flex;
    flex-direction: column;
}
.data {
    display: flex;
    flex-direction: column;
}
